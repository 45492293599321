import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";
import { Analytics } from "@/libs/analytics";
import { useEffect } from "react";

export const AnalyticsInitialiser = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  useEffect(() => {
    Analytics.init(clientParams, user);
  }, [user, clientParams]);

  return <>{children}</>;
};
