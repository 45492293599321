import { SpacerData } from "@/renderers";
import { Spacer } from "@horizon/components";

export const SpacerComponent = ({
  spacerData,
  index,
  children,
}: {
  spacerData: SpacerData;
  index: number | undefined;
  children: JSX.Element | JSX.Element[] | null;
}): JSX.Element => {
  const { size } = spacerData;
  return (
    <Spacer key={index} size={size}>
      {children}
    </Spacer>
  );
};
