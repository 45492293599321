import { GeminiProvider } from "@/services/providers";
import { InspirationMapper } from "./inspiration_mapper";

import type { PageData, ComponentData } from "@/renderers";
import { Service, ServiceArgs } from "@/services/";
import { Inspiration } from "../providers/gemini/types";
import {
  PATHS,
  SITE_TITLE,
  buildContainerData,
  buildSectionData,
  getIdFromSlug,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken } = serviceArgs;
  if (path.startsWith(PATHS.inspiration)) {
    const slug = path.replace(PATHS.inspiration, "");
    const id = getIdFromSlug(slug);
    const inspiration = await GeminiProvider.getInspirationById(
      id,
      accessToken
    );

    if (inspiration) {
      return {
        title: `${inspiration.title} | Inspiration | ${SITE_TITLE}`,
        metaDescription: inspiration.introduction,
        slug: `${PATHS.inspiration}/${inspiration.id}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.inspiration}/${inspiration.slug}`,
        metaTitle: inspiration.title,
        metaImage: { url: inspiration.imageUrl },
        components: [
          ...buildComponents(inspiration),
          maybeCreatePaywall(serviceArgs, inspiration.requiredUserAction),
        ].filter(Boolean) as ComponentData[],
      };
    }
  }

  return null;
};

const buildComponents = (inspiration: Inspiration): ComponentData[] => [
  InspirationMapper.toTitleData(inspiration),
  stripUndefinedValuesFromObject(
    buildSectionData(
      [
        buildContainerData(InspirationMapper.toBodyData(inspiration)),
        InspirationMapper.toContentItems(inspiration),
      ].flat()
    )
  ),
];

export const InspirationShowService: Service = { retrievePageData };
