import { useEffect, useState } from "react";
import { ComponentData, ComponentRenderer, ScheduleData } from "@/renderers";
import { Api } from "@/libs/api";
import { PortalMapper } from "@/services/providers/portal/mappers";
import { LoadingSpinner } from "@horizon/components";

export const ScheduleComponent = ({ title }: ScheduleData) => {
  const [scheduleEvents, setScheduleEvents] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [refreshDelta, setRefreshDelta] = useState(0);
  const [apiCallCount, setApiCallCount] = useState(0);

  useEffect(() => {
    Api.getDigitalSchedule().then((data) => {
      setScheduleEvents(data?.events);
      setLoading(false);

      if (data?.refreshDelta !== 0) {
        setRefreshDelta(data?.refreshDelta);
      }
    });
  }, [apiCallCount]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setApiCallCount((prevState) => prevState + 1);
    }, refreshDelta * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [refreshDelta]);

  if (isLoading) {
    return <LoadingSpinner title="Loading the Schedule" />;
  }

  if (!scheduleEvents) {
    return <div>Failed to load schedule</div>;
  }

  const componentData: ComponentData =
    PortalMapper.toScheduleTabsData(scheduleEvents);

  return ComponentRenderer.render(componentData);
};
