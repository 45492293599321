import { gql } from "graphql-request";

export const CREATE_SESSION = gql`
  mutation CreateSession(
    $accessToken: String
    $expiration: DateTime!
    $idToken: String
    $sessionId: String!
    $refreshToken: String
    $userId: String
  ) {
    createSession(
      accessToken: $accessToken
      idToken: $idToken
      expiration: $expiration
      sessionId: $sessionId
      refreshToken: $refreshToken
      userId: $userId
    ) {
      session_id
    }
  }
`;

export const DELETE_SESSION = gql`
  mutation DeleteSession($sessionId: String!) {
    deleteSession(sessionId: $sessionId) {
      status
      message
    }
  }
`;

export const GET_SESSION = gql`
  query getSessionTokens($sessionId: String!) {
    tokensForSession(sessionId: $sessionId) {
      accessToken
      expiration
      idToken
      refreshToken
    }
  }
`;
