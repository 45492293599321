import { PAGINATION_FRAGMENT } from "@/services/providers/gemini/queries/shared_fragments/pagination_fragment";
import { gql } from "graphql-request";
import { CAMPAIGN_FRAGMENT } from "./campaign_query";

const AGENCY_FRAGMENT = gql`
  fragment AgencyFragment on Agency {
    access
    awardCount
    campaignCount
    campaigns {
      ...CampaignFragment
    }
    id
    locations {
      awardCount
      country
      name
      region
      shortlistCount
      town
    }
    name
    shortlistCount
    slug
  }
  ${CAMPAIGN_FRAGMENT}
`;

export const GET_AGENCY_FILTERS = gql`
  query (
    $awards: [String]
    $regions: [String]
    $ids: [Int]
    $page: Int
    $search_text: String
  ) {
    getAgencyFilterOptions(
      filters: {
        awards: $awards
        regions: $regions
        page: $page
        ids: $ids
        searchText: $search_text
      }
    ) {
      key
      label
      values {
        count
        key
        label
      }
    }
  }
`;

export const GET_FILTERED_AGENCIES = gql`
  query FilteredAgencis(
    $award_levels: [String]
    $page: Int
    $regions: [String]
    $sort: String
    $search_text: String
  ) {
    filteredAgencies(
      filters: {
        awards: $award_levels
        regions: $regions
        sort: $sort
        page: $page
        searchText: $search_text
      }
    ) {
      agencies {
        campaignCount
        id
        name
        slug
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

export const GET_AGENCY_BY_ID = gql`
  query GetAgencyById(
    $agency_id: Int!
    $award_levels: [String]
    $countries: [String]
    $entered_lions: [String]
    $festival_name: [String]
    $media_categories: [String]
    $sectors: [String]
    $search_text: String
    $sort: String
    $years: [Int]
    $page: Int
  ) {
    getAgencyWithFilteredCampaigns(
      campaignFilters: {
        awards: $award_levels
        countries: $countries
        enteredLions: $entered_lions
        festivalName: $festival_name
        mediaCategories: $media_categories
        searchText: $search_text
        sectors: $sectors
        sort: $sort
        years: $years
        page: $page
      }
      id: $agency_id
    ) {
      agency {
        ...AgencyFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${AGENCY_FRAGMENT}
`;
