import { gql } from "graphql-request";

export const GET_FILTERED_EDITORIALS = gql`
  query filteredEditorial(
    $categoryId: [String]
    $page: Int
    $search_text: String
  ) {
    filteredEditorial(
      filters: {
        categoryId: $categoryId
        page: $page
        search_text: $search_text
      }
    ) {
      editorials {
        description
        imageUrl
        publishedDate
        slug
        title
        mediaType
        theme
      }
      pagination {
        currentPage
        pageSize
        totalRecords
      }
    }
  }
`;

export const GET_REPORT_BY_ID = gql`
  query getReportForId($id: String) {
    getReportForId(id: $id) {
      id
      publishedDate
      description
      title
      slug
      assetUrl
      captionsUrl
      requiredUserActions
      paywall {
        ctas {
          buttonVariant
          iconName
          iconPosition
          label
          link
        }
        copy
      }
      editorialCategory {
        id
        title
        description
      }
    }
  }
`;

export const GET_VIDEO_BY_ID = gql`
  query getVideoForId($id: String) {
    getVideoForId(id: $id) {
      id
      publishedDate
      description
      title
      slug
      assetUrl
      captionsUrl
      requiredUserActions
      paywall {
        ctas {
          buttonVariant
          iconName
          iconPosition
          label
          link
        }
        copy
      }
      editorialCategory {
        id
        title
        description
      }
    }
  }
`;
