import { ButtonData, PaywallData } from "@/renderers";
import { Modal_v2 as Modal, MarkdownRenderer } from "@horizon/components";
import { useEffect, useState } from "react";

export const PaywallComponent = (paywallData: PaywallData) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        canClose={false}
        closeModal={() => {}}
        title={{
          as: "h4",
          copy: paywallData.title,
        }}
        ctas={paywallData.ctas?.map(ctaFromButtonData)}
      >
        <MarkdownRenderer html={paywallData.html} />
      </Modal>
    </>
  );
};

const ctaFromButtonData = (buttonData: ButtonData) => {
  const { label, href, variant, icon } = buttonData;
  return {
    variant,
    icon: icon && { name: icon.name, position: icon.position },
    href,
    children: label,
  };
};
