import { useState } from "react";
import { Modal_v2 } from "@horizon/components";
import { CollectionListingData, CollectionCardData } from "@/renderers";
import { GroupingComponent } from "@/components";
import { Api } from "@/libs/api";
import { defaultComponentsPerRow } from "@/services/libs";

interface DeleteModalProps {
  closeModal: Function;
  isOpen: boolean;
  itemToDelete: CollectionCardData | null;
  collectionData: {
    id: number;
    campaignIds: number[];
    entryIds: number[];
    name: string;
  };
}

type CollectionCardVariant = "Campaign" | "Entry";

type DeleteModalEntityMap = {
  [key in CollectionCardVariant]: number[];
};

export const DeleteModal = ({
  closeModal = () => {},
  isOpen = false,
  itemToDelete = null,
  collectionData,
}: DeleteModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { id, campaignIds, entryIds, name } = collectionData;
  const itemTitle = itemToDelete?.title || "";

  const entityMap: DeleteModalEntityMap = {
    Campaign: campaignIds,
    Entry: entryIds,
  };

  const reloadPage = () => {
    return location.reload();
  };

  const handleDelete = () => {
    if (!itemToDelete) {
      return closeModal();
    }

    setIsLoading(true);
    const cardVariant = itemToDelete.variant as CollectionCardVariant;

    if (entityMap.hasOwnProperty(cardVariant)) {
      entityMap[cardVariant] = entityMap[cardVariant].filter(
        (id: number) => id !== itemToDelete.id
      );
    }

    Api.updateCollection(
      {
        id,
        campaignIds: entityMap["Campaign"],
        entryIds: entityMap["Entry"],
        name,
      },
      reloadPage
    );
  };

  return (
    <Modal_v2
      isOpen={isOpen}
      closeModal={closeModal}
      headerTitle={{
        as: "h4",
        copy: `Remove ${itemTitle} from ${name}?`,
      }}
      title={{
        as: "h4",
        copy: `Remove ${itemTitle} from ${name}?`,
      }}
      ctas={[
        {
          children: "Remove",
          loading: isLoading,
          onClick: handleDelete,
          variant: "primary",
        },
        {
          children: "Cancel",
          onClick: closeModal,
          variant: "secondary",
        },
      ]}
    />
  );
};

export const CollectionListing = ({
  campaignIds,
  canRemove,
  components,
  entryIds,
  id,
  name,
}: CollectionListingData) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<CollectionCardData | null>(
    null
  );

  const closeDeleteModal = () => {
    setItemToDelete(null);
    setDeleteModalOpen(false);
  };

  const collectionCards = components?.map((component: CollectionCardData) => ({
    ...component,
    actionButton: canRemove
      ? {
          variant: "secondary",
          icon: {
            name: "Trash",
          },
          onClick: () => {
            setItemToDelete(component);
            setDeleteModalOpen(true);
          },
        }
      : null,
  }));

  return (
    <>
      <GroupingComponent
        type="ComponentGrouping"
        components={collectionCards}
        componentsPerRow={defaultComponentsPerRow}
        variant="grid"
      />
      {canRemove && (
        <DeleteModal
          isOpen={deleteModalOpen}
          closeModal={closeDeleteModal}
          itemToDelete={itemToDelete}
          collectionData={{
            id,
            campaignIds,
            entryIds,
            name,
          }}
        />
      )}
    </>
  );
};
