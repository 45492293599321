import { SearchContainerData } from "@/renderers";
import { ComponentRenderer } from "@/renderers";

export const SearchContainerComponent = (
  searchContainerData: SearchContainerData
) => {
  return (
    <>
      {searchContainerData.content.map((component, index) => {
        return ComponentRenderer.render(component, index);
      })}
    </>
  );
};
