import { callPortal } from "@/services/providers/portal/portal_provider";
import { GET_DIGITAL_SCHEDULE } from "@/services/providers/portal/queries/digital_schedule";
import { StageEvent } from "@/services/providers/portal/types";
import { logger } from "@/libs/Logger";

export const getDigitalSchedule = async (): Promise<Array<StageEvent>> => {
  try {
    const { events } = await callPortal({
      query: GET_DIGITAL_SCHEDULE,
      variables: { digitalSchedule: true },
    });
    return events;
  } catch (e) {
    logger.error("Error loading digital schedule, ", e);
    return [];
  }
};
