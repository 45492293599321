import { SessionStorePayload } from "@auth0/nextjs-auth0";
import { logger } from "@/libs/Logger";
import { CREATE_SESSION, DELETE_SESSION, GET_SESSION } from "./queries";
import { GraphQLQuery } from "@/services/libs/grapqhl";

import {
  decodeToken,
  getM2MToken,
  isTokenExpired,
  refreshAccessToken,
} from "@/libs/authentication/accessToken";

import type { JwtPayload } from "@/libs/authentication/accessToken";

type User = {
  [key: string]: any;
};

export const callUMA = async (query: GraphQLQuery, accessToken?: string) => {
  const URL = (process.env && process.env.USER_GRAPHQL_ENDPOINT) || "";
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
    body: JSON.stringify(query),
  };

  try {
    const result = await fetch(URL, options);
    if (result.status != 200) {
      logger.error(
        `Received status code not 200 when calling UMA: ${result.status} for query: ${query}`
      );
      return null;
    }
    const { data, errors } = await result.json();
    if (result.ok && errors == undefined) {
      return data;
    } else {
      logger.error(errors?.map((e: any) => e.message).join("\n") ?? "unknown");
      return null;
    }
  } catch (err) {
    logger.info(err);
  }
};

const convertToUUID = (input: string): string => {
  if (!input) {
    return "";
  }

  const uuid = input.replace(
    /(.{8})(.{4})(.{4})(.{4})(.{12})/g,
    "$1-$2-$3-$4-$5"
  );

  return uuid;
};

const generateSessionPayload = (session: any): SessionStorePayload => {
  const { accessToken, expiration, idToken, refreshToken } = session;
  const user = decodeToken(idToken) as User;
  const at = decodeToken(accessToken) as JwtPayload;
  const exp = Math.floor(new Date(expiration).getTime() / 1000);
  const updatedAt = Math.floor(new Date(user?.updated_at).getTime() / 1000);
  return {
    header: { iat: user?.iat, uat: updatedAt, exp },
    data: {
      user,
      accessToken,
      accessTokenExpiresAt: at?.exp || 0,
      idToken,
      refreshToken,
    },
  };
};

export const createSession = async (
  sessionId: string,
  session: SessionStorePayload,
  token: string | null
): Promise<void> => {
  if (!token) {
    return;
  }
  const sessionIdUUID = convertToUUID(sessionId);
  const {
    data: { accessToken, idToken, refreshToken, user },
  } = session;

  const userId =
    user["http://lionscreativity.com/user/app_metadata"]?.uma_id || "";

  // set the expiration to one year from now
  const expiration = new Date(
    Date.now() + 365 * 24 * 60 * 60 * 1000
  ).toISOString();

  const query = {
    query: CREATE_SESSION,
    variables: {
      accessToken,
      expiration,
      idToken,
      refreshToken,
      sessionId: sessionIdUUID,
      userId,
    },
  };
  await callUMA(query, token);
};

export const deleteSession = async (
  sessionId: string,
  accessToken: string | null
) => {
  if (!accessToken) {
    return;
  }
  const sessionIdUUID = convertToUUID(sessionId);
  const query = {
    query: DELETE_SESSION,
    variables: { sessionId: sessionIdUUID },
  };
  await callUMA(query, accessToken);
};

export const getSession = async (
  sessionId: string,
  accessToken: string | null
): Promise<SessionStorePayload | null> => {
  if (!accessToken) {
    return null;
  }
  const sessionIdUUID = convertToUUID(sessionId);
  const query = {
    query: GET_SESSION,
    variables: { sessionId: sessionIdUUID },
  };

  const result = await callUMA(query, accessToken);

  if (!result || !result.tokensForSession) {
    return null;
  }

  let session = result.tokensForSession;

  const at = decodeToken(session.accessToken) as JwtPayload;
  const isAccessTokenExpired = isTokenExpired(at?.exp);

  if (isAccessTokenExpired) {
    const refreshed = await refreshAccessToken(session.refreshToken);
    session.accessToken = refreshed?.access_token;
    session.idToken = refreshed?.id_token;
  }

  const sessionPayload = generateSessionPayload(session);

  if (isAccessTokenExpired) {
    await createSession(sessionId, sessionPayload, accessToken);
  }

  return sessionPayload;
};

class UMASessionStore {
  constructor() {}

  async get(sessionId: string) {
    const machineToken = await getM2MToken();
    return await getSession(sessionId, machineToken);
  }

  async set(sessionId: string, session: SessionStorePayload) {
    const machineToken = await getM2MToken();
    await createSession(sessionId, session, machineToken);
  }

  async delete(sessionId: string) {
    const machineToken = await getM2MToken();
    await deleteSession(sessionId, machineToken);
  }
}

export default UMASessionStore;
