import { GeminiProvider } from "@/services/providers";
import { PageData, ComponentData } from "@/renderers";
import { ServiceArgs, Service } from "@/services";
import {
  buildContainerData,
  buildDarkSectionData,
  PATHS,
  getIdFromSlug,
  getSlugFromPath,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
  SITE_TITLE,
  maybeRedirectToSlug,
} from "@/services/libs";
import { ClassicAsset } from "@/services/providers/gemini/types";
import { ClassicMapper } from "@/services/talks-videos/classic_show/classic_show.mapper";
import { Authentication } from "@/libs";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken } = serviceArgs;
  if (path.startsWith(PATHS.classic)) {
    const id = getIdFromSlug(path);

    const classicAsset = await GeminiProvider.getClassicAssetById(
      id,
      accessToken
    );

    if (classicAsset) {
      maybeRedirectToSlug(path, id, PATHS.classic, classicAsset.slug);

      return {
        title: `${classicAsset.title} | Classic | ${SITE_TITLE}`,
        metaDescription: classicAsset.title,
        slug: `${PATHS.classic}/${classicAsset.slug}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.classic}/${classicAsset.slug}`,
        metaTitle: classicAsset.title,
        metaImage: { url: `${classicAsset.imageUrl}?resize=300,200` },
        components: [
          ...buildComponents(classicAsset, accessToken),
          maybeCreatePaywall(
            serviceArgs,
            Authentication.USER_ACTIONS.CLASSIC_ASSETS_VIEW
          ),
        ].filter(Boolean) as ComponentData[],
      };
    }
  }
  return null;
};

const buildComponents = (
  classicAsset: ClassicAsset,
  accessToken?: string
): ComponentData[] => {
  const results = [
    buildDarkSectionData(
      [
        buildContainerData([
          ClassicMapper.classicToVideo(classicAsset, accessToken),
        ]),
      ],
      classicAsset?.title
    ),
    ClassicMapper.classicToPageTitle(classicAsset),
  ].filter(Boolean) as ComponentData[];

  return stripUndefinedValuesFromObject(results);
};

export const ClassicShowService: Service = { retrievePageData };
