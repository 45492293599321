import { ENTRY_TYPE_FRAGMENT } from "@/services/providers/gemini/queries/festival_query";
import { gql } from "graphql-request";

const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    code
    description
    id
    awardEntries {
      award
      brand
      entrantIdeaCreation
      location
      mediaCompanies
      prCompanies
      prize
      productService
      productionCompanies
      title
      slug
    }
  }
`;

export const GET_AWARD_RESULTS = gql`
  query ($entry_type_id: String!, $festival_name: String!, $year: String!) {
    getAwardCategories(
      entryTypeId: $entry_type_id
      festivalName: $festival_name
      year: $year
    ) {
      shortlisted {
        ...CategoryFragment
      }
      winners {
        ...CategoryFragment
      }
      festivalEntryType {
        ...EntryTypeFragment
      }
    }
  }
  ${CATEGORY_FRAGMENT}
  ${ENTRY_TYPE_FRAGMENT}
`;
