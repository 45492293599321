import { TagData } from "@/renderers";
import { DefaultTag, AwardTag, IconTag, RatingTag } from "@horizon/components";
import { buildKey } from "./libs";

export const TagComponent = (
  { content, component, variant }: TagData,
  index?: number
) => {
  switch (component) {
    case "Award":
      return (
        <AwardTag key={buildKey("tag", index)} variant={variant}>
          {content}
        </AwardTag>
      );
    case "Icon":
      return (
        <IconTag key={buildKey("tag", index)} variant={variant}>
          {content}
        </IconTag>
      );
    case "Rating":
      return (
        <RatingTag key={buildKey("tag", index)} variant={variant}>
          {content}
        </RatingTag>
      );
    default:
      return (
        <DefaultTag key={buildKey("tag", index)} variant={variant}>
          {content}
        </DefaultTag>
      );
  }
};
