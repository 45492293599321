import {
  ComponentData,
  LabelCaptionData,
  PageTitleBlockData,
} from "@/renderers";
import {
  TEXT_CONSTS,
  defaultComponentsPerRow,
  formatNumber,
  pluralize,
} from "@/services/libs";
import { awardCountsToTags } from "@/services/libs/award_utils";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Brand, Pagination } from "@/services/providers/gemini/types";

const toTitleData = (
  { awardCount, shortlistCount, name }: Brand,
  pagination: Pagination | null
): PageTitleBlockData => ({
  type: "PageTitleBlock",
  title: `${formatNumber(
    pagination?.totalRecords
  )} ${name.toUpperCase()} ${pluralize(
    pagination?.totalRecords || 0,
    TEXT_CONSTS.CAMPAIGN
  ).toUpperCase()}`,
  awardTags: awardCountsToTags(awardCount, shortlistCount),
});

const toCampaignGrouping = (
  { campaigns }: Brand,
  pagination: Pagination | null
): ComponentData =>
  GeminiMapper.toComponentGroupingData({
    type: "ComponentGrouping",
    searchQuery: `content_type=campaigns`,
    componentsCollection: { items: campaigns },
    componentsPerRow: defaultComponentsPerRow,
    variant: "grid",
    totalRecords: pagination?.totalRecords,
    page: pagination?.currentPage,
    pageSize: pagination?.pageSize,
  });

export const BrandShowMapper = {
  toTitleData,
  toCampaignGrouping,
};
