import { ComponentData, EventItemData } from "@/renderers";
import { EventSchedule } from "../../contentful/types/event_schedule.d";
import {
  EventScheduleData,
  ScheduleEventItem,
  StageEvent,
} from "../types/stage_event";
import { Talk } from "../../gemini/types";
import {
  buildContainerData,
  formatDate,
  formatDateString,
  getDurationText,
  getRandomNumber,
  getTimeDifferenceInSeconds,
  groupArrayByKey,
  isNowBetweenDates,
  isPastDate,
  stripHtmlTags,
  PATHS,
} from "@/services/libs";
import sortByDateTime from "@/services/libs/schedule";
import {
  currentSearchQuery,
  parseSearchQuery,
} from "@/libs/QueryParams/query_params";

const timeZone = "Europe/Paris";
const eventStatus = {
  live: "live",
  past: "past",
  upcoming: "upcoming",
};

const toScheduleEventsData = (
  scheduleData: StageEvent[],
  onDemandTalks: Talk[]
): EventScheduleData | null => {
  if (!scheduleData) {
    return null;
  }
  const sortedEvents = scheduleData?.sort(sortByDateTime);
  const parsedEvents = sortedEvents?.map((event: StageEvent) => {
    const onDemandTalk = onDemandTalks?.find((talk: Talk) => {
      return talk.externalId == event.eventId;
    });

    return toScheduleEvent(event, onDemandTalk);
  });

  const nextEvent = parsedEvents.find(
    (event) => event.status !== eventStatus.past
  );

  const nextEventEndDate = nextEvent
    ? {
        dateString: `${nextEvent?.dateIso} ${nextEvent?.endTime}`,
        format: "yyyyMMdd HHmm",
      }
    : null;

  // Add a random number of seconds to the next event end time to ensure the
  // schedule is refreshed after the event has ended without causing spike in
  // traffic to the API endpoint
  const refreshDelta = nextEventEndDate
    ? getTimeDifferenceInSeconds({ end: nextEventEndDate, timeZone }) +
      getRandomNumber(5, 120)
    : 0;

  return {
    events: parsedEvents,
    refreshDelta,
  };
};

const toScheduleEvent = (
  event: StageEvent,
  onDemandTalk?: Talk
): ScheduleEventItem => {
  const speakers = event.speakerData.map((speaker) => {
    return speaker.speaker;
  });

  let endDateString = `${event.dateIso} ${event.endTime}`;
  let status = eventStatus.upcoming;

  const tags = event?.eventTags?.map((tag) => tag.eventTag);
  const format = "yyyyMMdd HHmm";
  const startDateString = `${event.dateIso} ${event.startTime}`;
  const finishesNextDay = event.endTime < event.startTime;

  // Handle the overnight events
  if (finishesNextDay) {
    const nextDayIso = (parseInt(event.dateIso, 10) + 1).toString();
    endDateString = `${nextDayIso} ${event.endTime}`;
  }

  const isPast = isPastDate(endDateString, format, timeZone);
  const isLive = isNowBetweenDates(
    startDateString,
    endDateString,
    format,
    timeZone
  );

  const duration = getDurationText({
    start: { dateString: startDateString, format },
    end: { dateString: endDateString, format },
  });

  if (isPast) {
    status = eventStatus.past;
  } else if (isLive) {
    status = eventStatus.live;
  }

  return {
    dateIso: event.dateIso,
    description: event.description,
    duration,
    endTime: formatDateString(event.endTime, "HHmm", "HH:mm"),
    host: event.eventHost,
    id: event.eventId,
    speakers,
    startTime: formatDateString(event.startTime, "HHmm", "HH:mm"),
    status,
    tags,
    title: event.titleOnly,
    videoUrl: onDemandTalk && `${PATHS.talks}/${onDemandTalk.slug}`,
  } as ScheduleEventItem;
};

const toScheduleData = ({ title }: EventSchedule): ComponentData => {
  return {
    type: "Schedule",
    title,
  } as ComponentData;
};

const toScheduleTabsData = (
  scheduleEvents: ScheduleEventItem[]
): ComponentData => {
  const eventsByDay = groupArrayByKey(scheduleEvents, "dateIso");
  const days = Object.keys(eventsByDay);
  const currentTabQuery = getCurrentTabQuery(days);

  return {
    type: "TabContainer",
    currentTabQuery,
    reloadOnTabChange: false,
    tabs: days?.map((day) => {
      return {
        type: "Tab",
        title: formatDateString(day, "yyyyMMdd", "E, do LLL"),
        label: day,
        components: [buildContainerData(buildDaysEvents(eventsByDay[day]))],
      };
    }),
  } as ComponentData;
};

const buildDaysEvents = (scheduleEvents: ScheduleEventItem[]) => {
  return scheduleEvents?.map((event) => {
    return buildEvent(event);
  });
};

const buildEvent = (eventItem: ScheduleEventItem): EventItemData => {
  const event = {
    calendarEvent: {
      description: stripHtmlTags(eventItem.description),
      endTime: formatDateString(
        `${eventItem.dateIso} ${eventItem.endTime}`,
        "yyyyMMdd HH:mm",
        "yyyy-MM-dd'T'HH:mm:ssXXX",
        timeZone
      ),
      location: "https://www.lovethework.com/canneslions-digitalpass",
      startTime: formatDateString(
        `${eventItem.dateIso} ${eventItem.startTime}`,
        "yyyyMMdd HH:mm",
        "yyyy-MM-dd'T'HH:mm:ssXXX",
        timeZone
      ),
      title: eventItem.title,
    },
    description: eventItem.description,
    duration: eventItem.duration,
    host: eventItem.host,
    id: eventItem.id,
    speakers: eventItem.speakers,
    status: eventItem.status,
    tags: eventItem.tags,
    time: `${eventItem.startTime} - ${eventItem.endTime} CEST`,
    title: eventItem.title,
    type: "EventItem",
    videoUrl: eventItem.videoUrl,
  };

  return event as EventItemData;
};

const getCurrentTabQuery = (
  days: string[]
): { key: string; value: string } | undefined => {
  const queryParams = currentSearchQuery();
  const parsedQuery = parseSearchQuery(queryParams);
  const currentDay = formatDate(new Date(), "yyyyMMdd");
  const selectedDay = parsedQuery?.day[0] || currentDay;
  const tabValue = days.find((day) => day === selectedDay) || days[0];
  return { key: "day", value: tabValue };
};

export const PortalMapper = {
  toScheduleData,
  toScheduleEventsData,
  toScheduleTabsData,
};
