import styled from "@emotion/styled";
import { Button_v2 as Button } from "@horizon/components";

export const BackToTopButton = styled(Button)({
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.16)",
});

export const Wrapper = styled("div")({
  position: "fixed",
  bottom: "2.4rem",
  right: "1.6rem",
  zIndex: "100",
});
