import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_ENTRIES_BY_IDS } from "@/services/providers/gemini/queries/entry_query";
import { Entry } from "@/services/providers/gemini/types";

export const getEntryById = async (
  id: string,
  accessToken?: string
): Promise<Entry | null> => {
  const results = await callGemini(
    { query: GET_ENTRIES_BY_IDS, variables: { ids: [parseInt(id)] } },
    accessToken
  );

  if (results === null) {
    return null;
  } else {
    const {
      getEntriesByIds: [entry],
    } = results;
    return entry;
  }
};
