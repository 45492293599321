import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_ENTRY_FILTERS } from "@/services/providers/gemini/queries/filter_query";
import { FilterOption } from "@/services/providers/gemini/types/filters";

export const getEntryFilters = async (
  queryParams: any,
  accessToken?: string
): Promise<Array<FilterOption>> => {
  const response = await callGemini(
    { query: GET_ENTRY_FILTERS, variables: queryParams },
    accessToken
  );
  if (response) {
    const { getEntryFilterOptions: options } = response;
    return options;
  }
  return [];
};
