import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { YOUNG_ENTRY_QUERY } from "../queries/young_entry_query";
import { YoungEntry } from "../types";

export const getYoungEntryById = async (
  id: string,
  accessToken?: string
): Promise<YoungEntry> => {
  const {
    getYoungEntriesByIds: [youngEntry],
  } = await callGemini(
    { query: YOUNG_ENTRY_QUERY, variables: { ids: [parseInt(id)] } },
    accessToken
  );

  return youngEntry;
};
