import { initAuth0 } from "@auth0/nextjs-auth0";
import Store from "./session";

let auth0: any = null;

const init = () => {
  if (!auth0) {
    auth0 = initAuth0({
      secret: process.env.AUTH0_SECRET,
      baseURL: process.env.AUTH0_BASE_URL,
      clientID: process.env.AUTH0_CLIENT_ID,
      clientSecret: process.env.AUTH0_CLIENT_SECRET,
      issuerBaseURL: process.env.AUTH0_ISSUER_BASE_URL,
      session: {
        store: new Store(),
      },
    });
  }
  return auth0;
};

export default init;
