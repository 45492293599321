import { ParsedQuery } from "@/libs/QueryParams";
import { ComponentData, PaginationData } from "@/renderers";

export interface SearchQueryResponse {
  nextResults: ComponentData[];
  pagination?: PaginationData;
}

export const SearchEvents = Object.freeze({
  SEARCH_RESULTS: "searchResults",
});
