import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_INSPIRATION_BY_ID } from "@/services/providers/gemini/queries/inspiration_query";
import { Inspiration } from "@/services/providers/gemini/types";

export const getInspirationById = async (
  id: string,
  accessToken?: string
): Promise<Inspiration | null> => {
  const response = await callGemini(
    { query: GET_INSPIRATION_BY_ID, variables: { id: id } },
    accessToken
  );
  if (response) {
    const { inspirationForId: inspiration } = response;
    if (valuesNotAllNull(inspiration)) {
      return inspiration;
    }
  }
  return null;
};

const valuesNotAllNull = (object: object) => {
  return Object.values(object).some((value) => value !== null);
};
