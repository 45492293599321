import { TableCell, TableData } from "./components.d";
import {
  Container,
  GroupHeader,
  HeroTitle,
  Table_v2 as Table,
} from "@horizon/components";
import { Pagination } from "@/components";
import { tagToTagProps } from "./libs";

export const TableComponent = (
  tableData: TableData,
  index?: number
): JSX.Element => {
  const hasAutoColumns =
    tableData.headers?.some(
      (header: TableCell): boolean => header.cellWidth === "auto"
    ) || false;

  const layout = tableData.layout || hasAutoColumns ? "auto" : "fixed";

  const rows = tableData.rows?.map((row) => {
    const cells = row.cells.map((cell) => {
      if (cell.type === "tag") {
        return {
          ...cell,
          tag: tagToTagProps(cell?.tag),
        };
      }

      return cell;
    });
    return { cells };
  });

  return (
    <div key={index} id={`${tableData.id}`}>
      {tableData.title && (
        <HeroTitle size={["xsmall", "medium"]} as="h2">
          {tableData.title.toUpperCase()}
        </HeroTitle>
      )}
      {tableData.banner && <GroupHeader banner={tableData.banner} />}
      <Table
        fullWidth
        headers={tableData.headers}
        layout={layout}
        rows={rows}
        striped={tableData.striped}
      />
      {tableData.pagination && (
        <Pagination
          page={tableData.pagination.page}
          pageSize={tableData.pagination.pageSize}
          totalRecords={tableData.pagination.totalRecords}
        />
      )}
    </div>
  );
};
