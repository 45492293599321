import { callRestGeminiPut } from "@/services/providers/gemini/gemini_provider";

export const claimLicense = async (accessToken: string, claimCode?: string) => {
  if (!claimCode) return undefined;
  const results = await callRestGeminiPut(
    "/api/me/claim",
    {
      claim_code: claimCode,
    },
    accessToken
  );
  return results;
};
