import { HeroTitleData } from "@/renderers";
import { HeroTitle } from "@horizon/components";
import { buildKey } from "./libs";

export const HeroTitleComponent = (
  { size, content, id }: HeroTitleData,
  index?: number
) => {
  return (
    <HeroTitle key={buildKey("hero", index)} size={size} id={id}>
      {content}
    </HeroTitle>
  );
};
