import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_FESTIVAL_TRACKS_FOR_YEAR } from "@/services/providers/gemini/queries/festival_query";
import { FestivalTrack } from "@/services/providers/gemini/types";

export const getFestivalTracks = async ({
  festivalName,
  festivalYear,
  accessToken,
}: {
  festivalName: string;
  festivalYear?: number;
  accessToken?: string;
}): Promise<FestivalTrack[] | null> => {
  const results = await callGemini(
    {
      query: GET_FESTIVAL_TRACKS_FOR_YEAR,
      variables: { festivalName, festivalYear },
    },
    accessToken
  );
  if (results === null || results === undefined) {
    return null;
  } else {
    const { getFestivalTracks: tracks } = results;
    return tracks;
  }
};
