import { AnnouncementData } from "@/renderers";
import { Announcement } from "@horizon/components";
import { NoMarginMarkdownComponent } from "@/components/MarkdownComponent";

export const AnnouncementComponent = ({
  announcementMsg,
  expiredMsg,
  date,
  variant,
  id,
}: AnnouncementData) => {
  return (
    <Announcement
      id={id}
      announcementMsg={
        <NoMarginMarkdownComponent
          type="Markdown"
          copySize="medium"
          html={announcementMsg || ""}
        />
      }
      date={date}
      expiredMsg={
        <NoMarginMarkdownComponent
          type="Markdown"
          copySize="medium"
          html={expiredMsg || ""}
        />
      }
      variant={variant}
    />
  );
};
