import { createContext, useContext } from "react";

export const ClientParameterProvider = ({
  children,
  parameters,
}: {
  children: React.ReactNode;
  parameters: ClientParameters;
}) => {
  return (
    <ClientParameterContext.Provider value={parameters}>
      {children}
    </ClientParameterContext.Provider>
  );
};

export type ClientParameters = {
  [key: string]: string;
};

const ClientParameterContext = createContext<ClientParameters | {}>({});

export const useClientParameters = () => {
  return useContext(ClientParameterContext);
};
