import { RankingsTable } from "@/services/providers/contentful/types";

const festivalCodes = {
  "Cannes Lions": "CL",
  Eurobest: "EB",
  "Spikes Asia": "SA",
  "Dubai Lynx": "DL",
};

export const parseRankingsQuery = (component: RankingsTable) => {
  const { awardType, awardCategory, year, variant } = component;

  return {
    type: awardType,
    festivalCode: festivalCodes[variant as keyof object],
    year: year,
    category: parseInt(awardCategory.split("-").reverse()[0]),
  };
};

export const combineQueryParams = (
  rankingsParams: { year: any; category: any; type: any; festivalCode: any },
  queryParams: string | undefined
) => {
  const params = new URLSearchParams(queryParams);
  const type = params.get("type") || rankingsParams.type;
  const festivalCode =
    rankingsParams.festivalCode || festivalCodes["Cannes Lions"];
  const year = parseInt(params.get("year") || rankingsParams.year);
  const category = parseInt(params.get("category") || rankingsParams.category);

  return { type, festivalCode, year, category };
};
