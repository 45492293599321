import { ButtonData, PageTitleBlockData } from "@/renderers";
import { PageTitleBlock, Container } from "@horizon/components";
import { buildKey } from "../libs";

export const PageTitleBlockComponent = ({
  pageTitleBlockData,
  index,
}: {
  pageTitleBlockData: PageTitleBlockData;
  index?: number;
}) => {
  const { title, superText, subText, awardTags, ctas, quote, profileCard, id } =
    pageTitleBlockData;

  return (
    <Container id={id}>
      <PageTitleBlock
        key={buildKey("pagetitle", index)}
        title={title}
        superText={superText}
        subText={subText}
        awardTags={
          awardTags &&
          awardTags.map((awardTag) => ({
            variant: awardTag.variant,
            copy: awardTag.content,
          }))
        }
        ctas={ctas?.map(ctaFromButtonData)}
        quote={quote && { copy: quote }}
        profileCard={
          profileCard && {
            title: { copy: profileCard?.name },
            caption: { copy: profileCard?.caption },
            media: {
              src: profileCard?.media?.href,
              alt: profileCard?.media?.alt,
            },
          }
        }
      />
    </Container>
  );
};

const ctaFromButtonData = (buttonData: ButtonData) => {
  const { label, href, variant, icon } = buttonData;
  return {
    variant,
    icon: icon && { name: icon.name, position: icon.position },
    href,
    children: label,
  };
};
