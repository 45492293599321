import { AwardTagVariant } from "@/renderers";

export type AwardLevel =
  | "titanium"
  | "titaniumgrandprix"
  | "grandprix"
  | "gold"
  | "silver"
  | "bronze"
  | "shortlist";

export type ClassicAwardLevel =
  | "Grand Prix"
  | "Gold Lion"
  | "Gold"
  | "1st Prize"
  | "Silver Lion"
  | "Silver"
  | "Bronze Lion"
  | "Bronze"
  | "Diploma"
  | "Shortlist";

export interface Award {
  level: AwardLevel;
  levelLabel: string;
  count?: number;
}

type AwardLevelVariantMapping = { [key in AwardLevel]: AwardTagVariant };
export const awardLevelVariantMapping: AwardLevelVariantMapping = {
  titanium: "titanium",
  titaniumgrandprix: "grandPrix",
  grandprix: "grandPrix",
  gold: "gold",
  silver: "silver",
  bronze: "bronze",
  shortlist: "shortlist",
};

type ClassicAwardLevelVariantMapping = {
  [key in ClassicAwardLevel]: AwardTagVariant;
};
export const classicAwardLevelVariantMapping: ClassicAwardLevelVariantMapping =
  {
    "Grand Prix": "grandPrix",
    "Gold Lion": "gold",
    Gold: "gold",
    "1st Prize": "gold",
    "Silver Lion": "silver",
    Silver: "silver",
    "Bronze Lion": "bronze",
    Bronze: "bronze",
    Diploma: "bronze",
    Shortlist: "shortlist",
  };
