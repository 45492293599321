import { gql } from "graphql-request";
import {
  HERO_BLOCK_FRAGMENT,
  PAYWALL_COMPONENT_FRAGMENT,
} from "./section_queries";

export const REPORT_BY_ID_QUERY = gql`
  query ($preview: Boolean, $id: String) {
    reportCollection(preview: $preview, where: { sys: { id: $id } }, limit: 1) {
      items {
        title
        thumbnail {
          url
        }
        headerComponent {
          type: __typename
          ... on HeroBlock {
            ...HeroBlockFragment
          }
        }
        report {
          url
          contentType
        }
        description
        editorialCategory: contentCategory {
          description
          title
          sys {
            id
          }
        }
        navigationType
        requiredUserAction
        excludedUserAction
        marketoFormId
        publishedDate
        paywall {
          ...PaywallComponentFragment
        }
        content: contentCollection(preview: $preview) {
          items {
            sys {
              id
            }
          }
        }
      }
    }
  }
  ${HERO_BLOCK_FRAGMENT}
  ${PAYWALL_COMPONENT_FRAGMENT}
`;
