import {
  ComponentData,
  TextData,
  AwardTagData,
  PageTitleBlockData,
} from "@/renderers";
import {
  TEXT_CONSTS,
  defaultComponentsPerRow,
  listToCommaSeparatedString,
  pluralize,
} from "@/services/libs";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Individual, Pagination } from "@/services/providers/gemini/types";
import { awardLevelToVariant } from "@/services/libs/award_utils";

const toTitleData = ({
  awards,
  country,
  fullname,
  jobtitle,
}: Individual): PageTitleBlockData => ({
  type: "PageTitleBlock",
  title: `${fullname.toUpperCase()}`,
  subText: listToCommaSeparatedString([jobtitle, country]),
  awardTags: awards?.map((award) => {
    return {
      type: "Tag",
      content: award.levelLabel,
      variant: awardLevelToVariant(award.level),
      component: "Award",
    } as AwardTagData;
  }),
});

const toCampaignCountData = (pagination: Pagination | null): TextData => {
  const totalCampaigns = pagination ? pagination.totalRecords : 0;
  return {
    type: "Text",
    size: "xlarge",
    content: `${totalCampaigns} ${pluralize(
      totalCampaigns,
      TEXT_CONSTS.CAMPAIGN
    ).toUpperCase()}`,
  };
};

const toCampaignGrouping = (
  { campaigns }: Individual,
  pagination: Pagination | null
): ComponentData =>
  GeminiMapper.toComponentGroupingData({
    type: "ComponentGrouping",
    searchQuery: `content_type=campaigns`,
    componentsCollection: { items: campaigns },
    componentsPerRow: defaultComponentsPerRow,
    variant: "grid",
    totalRecords: pagination?.totalRecords,
    page: pagination?.currentPage,
    pageSize: pagination?.pageSize,
  });

export const IndividualShowMapper = {
  toTitleData,
  toCampaignCountData,
  toCampaignGrouping,
};
