import { PageTitleBlockComponent } from "@/components/PageTitleBlockComponent/PageTitleBlock_default";
import { PageTitleBlockComponentWithSaveCollections } from "@/components/PageTitleBlockComponent/PageTitleBlock_withSaveCollections";
import { PageTitleBlockData } from "@/renderers";

const buildPageTitleBlockComponent = (
  pageTitleBlockData: PageTitleBlockData,
  index?: number
) => {
  const { saveCollectionButtonData } = pageTitleBlockData;

  if (saveCollectionButtonData) {
    return (
      <PageTitleBlockComponentWithSaveCollections
        pageTitleBlockData={pageTitleBlockData}
        index={index}
        key={index}
      />
    );
  }
  return (
    <PageTitleBlockComponent
      pageTitleBlockData={pageTitleBlockData}
      index={index}
      key={index}
    />
  );
};

export const PageTitleBlockFactory = { buildPageTitleBlockComponent };
