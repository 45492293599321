import { Authentication } from "@/libs";
import { QueryParams, ParsedQuery } from "@/libs/QueryParams";
import { ComponentData, SpacerData } from "@/renderers";
import { ServiceArgs } from "@/services";
import { AgencyListingMapper } from "@/services/directory/agency_listing/agency_listing_mapper";
import {
  SITE_TITLE,
  buildContainerData,
  buildSectionData,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { GeminiProvider } from "@/services/providers";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import {
  Agency,
  FilterOption,
  Pagination,
} from "@/services/providers/gemini/types";

const retrievePageData = async (serviceArgs: ServiceArgs) => {
  const { path, accessToken, queryParams } = serviceArgs;
  const parsedQuery = QueryParams.parseSearchQuery(
    appendContentType(queryParams || "")
  );
  delete parsedQuery?.id;

  const filterOptions = await GeminiProvider.getAgencyFilters(
    parsedQuery,
    accessToken
  );
  const { agencies, pagination } = await GeminiProvider.getFilteredAgencies(
    parsedQuery,
    accessToken
  );

  if (agencies) {
    const result = {
      title: `${SITE_TITLE} | Agencies`,
      metaDescription: `${SITE_TITLE} | Agencies`,
      slug: `${path}`,
      metaTitle: `${SITE_TITLE} | Agencies`,
      cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${path}`,
      components: [
        ...buildComponents(agencies, filterOptions, parsedQuery, pagination),
        maybeCreatePaywall(
          serviceArgs,
          Authentication.USER_ACTIONS.AGENCIES_VIEW
        ),
      ].filter(Boolean) as ComponentData[],
    };
    return stripUndefinedValuesFromObject(result);
  }
  return null;
};

const appendContentType = (queryString: string) => {
  if (queryString.length > 1) {
    return queryString.concat(`&content_type=agency`);
  }
  return `content_type=agency`;
};

const buildComponents = (
  brands: Agency[],
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery,
  pagination: Pagination | null
) => {
  return [
    buildSectionData(
      [
        AgencyListingMapper.toSearchBarData(
          (parsedQuery?.search_text as string) || ""
        ),
        GeminiMapper.toFilterBlockData(
          filterOptions,
          parsedQuery,
          buildSortOptions(parsedQuery)
        ),
        buildContainerData([AgencyListingMapper.maybeAddNoResults(pagination)]),
        buildContainerData([
          AgencyListingMapper.toAgencyTable(brands, pagination),
        ]),
      ],
      AgencyListingMapper.toTitleText(pagination)
    ),
  ];
};

const SORT_OPTIONS = [
  { value: "relevance", label: "Best match" },
  { value: "name", label: "Most awarded" },
  { value: "title_ascending", label: "Title - A-Z" },
  { value: "title_descending", label: "Title - Z-A" },
];

const buildSortOptions = (
  parsedQuery: ParsedQuery
): {
  options: { label: string; value: string }[];
  initialValue: string;
} => ({
  options: SORT_OPTIONS,
  initialValue: parsedQuery?.sort ? (parsedQuery.sort as string) : "name",
});

export const AgencyListingService = { retrievePageData };
