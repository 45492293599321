import { AvatarData } from "@/renderers";
import { Avatar_v2 as Avatar } from "@horizon/components";
import { buildKey } from "./libs";
import { tagToTagProps } from "./libs";

export const AvatarComponent = (
  { image, name, size, subText, tag, id }: AvatarData,
  index?: number
) => {
  const tagProps = tag && tagToTagProps(tag);
  return (
    <Avatar
      id={id}
      key={buildKey("avatar", index)}
      name={name}
      size={size || "large"}
      src={image?.href}
      subText={subText}
      tag={tagProps}
    />
  );
};
