import { ParsedQuery } from "@/libs/QueryParams";
import { callGemini } from "@/services/providers/gemini/gemini_provider";

import { GET_PORTFOLIO_BY_ID } from "@/services/providers/gemini/queries/portfolio_query";

import { Individual, Pagination } from "@/services/providers/gemini/types";

export const getIndividualById = async (
  id: string,
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<{
  individual: Individual | null;
  pagination: Pagination | null;
}> => {
  const results = await callGemini(
    {
      query: GET_PORTFOLIO_BY_ID,
      variables: { portfolio_id: parseInt(id), ...queryParams },
    },
    accessToken
  );

  if (results === null) {
    return { individual: null, pagination: null };
  } else {
    const { getPortfolioWithFilteredCampaigns: portfolioData } = results;
    return {
      individual: portfolioData.portfolio,
      pagination: portfolioData.pagination,
    };
  }
};
