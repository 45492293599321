import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_FILTERED_PORTFOLIOS } from "@/services/providers/gemini/queries/portfolio_query";
import { Individual, Pagination } from "@/services/providers/gemini/types";

export const getFilteredIndividuals = async (
  queryParams: any,
  accessToken?: string
): Promise<{
  individuals: Array<Individual> | null;
  pagination: Pagination | null;
}> => {
  const results = await callGemini(
    { query: GET_FILTERED_PORTFOLIOS, variables: queryParams },
    accessToken
  );

  if (results === null) {
    return { individuals: null, pagination: null };
  } else {
    const {
      filteredPortfolios: { portfolios: portfolios, pagination: pagination },
    } = results;
    return { individuals: portfolios, pagination: pagination };
  }
};
