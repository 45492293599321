import {
  differenceInSeconds,
  format,
  formatDuration,
  intervalToDuration,
  isPast,
  isWithinInterval,
  parse,
} from "date-fns";
import type { Duration } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";

type DateFormat = {
  dateString: string;
  format: string;
};

export const getMonthNameFromDateString = (
  dateString: string,
  locale?: string
): string => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  const locales = ["default"];
  if (locale) {
    // We want to use the provided locale first, providing default as a fallback
    locales.unshift(locale);
  }
  return date.toLocaleString(locales, { month: "long" });
};

export const getDayNameFromDateString = (
  dateString: string,
  locale?: string
): string => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  const locales = ["default"];
  if (locale) {
    // We want to use the provided locale first, providing default as a fallback
    locales.unshift(locale);
  }
  return date.toLocaleString(locales, { weekday: "long" });
};

export const toDateFormat = (dateString: string | undefined): string => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);
  return `${padLeadingZero(date.getDate())} 
  ${getMonthNameFromDateString(dateString)} 
  ${date.getUTCFullYear()}`;
};

const padLeadingZero = (number: number): string => {
  return number < 10 ? `0${number}` : `${number}`;
};

export const yearFromDateTime = (dateTime: string | undefined): string => {
  if (!dateTime) {
    return "";
  }

  return dateTime?.split("-")[0] || "";
};

export const formatDate = (
  date: Date,
  outputFormat = "dddd Do MMMM"
): string => {
  if (!date) {
    return "";
  }

  try {
    return format(date, outputFormat);
  } catch (e) {
    return "";
  }
};

export const formatDateString = (
  dateString: string,
  inputFormat = "YYYYMMDD",
  outputFormat = "dddd Do MMMM",
  timeZone?: string
): string => {
  if (!dateString) {
    return "";
  }
  let date;

  try {
    date = parse(dateString, inputFormat, new Date());

    if (timeZone) {
      date = fromZonedTime(date, timeZone);
    }

    return format(date, outputFormat);
  } catch (e) {
    return "";
  }
};

export const isPastDate = (
  dateString: string,
  format: string,
  timeZone?: string
): boolean => {
  let date;

  try {
    date = parse(dateString, format, new Date());
  } catch (e) {
    return false;
  }

  if (timeZone) {
    date = fromZonedTime(date, timeZone);
  }

  return isPast(date);
};

export const isNowBetweenDates = (
  startDateString: string,
  endDateString: string,
  format: string,
  timeZone?: string
): boolean => {
  let startDate, endDate;

  try {
    startDate = parse(startDateString, format, new Date());
    endDate = parse(endDateString, format, new Date());
  } catch (e) {
    console.log(e);
    return false;
  }

  if (timeZone) {
    startDate = fromZonedTime(startDate, timeZone);
    endDate = fromZonedTime(endDate, timeZone);
  }

  return isWithinInterval(new Date(), {
    start: startDate,
    end: endDate,
  });
};

export const getDurationText = ({
  start,
  end,
}: {
  start: DateFormat;
  end: DateFormat;
}): string => {
  if (!start || !end) {
    return "";
  }

  const outputOptions = {
    delimiter: ", ",
    format: ["hours", "minutes"] as Array<keyof Duration>,
  };

  try {
    const now = new Date();
    const startDate = parse(start.dateString, start.format, now);
    const endDate = parse(end.dateString, end.format, now);
    const duration = intervalToDuration({ start: startDate, end: endDate });
    return formatDuration(duration, outputOptions);
  } catch (e) {
    return "";
  }
};

export const getTimeDifferenceInSeconds = ({
  start,
  end,
  timeZone,
}: {
  start?: DateFormat;
  end?: DateFormat;
  timeZone?: string;
}): number => {
  const now = timeZone ? toZonedTime(new Date(), timeZone) : new Date();
  let startDate, endDate;

  try {
    startDate = start ? parse(start.dateString, start.format, now) : now;
    endDate = end ? parse(end.dateString, end.format, now) : now;
  } catch (e) {
    return 0;
  }

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return 0;
  }

  if (timeZone) {
    startDate = fromZonedTime(startDate, timeZone);
    endDate = fromZonedTime(endDate, timeZone);
  }

  return differenceInSeconds(endDate, startDate);
};
