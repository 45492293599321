import { Service, ServiceArgs } from "@/services/";
import type { PageData, ComponentData } from "@/renderers";
import { GeminiProvider } from "@/services/providers";
import { Collection, Entry } from "@/services/providers/gemini/types";
import { EntryShowMapper } from "./entry_show_mapper";
import { QueryParams } from "@/libs/QueryParams";
import { ComponentGrouping } from "@/services/providers/contentful/types";
import {
  PATHS,
  SITE_TITLE,
  buildSectionData,
  defaultComponentsPerRowCarousel,
  getIdFromSlug,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken } = serviceArgs;

  if (!path.startsWith(PATHS.entries)) {
    return null;
  }

  const id = getIdFromSlug(path);
  const [entry, collections] = await Promise.all([
    GeminiProvider.getEntryById(id, accessToken),
    GeminiProvider.getCollections(accessToken),
  ]);

  if (!entry) {
    return null;
  }

  const components = await buildComponents(entry, collections, serviceArgs);
  const result: PageData = {
    title: `${entry.title} | Entry | ${SITE_TITLE}`,
    metaDescription: entry.title,
    slug: `${PATHS.entries}${entry.id}`,
    cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN || ""}${PATHS.entries}/${
      entry.id
    }`,
    metaTitle: entry.title,
    metaImage: {
      url: entry.assets[0]?.thumbnailUrl || "",
    },
    components,
  };
  return stripUndefinedValuesFromObject(result);
};

const buildComponents = async (
  entry: Entry,
  collections?: Collection[],
  serviceArgs?: ServiceArgs
) => {
  const { userActions } = serviceArgs || {};
  const galleryCarousel = EntryShowMapper.maybeToGalleryCarousel(
    entry,
    userActions
  );
  return [
    EntryShowMapper.toTitleData(entry, collections),
    galleryCarousel ? buildSectionData([galleryCarousel]) : {},
    buildSectionData([EntryShowMapper.toTabContainer(entry)]),
    EntryShowMapper.toFooterCarousels(await buildComponentGroupings(entry)),
    maybeCreatePaywall(serviceArgs, entry.requiredUserActions),
  ]
    .flat()
    .filter(Boolean) as ComponentData[];
};

const buildComponentGroupings = async (
  entry: Entry
): Promise<ComponentGrouping[]> => {
  return Promise.all([
    createComponentGrouping(
      `content_type=entries&category_ids=${entry.entryType.category.id}&entry_type_id=${entry.entryType.id}&years=${entry.year}`,
      `More Entries from ${entry.entryType.category.name} in ${entry.entryType.name}`
    ),
    createComponentGrouping(
      `content_type=entries&company_names=${entry.company?.name}`,
      `More Entries from ${entry.company?.name}`
    ),
  ]);
};

const createComponentGrouping = async (
  searchQuery: string,
  title: string
): Promise<ComponentGrouping> => {
  const results = await GeminiProvider.getFilteredContent(
    QueryParams.parseSearchQuery(searchQuery)
  );
  return {
    type: "ComponentGrouping",
    title,
    variant: "carousel",
    searchQuery,
    componentsPerRow: defaultComponentsPerRowCarousel,
    componentsCollection: { items: results.content || [] },
  };
};

export const EntryShowService: Service = { retrievePageData };
