export const TEXT_CONSTS = {
  AWARD: "Award",
  CAMPAIGN: "Campaign",
  SHORTLIST: "Shortlist",
  BRAND: "Brand",
  AGENCY: "Agency",
  ITEM: "Item",
  RESULT: "result",
  PERSON: "Person",
};

export const pluralize = (num: number, word: string) => {
  if ([1, -1].includes(Number(num))) {
    return word;
  } else {
    return IRREGULAR_PLURALS[word?.toLowerCase()] || `${word}s`;
  }
};

const IRREGULAR_PLURALS: { [key: string]: string } = {
  person: "people",
  agency: "agencies",
};

export const SITE_TITLE = "THE WORK";
