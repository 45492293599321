import { ListGroupData } from "./components.d";
import { Container, ListGroup, ListCell } from "@horizon/components";
import { Pagination } from "@/components";

export const ListGroupComponent = (
  listGroupData: ListGroupData
): JSX.Element => {
  const { title, rows, page, pageSize, totalRecords, id } = listGroupData;
  return (
    <Container>
      <ListGroup textWrap title={title} id={id}>
        {rows?.map((row, index) => {
          return (
            <ListCell
              key={index}
              backgroundColor={row.backgroundColor || "white"}
              label={{ copy: row.copy }}
              rightLabel={row.rightLabel}
              subText={row.subText}
              superText={row.superText}
              cellLink={row.cellLink}
            />
          );
        })}
      </ListGroup>
      <Pagination page={page} pageSize={pageSize} totalRecords={totalRecords} />
    </Container>
  );
};
