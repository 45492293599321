import {
  getUserAccessToken,
  getUserActions,
} from "@/libs/authentication/accessToken";
import {
  getUser,
  AuthenticatedUser,
  AuthenticatedUserContext,
  useAuthenticatedUser,
  userHasAction,
  signinHrefPath,
  USER_ACTIONS,
} from "@/libs/authentication/user";

export const Authentication = {
  getUserAccessToken,
  getUser,
  userHasAction,
  signinHrefPath,
  getUserActions,
  useAuthenticatedUser,
  AuthenticatedUserContext,
  USER_ACTIONS,
};
export type { AuthenticatedUser };
export * from "@/libs/ErrorHandler";
