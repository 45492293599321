import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_CAMPAIGN_FILTERS } from "@/services/providers/gemini/queries/filter_query";
import { FilterOption } from "@/services/providers/gemini/types/filters";

export const getCampaignFilters = async (
  queryParams: any,
  accessToken?: string
): Promise<Array<FilterOption>> => {
  const { getCampaignFilterOptions: options } = (await callGemini(
    { query: GET_CAMPAIGN_FILTERS, variables: queryParams },
    accessToken
  )) || { getCampaignFilterOptions: [] };
  return options;
};
