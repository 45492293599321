import type {
  AccordionData,
  AnnouncementData,
  AvatarData,
  AwardsBlockData,
  CaptionData,
  CardData,
  CollapsibleNavigationLayoutData,
  CollectionActionsData,
  CollectionListingData,
  ComponentData,
  ComponentGroupingData,
  ContainerData,
  DescriptionBlockData,
  EventItemData,
  FeaturePanelData,
  FilterBlockData,
  FormData,
  GalleryBlockData,
  GalleryCarouselData,
  HeroBlockData,
  HeroTitleData,
  LabelCaptionData,
  LinkData,
  ListGroupData,
  LivePLayerData,
  MarkdownData,
  MarketoFormData,
  MediaPlayerData,
  PageTitleBlockData,
  PageTitleData,
  PaywallData,
  QuoteData,
  RankingsBlock,
  RichTextBlockData,
  ScheduleData,
  SearchBarDataExtend,
  SearchContainerData,
  SearchPageTitleData,
  SectionBlockData,
  SpacerData,
  TabContainerData,
  TableData,
  TagData,
  TagListData,
  TextComponentData,
  TextData,
  UploadFileData,
} from "@/renderers";

import {
  AccordionComponent,
  AnnouncementComponent,
  AvatarComponent,
  AwardsBlock,
  CardComponent,
  CollapsibleNavigationLayoutComponent,
  CollectionActions,
  CollectionListing,
  DescriptionBlockComponent,
  EventItemComponent,
  FeaturePanelComponent,
  FiltersComponent,
  FormComponent,
  GalleryBlockComponent,
  GalleryCarouselComponent,
  GroupingComponent,
  HeroBlockComponent,
  LabelCaptionComponent,
  LinkComponent,
  ListGroupComponent,
  LivePlayerComponent,
  MarkdownComponent,
  MarketoFormComponent,
  MediaPlayerComponent,
  PageTitleBlockFactory,
  PageTitleComponent,
  PaywallComponent,
  QuoteComponent,
  RankingsBlockComponent,
  RichTextBlockComponent,
  RichTextComponent,
  ScheduleComponent,
  SearchBarComponent,
  SearchContainerComponent,
  SectionComponent,
  TabContainerComponent,
  TableComponent,
  TagComponent,
  TagListComponent,
  TextBlockComponent,
  TextComponent,
  UploadFileComponent,
} from "@/components";
import { CaptionComponent } from "@/components/CaptionComponent";
import { HeroTitleComponent } from "@/components/HeroTitleComponent";
import { SpacerComponent } from "@/components/SpacerComponent";
import { ContainerComponent } from "@/components/ContainerComponent";
import { SearchPageTitle } from "@/components/SearchPageTitle";

function render(
  componentData: ComponentData,
  index?: number
): JSX.Element | null {
  switch (componentData?.type) {
    case "Accordion":
      return (
        <AccordionComponent {...(componentData as AccordionData)} key={index} />
      );
    case "Announcement":
      return (
        <AnnouncementComponent
          {...(componentData as AnnouncementData)}
          key={index}
        />
      );
    case "Avatar":
      return <AvatarComponent {...(componentData as AvatarData)} key={index} />;
    case "AwardsBlock":
      return (
        <AwardsBlock {...(componentData as AwardsBlockData)} key={index} />
      );
    case "Caption":
      return (
        <CaptionComponent {...(componentData as CaptionData)} key={index} />
      );
    case "Card":
      return <CardComponent {...(componentData as CardData)} key={index} />;
    case "CollapsibleNavigationLayout":
      return (
        <CollapsibleNavigationLayoutComponent
          key={Math.random()}
          {...(componentData as CollapsibleNavigationLayoutData)}
        />
      );
    case "CollectionActions":
      return (
        <CollectionActions
          {...(componentData as CollectionActionsData)}
          key={index}
        />
      );
    case "CollectionListing":
      return (
        <CollectionListing
          {...(componentData as CollectionListingData)}
          key={index}
        />
      );
    case "ComponentGrouping":
      return (
        <GroupingComponent
          {...(componentData as ComponentGroupingData)}
          key={index}
        />
      );
    case "Container":
      return renderContainer(componentData as ContainerData, index);
    case "DescriptionBlock":
      return (
        <DescriptionBlockComponent
          {...(componentData as DescriptionBlockData)}
          key={index}
        />
      );
    case "EventItem":
      return (
        <EventItemComponent
          {...(componentData as EventItemData)}
          key={(componentData as EventItemData).id}
        />
      );
    case "FeaturePanel":
      return (
        <FeaturePanelComponent
          {...(componentData as FeaturePanelData)}
          key={index}
        />
      );
    case "FilterBlock":
      return (
        <FiltersComponent {...(componentData as FilterBlockData)} key={index} />
      );
    case "Form":
      return <FormComponent {...(componentData as FormData)} key={index} />;
    case "GalleryBlock":
      return (
        <GalleryBlockComponent
          {...(componentData as GalleryBlockData)}
          key={index}
        />
      );
    case "GalleryCarousel":
      return (
        <GalleryCarouselComponent {...(componentData as GalleryCarouselData)} />
      );
    case "HeroBlockData":
      return (
        <HeroBlockComponent {...(componentData as HeroBlockData)} key={index} />
      );
    case "HeroTitle":
      return (
        <HeroTitleComponent {...(componentData as HeroTitleData)} key={index} />
      );
    case "HTMLTextBlock":
      return <TextBlockComponent {...(componentData as any)} key={index} />;
    case "LabelCaption":
      return (
        <LabelCaptionComponent
          {...(componentData as LabelCaptionData)}
          key={index}
        />
      );
    case "Link":
      return <LinkComponent {...(componentData as LinkData)} key={index} />;
    case "ListGroup":
      return (
        <ListGroupComponent {...(componentData as ListGroupData)} key={index} />
      );
    case "LivePlayer":
      return (
        <LivePlayerComponent
          {...(componentData as LivePLayerData)}
          key={index}
        />
      );
    case "Markdown":
      return (
        <MarkdownComponent {...(componentData as MarkdownData)} key={index} />
      );
    case "MarketoForm":
      return (
        <MarketoFormComponent
          {...(componentData as MarketoFormData)}
          key={index}
        />
      );
    case "MediaPlayerData":
      return (
        <MediaPlayerComponent
          {...(componentData as MediaPlayerData)}
          key={index}
        />
      );
    case "PageTitle":
      return (
        <PageTitleComponent {...(componentData as PageTitleData)} key={index} />
      );
    case "PageTitleBlock":
      return PageTitleBlockFactory.buildPageTitleBlockComponent(
        componentData as PageTitleBlockData,
        index
      );
    case "Paywall":
      return (
        <PaywallComponent {...(componentData as PaywallData)} key={index} />
      );
    case "Quote":
      return <QuoteComponent {...(componentData as QuoteData)} key={index} />;
    case "RankingsBlock":
      return (
        <RankingsBlockComponent
          {...(componentData as RankingsBlock)}
          key={index}
        />
      );
    case "Schedule":
      return (
        <ScheduleComponent {...(componentData as ScheduleData)} key={index} />
      );
    case "SearchBar":
      return (
        <SearchBarComponent
          {...(componentData as SearchBarDataExtend)}
          key={index}
        />
      );
    case "SearchContainer":
      return (
        <SearchContainerComponent
          {...(componentData as SearchContainerData)}
          key={index}
        />
      );
    case "SearchPageTitle":
      return (
        <SearchPageTitle
          {...(componentData as SearchPageTitleData)}
          key={index}
        />
      );
    case "SectionBlock":
      return renderSection(componentData as SectionBlockData, index);
    case "Spacer":
      return renderSpacer(componentData as SpacerData, index);
    case "TabContainer":
      return (
        <TabContainerComponent
          {...(componentData as TabContainerData)}
          key={index}
        />
      );
    case "Table":
      return <TableComponent {...(componentData as TableData)} key={index} />;
    case "TagList":
      return (
        <TagListComponent {...(componentData as TagListData)} key={index} />
      );
    case "Tag":
      return <TagComponent {...(componentData as TagData)} key={index} />;
    case "Text":
      return <TextComponent {...(componentData as TextData)} key={index} />;
    case "TextComponent":
      return (
        <RichTextComponent
          {...(componentData as TextComponentData)}
          key={index}
        />
      );
    case "RichTextBlock":
      return (
        <RichTextBlockComponent
          {...(componentData as RichTextBlockData)}
          key={index}
        />
      );
    case "UploadFile":
      return <UploadFileComponent {...(componentData as UploadFileData)} />;
  }
  return null;
}

const renderSpacer = (spacerData: SpacerData, index: number | undefined) => {
  if (!spacerData) {
    return null;
  }
  return (
    <SpacerComponent spacerData={spacerData} key={index} index={index}>
      {
        spacerData?.content?.map(
          (item, index) => item && render(item, index)
        ) as JSX.Element[]
      }
    </SpacerComponent>
  );
};

const renderSection = (
  sectionBlock: SectionBlockData,
  index: number | undefined
) => {
  return (
    <SectionComponent sectionData={sectionBlock} key={index}>
      {
        sectionBlock?.content?.map(
          (item, index) => item && render(item, index)
        ) as JSX.Element[]
      }
    </SectionComponent>
  );
};

const renderContainer = (
  containerBlock: ContainerData,
  index: number | undefined
) => {
  return (
    <ContainerComponent containerData={containerBlock} key={index}>
      {
        containerBlock?.children?.map(
          (item, index) => item && render(item, index)
        ) as JSX.Element[]
      }
    </ContainerComponent>
  );
};

export const ComponentRenderer = {
  render,
};
