import { PageTitleData } from "@/renderers";
import { PageTitle } from "@horizon/components";
import { buildKey } from "./libs";

export const PageTitleComponent = (
  { size, content, id }: PageTitleData,
  index?: number
) => {
  return (
    <PageTitle key={buildKey("pagetitle", index)} size={size} id={id}>
      {content}
    </PageTitle>
  );
};
