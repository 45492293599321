import { logger } from "@/libs/Logger";
import { GraphQLQuery } from "@/services/libs/grapqhl";

export const callPortal = async (query: GraphQLQuery) => {
  const URL = (process.env && process.env.PORTAL_GRAPHQL_ENDPOINT) || "";
  const apiKey = (process.env && process.env.PORTAL_API_KEY) || "";

  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "graphql-api-key": apiKey,
    },
    body: JSON.stringify(query),
  };

  try {
    const result = await fetch(URL, options);
    if (result.status != 200) {
      logger.error(
        `Received status code not 200 when calling Portal: ${result.status} for query: ${query}`
      );
      return null;
    }
    const { data, errors } = await result.json();
    if (result.ok && errors == undefined) {
      return data;
    } else {
      logger.error(errors?.map((e: any) => e.message).join("\n") ?? "unknown");
      return null;
    }
  } catch (err) {
    logger.info(err);
  }
};
