import { MouseEvent } from "react";
import { Pagination_v2 } from "@horizon/components";
import { QueryParams } from "@/libs/QueryParams";

export const Pagination = ({
  page,
  pageSize,
  totalRecords,
}: {
  page?: number;
  pageSize?: number;
  totalRecords?: number;
}) => {
  if (!page || !pageSize || !totalRecords) return null;
  return (
    <Pagination_v2
      active={page}
      pageSize={pageSize}
      total={totalRecords}
      onItemClick={(
        clickEvent: MouseEvent<HTMLAllCollection>,
        page: number
      ) => {
        QueryParams.replaceQueryParam("page", `${page}`);
      }}
    />
  );
};
