import { Authentication } from "@/libs";
import { QueryParams } from "@/libs/QueryParams";
import { PageData } from "@/renderers";
import { ServiceArgs } from "@/services/";
import { IndividualShowMapper } from "@/services/directory/individual_show/individual_show_mapper";
import {
  PATHS,
  SITE_TITLE,
  buildContainerData,
  buildSectionData,
  getIdFromSlug,
  maybeCreatePaywall,
  maybeRedirectToSlug,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { GeminiProvider } from "@/services/providers";
import { Individual, Pagination } from "@/services/providers/gemini/types";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken, queryParams } = serviceArgs;
  if (path.startsWith(`${PATHS.people}/`)) {
    const id = getIdFromSlug(path);
    const parsedQuery = QueryParams.parseSearchQuery(queryParams);
    delete parsedQuery?.id;

    const { pagination, individual } = await GeminiProvider.getIndividualById(
      id,
      parsedQuery,
      accessToken
    );

    if (individual) {
      maybeRedirectToSlug(path, id, PATHS.people, individual.slug);

      const result = {
        title: `${SITE_TITLE} | INDIVIDUAL | ${individual.fullname}`,
        metaDescription: individual.fullname,
        slug: `${PATHS.people}/${individual.slug}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.people}/${individual.slug}`,
        metaTitle: individual.fullname,
        metaImage: "",
        components: [
          ...buildComponents(individual, pagination),
          maybeCreatePaywall(
            serviceArgs,
            Authentication.USER_ACTIONS.INDIVIDUALS_VIEW
          ),
        ].filter(Boolean),
      };
      return stripUndefinedValuesFromObject(result);
    }
    return null;
  }
  return null;
};

const buildComponents = (
  individual: Individual,
  pagination: Pagination | null
) => {
  return [
    IndividualShowMapper.toTitleData(individual),
    buildSectionData([
      buildContainerData([
        IndividualShowMapper.toCampaignCountData(pagination),
      ]),
    ]),
    IndividualShowMapper.toCampaignGrouping(individual, pagination),
  ];
};

export const IndividualShowService = { retrievePageData };
