import { useEffect, useState } from "react";

export const useWindowWidth = (): number => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = (): void =>
      setWidth(typeof window !== "undefined" ? window.innerWidth : 0);
    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  return width;
};

export default useWindowWidth;
