import { callRestGeminiGet } from "@/services/providers/gemini/gemini_provider";

import { UserProfile } from "@/services/providers/gemini/types";

export const getMyProfile = async (
  accessToken?: string
): Promise<UserProfile | null> => {
  const results = await callRestGeminiGet("/api/me", accessToken);
  if (!results) {
    return null;
  } else {
    results["permit_marketing"] = results["permitMarketing"];
    delete results["permitMarketing"];
    return results;
  }
};
