import { Authentication } from "@/libs";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { ComponentData, PageData } from "@/renderers";
import { Service, ServiceArgs } from "@/services/";
import { AgencyShowMapper } from "@/services/directory/agency_show/agency_show_mapper";
import {
  PATHS,
  SITE_TITLE,
  buildContainerData,
  buildSectionData,
  getIdFromSlug,
  maybeCreatePaywall,
  maybeRedirectToSlug,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { GeminiProvider } from "@/services/providers";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Agency, Pagination } from "@/services/providers/gemini/types";
import { FilterOption } from "@/services/providers/gemini/types/filters";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken, queryParams } = serviceArgs;
  if (path.startsWith(`${PATHS.agencies}/`)) {
    const id = getIdFromSlug(path);
    const parsedQuery = QueryParams.parseSearchQuery(
      appendAgencyIdAndContentType(queryParams || "", id)
    );
    delete parsedQuery?.id;
    const filterOptions = await GeminiProvider.getCampaignFilters(
      parsedQuery,
      accessToken
    );
    const { agency, pagination } = await GeminiProvider.getAgencyById(
      id,
      parsedQuery,
      accessToken
    );
    if (agency) {
      maybeRedirectToSlug(path, id, PATHS.agencies, agency.slug);

      const result = {
        title: `${SITE_TITLE} | Agency | ${agency.name}`,
        metaDescription: agency.name,
        slug: `${PATHS.agencies}/${agency.slug}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.agencies}/${agency.slug}`,
        metaTitle: agency.name,
        metaImage: { url: agency.campaigns[0]?.assets[0].thumbnailUrl || "" },
        components: [
          ...buildComponents(agency, pagination, filterOptions, parsedQuery),
          maybeCreatePaywall(
            serviceArgs,
            Authentication.USER_ACTIONS.AGENCIES_VIEW
          ),
        ].filter(Boolean) as ComponentData[],
      };
      return stripUndefinedValuesFromObject(result);
    }
  }
  return null;
};

const buildComponents = (
  agency: Agency,
  pagination: Pagination | null,
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery
) => {
  return [
    AgencyShowMapper.toTitleData(agency, pagination),
    buildSectionData([
      GeminiMapper.toFilterBlockData(
        filterOptions,
        parsedQuery,
        buildSortOptions(parsedQuery)
      ),
    ]),
    AgencyShowMapper.toCampaignGrouping(agency, pagination),
  ];
};

const SORT_OPTIONS = [
  { value: "date_descending", label: "Newest to oldest" },
  { value: "date_ascending", label: "Oldest to newest" },
  { value: "relevance", label: "Best match" },
  { value: "most_awarded", label: "Most awarded" },
  { value: "title_ascending", label: "Title - A-Z" },
  { value: "title_descending", label: "Title - Z-A" },
];

const buildSortOptions = (
  parsedQuery: ParsedQuery
): {
  options: { label: string; value: string }[];
  initialValue: string;
} => ({
  options: SORT_OPTIONS,
  initialValue: parsedQuery?.sort ? (parsedQuery.sort as string) : "relevance",
});

const appendAgencyIdAndContentType = (
  queryString: string,
  agency_id: string
) => {
  if (queryString.length > 1) {
    return queryString.concat(`&agency_id=${agency_id}&content_type=agency`);
  }
  return `agency_id=${agency_id}&content_type=agency`;
};

export const AgencyShowService: Service = { retrievePageData };
