import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_REPORT_BY_ID } from "@/services/providers/gemini/queries/editorial_query";
import { Editorial } from "@/services/providers/gemini/types";

export const getReportById = async (
  id: string,
  accessToken?: string
): Promise<Editorial> => {
  const { getReportForId: report } = await callGemini(
    { query: GET_REPORT_BY_ID, variables: { id: id } },
    accessToken
  );

  if (report.paywall.copy) {
    report.paywall.copy = { json: JSON.parse(report.paywall.copy) };
    report.paywall.ctasCollection = { items: report.paywall.ctas };
  } else {
    report.paywall = null;
  }

  return report;
};
