import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";
import { trackEvent } from "@/libs/analytics/Analytics";
import purgePii from "@/libs/analytics/purge_pii";
import { PageData } from "@/renderers";
import { useEffect } from "react";

export const AnalyticsPageTracking = ({
  children,
  pageData,
}: {
  children: React.ReactNode;
  pageData: PageData;
}) => {
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  useEffect(() => {
    const { cannonicalUrl } = pageData;
    trackEvent(
      clientParams,
      {
        event: "Page Transition",
        type: "Page View",
        label: purgePii(cannonicalUrl),
      },
      user
    );

    trackEvent(
      clientParams,
      {
        event: "VirtualPageView",
        label: "Virtual Page View",
        type: "Page View",
        virtualPageURL: purgePii(cannonicalUrl),
        virtualPageTitle: pageData.title,
      },
      user
    );
  }, [pageData, user, clientParams]);

  return <>{children}</>;
};
