import React from "react";
import {
  AwardTag,
  DefaultTag,
  FestivalTag,
  IconTag,
  RatingTag,
  StatusTag,
} from "@horizon/components";
import { TagData } from "@/renderers";

export const buildKey = (prefix: string, index?: number) => {
  if (index != null) {
    return `${prefix}-${index}`;
  }
  return undefined;
};

export const tagToTagProps = (tag?: TagData) => {
  if (!tag) {
    return null;
  }
  const component = tagComponent(tag.component);
  return {
    copy: tag.content,
    component: component,
    variant: tag.variant,
  };
};

const tagComponent = (component: string) => {
  switch (component) {
    case "Default":
      return DefaultTag;
    case "Award":
      return AwardTag;
    case "Icon":
      return IconTag;
    case "Festival":
      return FestivalTag;
    case "Rating":
      return RatingTag;
    case "Status":
      return StatusTag;
    default:
      return DefaultTag;
  }
};

export const createId = (value: string | undefined) =>
  value?.toLowerCase().replaceAll(" ", "-");
