import { Api } from "@/libs/api";
import {
  DownloadEvents,
  DownloadLimitNearEventPayload,
  PubSubProvider,
} from "@/libs/pubSub";

export const checkDownloadCount = () => {
  Api.getUserData().then((data) => {
    const { currentDownloads, totalAvailableDownloads, warningLimit } = data;
    if (currentDownloads >= totalAvailableDownloads) {
      PubSubProvider.pubishEvent<{}>(DownloadEvents.DOWNLOAD_LIMIT_REACHED, {});
      return true;
    }

    if (totalAvailableDownloads - warningLimit <= currentDownloads) {
      PubSubProvider.pubishEvent<DownloadLimitNearEventPayload>(
        DownloadEvents.DOWNLOAD_LIMIT_NEARED,
        {
          currentDownloads,
          totalAvailableDownloads,
        }
      );
      return true;
    }
  });
};
