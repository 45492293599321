import { ParsedQuery } from "@/libs/QueryParams";
import { FilterBlockData, FilterData } from "@/renderers";
import { FilterOption } from "@/services/providers/gemini/types";

export const toFilterBlockData = (
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery,
  sortOptions?: {
    options: { label: string; value: string }[];
    initialValue: string;
  }
): FilterBlockData => ({
  type: "FilterBlock",
  sortFilter: sortOptions && parseSortOptions(sortOptions),
  filters: parseFilterOptions(filterOptions, parsedQuery),
});

const parseSortOptions = (sortOptions: {
  options: { label: string; value: string }[];
  initialValue: string;
}): FilterData => ({
  key: "sort",
  options: sortOptions?.options || [],
  initialValue: sortOptions?.initialValue,
  label: "Sort by: ",
});

export const parseFilterOptions = (
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery
): FilterData[] => {
  return filterOptions.map((campaignFilter) =>
    toFilterData(campaignFilter, parsedQuery)
  );
};

const toFilterData = (
  filterOption: FilterOption,
  parsedQuery: ParsedQuery
): FilterData => {
  const { key, values, label } = filterOption;
  const initialValue = parsedQuery ? parsedQuery[key] : [];
  return {
    key: key,
    label: label,
    options: values?.filter(Boolean).map((option) => ({
      label: option.label,
      value: option.key,
    })),
    initialValue: initialValue,
    multiselect: isMultiselect(filterOption, parsedQuery),
  };
};

const isMultiselect = (
  filterOption: FilterOption,
  parsedQuery: any
): boolean => {
  const { content_type } = parsedQuery;
  const { label } = filterOption;
  if (content_type === "entries" && label === "Year") {
    return false;
  }

  return true;
};
