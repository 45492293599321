import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_RELATED_CAMPAIGNS } from "@/services/providers/gemini/queries/campaign_query";
import { Campaign } from "@/services/providers/gemini/types";

export const getRelatedCampaigns = async (
  id: string,
  accessToken?: string
): Promise<[Campaign] | []> => {
  const results = await callGemini(
    { query: GET_RELATED_CAMPAIGNS, variables: { campaignId: parseInt(id) } },
    accessToken
  );
  if (results) {
    const { getRelatedCampaigns: campaigns } = results;
    return campaigns;
  } else {
    return [];
  }
};
