import { callGemini } from "@/services/providers/gemini/gemini_provider";
import {
  GET_TALKS_BY_ID,
  GET_TALKS_BY_EXTERNAL_IDS,
} from "@/services/providers/gemini/queries/talk_query";
import { Talk } from "@/services/providers/gemini/types";

export const getTalkById = async (
  id: string,
  accessToken?: string
): Promise<Talk> => {
  const {
    getTalksByIds: [talk],
  } = await callGemini(
    { query: GET_TALKS_BY_ID, variables: { id: parseInt(id, 10) } },
    accessToken
  );
  return talk;
};

export const getTalksByExternalIds = async (
  ids: number[],
  accessToken?: string
): Promise<[Talk] | []> => {
  const response = await callGemini(
    { query: GET_TALKS_BY_EXTERNAL_IDS, variables: { externalIds: ids } },
    accessToken
  );
  const { getTalksByExternalIds: talks } = response;

  return talks;
};
