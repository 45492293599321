import { PAGINATION_FRAGMENT } from "@/services/providers/gemini/queries/shared_fragments/pagination_fragment";
import { gql } from "graphql-request";
import { CAMPAIGN_FRAGMENT } from "./campaign_query";

const PORTFOLIO_FRAGMENT = gql`
  fragment PortfolioFragment on Portfolio {
    access
    city
    company
    country
    fullname
    jobtitle
    role
    slug
    awards {
      count
      level
      levelLabel
      sortCode
    }
    campaigns {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;

export const GET_PORTFOLIO_BY_ID = gql`
  query PortfolioById(
    $portfolio_id: Int!
    $award_levels: [String]
    $countries: [String]
    $entered_lions: [String]
    $festival_name: [String]
    $media_categories: [String]
    $sectors: [String]
    $search_text: String
    $sort: String
    $years: [Int]
    $page: Int
  ) {
    getPortfolioWithFilteredCampaigns(
      campaignFilters: {
        awards: $award_levels
        countries: $countries
        enteredLions: $entered_lions
        festivalName: $festival_name
        mediaCategories: $media_categories
        searchText: $search_text
        sectors: $sectors
        sort: $sort
        years: $years
        page: $page
      }
      id: $portfolio_id
    ) {
      portfolio {
        ...PortfolioFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PORTFOLIO_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`;

export const GET_INDIVIDUAL_FILTERS = gql`
  query getPortfolioFilterOptions(
    $awards: [String]
    $countries: [String]
    $interests: [String]
    $page: Int
    $roles: [String]
    $search_text: String
    $skills: [String]
    $sort: String
  ) {
    getPortfolioFilterOptions(
      filters: {
        awards: $awards
        countries: $countries
        interests: $interests
        page: $page
        roles: $roles
        searchText: $search_text
        skills: $skills
        sort: $sort
      }
    ) {
      key
      label
      values {
        count
        label
        key
      }
    }
  }
`;

export const GET_FILTERED_PORTFOLIOS = gql`
  query filteredPortfolios(
    $award_levels: [String]
    $countries: [String]
    $interests: [String]
    $page: Int
    $roles: [String]
    $search_text: String
    $skills: [String]
    $sort: String
  ) {
    filteredPortfolios(
      filters: {
        awards: $award_levels
        countries: $countries
        interests: $interests
        page: $page
        roles: $roles
        searchText: $search_text
        skills: $skills
        sort: $sort
      }
    ) {
      portfolios {
        id
        awardCount
        fullname
        jobtitle
        slug
        campaigns {
          id
        }
      }
      pagination {
        currentPage
        pageSize
        totalRecords
      }
    }
  }
`;
