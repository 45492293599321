import { ContainerData } from "@/renderers";
import { Container } from "@horizon/components";

export const ContainerComponent = ({
  containerData,
  children,
}: {
  containerData: ContainerData;
  children: JSX.Element | JSX.Element[] | null;
}): JSX.Element => {
  return <Container {...containerData}>{children}</Container>;
};
