import { LivePLayerData } from "@/renderers";
import { LivePlayer } from "@horizon/components";
import { buildKey } from "../libs";

export const LivePlayerComponent = (
  { liveStreamUrl, id }: LivePLayerData,
  index?: number
) => {
  return (
    <LivePlayer
      id={id}
      key={buildKey("livePlayer", index)}
      videoUrl={liveStreamUrl}
    />
  );
};
