import { init } from "./Analytics";
import { AnalyticsPageTracking } from "@/libs/analytics/PageTracking";
import {
  trackAddToCalendar,
  trackDownload,
  trackFilterInteraction,
  trackFormSubmit,
  trackMarketoFormSubmit,
  trackPdfViewInteraction,
  trackRegistrationFormFilled,
  trackSearchTextEntered,
  trackSortInteraction,
  trackVideoView,
} from "@/libs/analytics/Events";

export const Analytics = {
  AnalyticsPageTracking,
  init,
  trackAddToCalendar,
  trackDownload,
  trackFilterInteraction,
  trackFormSubmit,
  trackMarketoFormSubmit,
  trackPdfViewInteraction,
  trackRegistrationFormFilled,
  trackSearchTextEntered,
  trackSortInteraction,
  trackVideoView,
};
