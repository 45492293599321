import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { PageData } from "@/renderers";
import { ServiceArgs } from "@/services";
import { CreateProfileMapper } from "@/services/account/create_profile/create_profile_mapper";
import {
  buildContainerData,
  SITE_TITLE,
  stripUndefinedValuesFromObject,
} from "@/services/libs";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { queryParams } = serviceArgs;

  const parsedQueryParams = QueryParams.parseSearchQuery(queryParams);
  const result = {
    title: `${SITE_TITLE} | ACCOUNT | CREATE PROFILE`,
    components: buildComponents(parsedQueryParams),
  };
  return stripUndefinedValuesFromObject(result);
};

const buildComponents = (parsedQuery: ParsedQuery) => {
  return [
    buildContainerData([
      CreateProfileMapper.toFormData(
        parsedQuery?.token as string,
        parsedQuery?.state as string
      ),
      CreateProfileMapper.toTermsAndConditionsTextBlock(),
    ]),
  ];
};

export const CreateProfileService = { retrievePageData };
