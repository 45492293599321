import { ComponentData, PageData } from "@/renderers";
import { Service, ServiceArgs } from "@/services/";
import { GeminiProvider } from "@/services/providers";
import {
  PATHS,
  SITE_TITLE,
  buildContainerData,
  buildSectionData,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { CollectionShowMapper } from "./collection_show_mapper";
import { Playlist } from "@/services/providers/gemini/types";
import { Authentication } from "@/libs";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { accessToken, path } = serviceArgs;
  if (path.startsWith("/account/collections/")) {
    const id = path.replace("/account/collections/", "");
    const collection = await GeminiProvider.getCollection(id, accessToken);

    if (collection) {
      const result = {
        title: `${collection.name} | Collections | ${SITE_TITLE}`,
        metaDescription: ` ${collection.name}`,
        slug: `${PATHS.collections}/${collection.id}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.collections}/${collection.id}`,
        metaTitle: `Account - Collections - ${collection.name}`,
        components: [
          ...myCollectionBuildComponents(collection),
          maybeCreatePaywall(
            serviceArgs,
            Authentication.USER_ACTIONS.COLLECTIONS_VIEW
          ),
        ].filter(Boolean) as ComponentData[],
      };
      return stripUndefinedValuesFromObject(result);
    }
  }

  if (path.startsWith("/shared-collection/")) {
    const id = path.replace("/shared-collection/", "");
    const collection = await GeminiProvider.getCollection(id, accessToken);

    if (collection) {
      const result = {
        title: `Account - Collections - ${collection.name}`,
        metaDescription: ` ${collection.name}`,
        slug: `${PATHS.collections}/${collection.id}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.collections}/${collection.id}`,
        metaTitle: `Account - Collections - ${collection.name}`,
        components: [...othersCollectionBuildComponents(collection)].filter(
          Boolean
        ) as ComponentData[],
      };
      return stripUndefinedValuesFromObject(result);
    }
  }

  return null;
};

const myCollectionBuildComponents = (collection: Playlist) => {
  return [
    CollectionShowMapper.toTitleData(collection),
    buildSectionData([
      buildContainerData([
        CollectionShowMapper.toCollectionActions(collection, false),
      ]),
    ]),
    CollectionShowMapper.toCollectionListing(collection, true),
  ];
};

const othersCollectionBuildComponents = (collection: Playlist) => {
  return [
    CollectionShowMapper.toTitleData(collection),
    buildSectionData([
      buildContainerData([
        CollectionShowMapper.toCollectionActions(collection, true),
      ]),
    ]),
    CollectionShowMapper.toCollectionListing(collection, false),
  ];
};

export const CollectionShowService: Service = { retrievePageData };
