import { gql } from "graphql-request";

export const GET_CAMPAIGN_FILTERS = gql`
  query (
    $awards: [String]
    $countries: [String]
    $enteredLions: [String]
    $festivalName: [String]
    $ids: [Int]
    $mediaCategories: [String]
    $search_text: String
    $years: [Int]
  ) {
    getCampaignFilterOptions(
      filters: {
        awards: $awards
        countries: $countries
        enteredLions: $enteredLions
        festivalName: $festivalName
        ids: $ids
        mediaCategories: $mediaCategories
        search_text: $search_text
        years: $years
      }
    ) {
      key
      label
      values {
        count
        key
        label
      }
    }
  }
`;

export const GET_ENTRY_FILTERS = gql`
  query (
    $ids: [Int]
    $years: [Int]
    $festival_name: [String]
    $award_levels: [String]
    $category_ids: [Int]
    $entry_type_id: [Int]
    $sort: String
  ) {
    getEntryFilterOptions(
      filters: {
        ids: $ids
        years: $years
        festivalName: $festival_name
        awards: $award_levels
        categoryIds: $category_ids
        entryTypeId: $entry_type_id
        sort: $sort
      }
    ) {
      key
      label
      values {
        count
        key
        label
      }
    }
  }
`;

export const GET_TALK_FILTERS = gql`
  query (
    $ids: [Int]
    $year: [Int]
    $theme: [String]
    $festival_name: [String]
    $search_text: String
    $sort: String
  ) {
    getTalkFilterOptions(
      filters: {
        ids: $ids
        year: $year
        theme: $theme
        festival_name: $festival_name
        searchText: $search_text
        sort: $sort
      }
    ) {
      key
      label
      values {
        count
        key
        label
      }
    }
  }
`;
