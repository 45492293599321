import { GeminiProvider } from "@/services/providers";

import type { ComponentData, PageData } from "@/renderers";
import type { Service, ServiceArgs } from "../..";
import {
  PATHS,
  SITE_TITLE,
  Sort,
  TEXT_CONSTS,
  buildSectionData,
  defaultComponentsPerRow,
  formatNumber,
  maybeCreatePaywall,
  pluralize,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import {
  FilterOption,
  FilteredContent,
  Talk,
} from "@/services/providers/gemini/types";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Authentication } from "@/libs";
import { talkToCard } from "@/services/providers/gemini/mappers/component_grouping";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { accessToken, queryParams } = serviceArgs;
  const parsedQuery = QueryParams.parseSearchQuery(
    appendCampaignsContentType(queryParams || "")
  );
  const filteredContent = await GeminiProvider.getFilteredContent(
    parsedQuery,
    accessToken
  );

  const filterOptions = await GeminiProvider.getTalkFilters(
    parsedQuery,
    accessToken
  );

  const result = {
    title: `Talks & Videos | ${SITE_TITLE}`,
    metaDescription: `Watch the talks and videos from ${SITE_TITLE}.}`,
    slug: `${PATHS.talks}`,
    cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.talks}`,
    metaTitle: `Talks & Videos | ${SITE_TITLE}`,
    components: await buildListingComponents(
      filteredContent,
      filterOptions,
      parsedQuery,
      serviceArgs
    ),
  };
  return stripUndefinedValuesFromObject(result);
};

const buildListingComponents = async (
  filteredContent: FilteredContent,
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery,
  serviceArgs: ServiceArgs
): Promise<ComponentData[]> => {
  const filterBlockData = GeminiMapper.toFilterBlockData(
    filterOptions,
    parsedQuery,
    Sort.buildSortOptions(
      Sort.CLASSIC_SORT_OPTIONS,
      Sort.parseSortValueFromQuery(parsedQuery, "date_descending")
    )
  );

  return [
    buildSectionData(
      [filterBlockData, toTalkGrid(filteredContent)].filter(Boolean),
      "Watch big ideas, on the small screen"
    ),
    maybeCreatePaywall(serviceArgs, Authentication.USER_ACTIONS.TALKS_VIEW),
  ].filter(Boolean) as ComponentData[];
};

const toTalkGrid = (
  filteredContent: FilteredContent
): ComponentData | undefined => {
  if (!filteredContent) return undefined;
  const { content, pagination } = filteredContent;

  return {
    type: "ComponentGrouping",
    title: `${formatNumber(pagination?.totalRecords)} ${pluralize(
      pagination?.totalRecords || 0,
      TEXT_CONSTS.RESULT
    )} found in Talks`,
    variant: "grid",
    componentsPerRow: defaultComponentsPerRow,
    components: content
      ? content.map((content) => talkToCard(content as Talk))
      : [],
    page: pagination?.currentPage,
    pageSize: pagination?.pageSize,
    totalRecords: pagination?.totalRecords,
    noResultsText: "UNFORTUNATELY, NO RESULTS WERE FOUND FOR YOUR SEARCH.",
  } as ComponentData;
};

const appendCampaignsContentType = (queryString: string) => {
  if (queryString.length > 1) {
    return queryString.concat("&content_type=talks");
  }
  return "content_type=talks";
};

export const TalksIndexService: Service = { retrievePageData };
