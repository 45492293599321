import { createId } from "@/components/libs";
import {
  CollectionActionsData,
  CollectionCardData,
  CollectionListingData,
  TagData,
  PageTitleBlockData,
  MediaPlayerData,
} from "@/renderers";
import {
  TEXT_CONSTS,
  listToCommaSeparatedString,
  pluralize,
  typeFromAsset,
} from "@/services/libs";

import { PATHS } from "@/services/libs";
import {
  Playlist,
  PlaylistAsset,
  PlaylistItem,
} from "@/services/providers/gemini/types";

const toTitleData = ({
  name,
  playlist_items,
}: Playlist): PageTitleBlockData => ({
  type: "PageTitleBlock",
  title: name,
  subText: `${playlist_items.length} Saved ${pluralize(
    playlist_items.length,
    TEXT_CONSTS.ITEM
  )}`,
});

const toCollectionActions = (
  {
    assets: assets,
    external_id,
    id: id,
    campaigns: campaigns,
    entries: entries,
    name: name,
  }: Playlist,
  restricted: boolean
): CollectionActionsData => {
  let actions = ["share", "play", "delete", "rename"];
  if (restricted) {
    actions = ["play"];
  }

  return {
    type: "CollectionActions",
    id: id,
    title: name,
    assets: assets.map((asset) => assetToMediaContent(asset, name)),
    campaignIds: campaigns.map((campaign) => campaign.id),
    entryIds: entries.map((entry) => entry.id),
    shareLink: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.sharedCollection}/${external_id}`,
    actions,
  };
};

const assetToMediaContent = (
  asset: PlaylistAsset,
  name: string
): MediaPlayerData => {
  return {
    type: "MediaPlayerData",
    media: {
      href: asset.full_asset,
      alt: asset.label,
      mediaType: typeFromAsset(asset),
      thumbnail: asset.thumbnail,
    },
    title: name,
    category: `Playlist - ${name}`,
    id: createId(name),
  };
};

const toCollectionListing = (
  { campaigns, entries, id, name, playlist_items }: Playlist,
  canRemove: boolean
): CollectionListingData => {
  return {
    type: "CollectionListing",
    campaignIds: campaigns.map((campaign) => campaign.id),
    canRemove,
    entryIds: entries.map((entry) => entry.id),
    components: playlist_items
      ? playlist_items
          .map((playlistItem: PlaylistItem) => {
            return playlistItemToCard(playlistItem);
          })
          .filter((item) => item !== null)
      : null,
    id,
    name,
  };
};

const playlistItemToCard = (
  playlistItem: PlaylistItem
): CollectionCardData | null => {
  switch (playlistItem.type) {
    case "entry":
      return {
        type: "Card",
        id: playlistItem.id,
        title: playlistItem.title,
        link: playlistItem.slug
          ? {
              label: playlistItem.title,
              href: `${PATHS.entries}/${playlistItem.slug}`,
            }
          : undefined,
        media: {
          href: (playlistItem.asset && playlistItem.asset.thumbnail) || "",
          alt: playlistItem.title || "",
          mediaType: "image/jpeg",
        },
        superText: playlistItem.category?.entry_type_name,
        subText: listToCommaSeparatedString([
          playlistItem.brand?.name,
          playlistItem.company?.name,
        ]),
        mediaTag: entryAwardToTagData(playlistItem),
        variant: "Entry",
      };
    case "campaign":
      return {
        type: "Card",
        id: playlistItem.id,
        title: playlistItem.title,
        tag:
          playlistItem.awards && playlistItem.awards.length > 0
            ? `${playlistItem.awards.length} ${pluralize(
                playlistItem.awards.length,
                TEXT_CONSTS.AWARD
              )}`
            : undefined,
        link: playlistItem.slug
          ? {
              label: playlistItem.title,
              href: `${PATHS.campaigns}/${playlistItem.slug}`,
            }
          : undefined,
        media: {
          href: (playlistItem.asset && playlistItem.asset.thumbnail) || "",
          alt: playlistItem.title || "",
          mediaType: "image/jpeg",
        },
        superText: playlistItem.category?.entry_type_name,
        subText: listToCommaSeparatedString([
          playlistItem.brand?.name,
          playlistItem.company?.name,
        ]),
        mediaTag: awardsToTagData(playlistItem),
        variant: "Campaign",
      };
  }
  return null;
};

const awardsToTagData = ({ awards }: PlaylistItem): TagData | undefined => {
  if (awards && awards.length) {
    return {
      type: "Tag",
      content: `${awards.length} ${pluralize(
        awards.length,
        TEXT_CONSTS.AWARD
      )}`,
      component: "Award",
    } as TagData;
  }
  return undefined;
};

const entryAwardToTagData = ({ award }: PlaylistItem): TagData | undefined => {
  if (award?.level) {
    let result: TagData = {
      type: "Tag",
      content: award?.level,
      component: "Award",
    };
    switch (award?.level) {
      case "titanium":
        result.variant = "titanium";
        break;
      case "grandprix":
        result.variant = "grandPrix";
        break;
      case "gold":
        result.variant = "gold";
        break;
      case "silver":
        result.variant = "silver";
        break;
      case "bronze":
        result.variant = "bronze";
        break;
      case "shortlist":
        result.variant = "shortlist";
        break;
    }
    return result as TagData;
  }
  return undefined;
};

export const CollectionShowMapper = {
  toTitleData,
  toCollectionActions,
  toCollectionListing,
};
