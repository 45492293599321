import { callRestGeminiGet } from "@/services/providers/gemini/gemini_provider";
import { Collection } from "@/services/providers/gemini/types";

export const getCollections = async (
  accessToken?: string
): Promise<Array<Collection>> => {
  if (!accessToken) return [];
  return await getPlaylists([], 0, accessToken);
};

const getPlaylists = async (
  playlists: Collection[],
  offset: number,
  accessToken?: string
): Promise<Array<Collection>> => {
  const response = await callRestGeminiGet(
    `/api/playlists?offset=${offset}`,
    accessToken
  );
  if (response && response.playlists) {
    playlists = playlists.concat(response.playlists);
  }
  if (response && response.next_page) {
    return await getPlaylists(playlists, offset + 25, accessToken);
  } else {
    return playlists;
  }
};
