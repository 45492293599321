import {
  SearchBarData,
  SpacerData,
  TableCell,
  TableData,
  TextData,
} from "@/renderers";
import { PATHS, TEXT_CONSTS, formatNumber, pluralize } from "@/services/libs";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Agency, Pagination } from "@/services/providers/gemini/types";

const toTitleText = (pagination: Pagination | null): string => {
  if (!pagination) {
    return `${TEXT_CONSTS.AGENCY}`;
  }

  return `${formatNumber(pagination?.totalRecords)} ${pluralize(
    pagination?.totalRecords || 0,
    TEXT_CONSTS.AGENCY
  ).toUpperCase()}`;
};

const toAgencyTable = (
  agencies: Agency[],
  pagination: Pagination | null
): TableData => {
  const rows = agencies.map((agency) => ({
    cells: [
      {
        label: {
          copy: agency.name,
          href: agency.slug ? `${PATHS.agencies}/${agency.slug}` : "",
        },
        separator: false,
        type: agency.slug ? "link" : "label",
      },
      agency.campaignCount && agency.campaignCount > 0
        ? {
            alignment: "right",
            cellWidth: "auto",
            tag: {
              content: `${formatNumber(agency.campaignCount)} ${pluralize(
                agency.campaignCount || 0,
                TEXT_CONSTS.CAMPAIGN
              )}`,
              component: "Festival",
              variant: "aggregated",
              type: "Tag",
            },
            type: "tag",
          }
        : {},
    ] as TableCell[],
  }));

  return GeminiMapper.toDirectoryTableData({
    headers: [],
    rows: rows || [],
    pagination: pagination || undefined,
  });
};

const toSearchBarData = (searchText: string): SearchBarData => ({
  type: "SearchBar",
  searchText: searchText,
  searchBarLabel: "Search agencies by name",
});

const toEmptyResultsText = (): TextData => ({
  type: "Text",
  content: "UNFORTUNATELY, NO RESULTS WERE FOUND FOR YOUR SEARCH.",
  size: "xxlarge",
});

const maybeAddNoResults = (pagination: Pagination | null): SpacerData => {
  const shouldShowNoResults = pagination?.totalRecords === 0;
  return {
    type: "Spacer",
    size: "large",
    content: shouldShowNoResults ? [toEmptyResultsText()] : undefined,
  };
};

export const AgencyListingMapper = {
  maybeAddNoResults,
  toAgencyTable,
  toSearchBarData,
  toTitleText,
};
