import { gql } from "graphql-request";

export const GET_RANKINGS = gql`
  query ($type: String!, $year: Int!, $category: Int!, $festivalCode: String!) {
    filteredRankings(
      filters: {
        type: $type
        year: $year
        category: $category
        festivalCode: $festivalCode
      }
    ) {
      rankingsHeaders {
        field
        label
      }
      rankings {
        name
        names
        agency
        city
        country
        position
        positionDelta
      }
    }
  }
`;

export const GET_RANKINGS_FILTERS = gql`
  query getRankingsFilterOptions(
    $type: String
    $category: Int
    $year: Int
    $festivalCode: String
  ) {
    getRankingsFilterOptions(
      filters: {
        type: $type
        category: $category
        year: $year
        festivalCode: $festivalCode
      }
    ) {
      label
      key
      values {
        label
        key
        section
      }
    }
  }
`;
