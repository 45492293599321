import { TextData } from "@/renderers";
import { Text } from "@horizon/components";
import { buildKey } from "./libs";

export const TextComponent = (
  { as = "p", size, content, id }: TextData,
  index?: number
) => {
  return (
    <Text key={buildKey("text", index)} as={as} size={size} id={id}>
      {content}
    </Text>
  );
};
