import type {
  Inspiration,
  inspirationResource,
} from "@/services/providers/gemini/types";
import {
  FeaturePanelData,
  Link,
  MediaContent,
  PageTitleBlockData,
  TextComponentData,
  TextData,
} from "@/renderers";
import { typeFromAsset } from "@/services/libs/mapper_utils";
import { PATHS } from "@/services/libs";
import { RichTextMapper } from "../providers/contentful/mappers/richText.mapper";

const toTitleData = (inspiration: Inspiration): PageTitleBlockData => ({
  type: "PageTitleBlock",
  title: inspiration.title,
});

const toBodyData = ({
  introduction,
  introductionRichText,
}: Inspiration): TextComponentData[] | TextData[] =>
  introductionRichText
    ? buildIntroductionRichText(introductionRichText)
    : buildIntroductionText(introduction);

const buildIntroductionRichText = (
  introduction: string
): TextComponentData[] => {
  const introductionRichText = JSON.parse(introduction);
  return RichTextMapper.jsonToTextComponents(introductionRichText).map(
    (node) => ({
      ...node,
      size: node.type === "TextComponent" ? "large" : node.size,
    })
  );
};

const buildIntroductionText = (introduction: string): TextData[] => [
  {
    type: "Text",
    size: "large",
    content: introduction,
  },
];

const toContentItems = (inspiration: Inspiration): FeaturePanelData[] => {
  return inspiration.inspirationResources.map((resource) => {
    return {
      type: "FeaturePanel",
      textPosition: "right",
      title: resource.title,
      copy: resource.introduction,
      media: mediaAsset(resource),
      link: toResourceLink(resource),
    };
  });
};

const toResourceLink = (resource: inspirationResource): Link | null => {
  if (resource.talk) {
    return { href: `${PATHS.talks}/${resource.talk.slug}`, label: "See Talk" };
  }
  if (resource.classicAsset) {
    return {
      href: `${PATHS.classic}/${resource.classicAsset.slug}`,
      label: "View Classic",
    };
  }
  if (resource.campaign) {
    return {
      href: `${PATHS.campaigns}/${resource.campaign.slug}`,
      label: "View Campaign",
    };
  }

  return null;
};

const canUseFullAsset = (mediaType: string): boolean => {
  return ["image/jpeg", "image/png", "video/mp4"].includes(mediaType);
};

const mediaAsset = (resource: any): MediaContent | null => {
  if (resource.asset) {
    const mediaType = typeFromAsset(resource.asset);
    return {
      href:
        (canUseFullAsset(mediaType) && resource.asset?.fullUrl) ||
        resource.asset?.thumbnailUrl,
      alt: resource.title,
      mediaType,
      thumbnail: resource.asset.thumbnailUrl,
    };
  }
  if (resource.campaign) {
    const asset = resource.campaign.assets[0];
    const mediaType = typeFromAsset(asset);
    return {
      href:
        (canUseFullAsset(mediaType) && asset?.fullUrl) ||
        asset?.thumbnailUrl ||
        "/images/missing-asset.png",
      alt: resource.title,
      mediaType,
      thumbnail: asset?.thumbnailUrl || "/images/missing-asset.png",
    };
  }
  if (resource.talk) {
    const talk = resource.talk;
    return {
      href: talk.videoUrl,
      alt: resource.title,
      mediaType: "video/mp4",
      thumbnail: talk.imageUrl,
    };
  }
  if (resource.classicAsset) {
    const classicAsset = resource.classicAsset;
    return {
      href: classicAsset.videoUrl,
      alt: resource.title,
      mediaType: "video/mp4",
      thumbnail: classicAsset.imageUrl,
    };
  }
  return null;
};

export const InspirationMapper = {
  toBodyData,
  toContentItems,
  toTitleData,
};
