import { PAGINATION_FRAGMENT } from "@/services/providers/gemini/queries/shared_fragments/pagination_fragment";
import { gql } from "graphql-request";
import { CAMPAIGN_FRAGMENT } from "./campaign_query";

const BRAND_FRAGMENT = gql`
  fragment BrandFragment on Brand {
    awardCount
    id
    name
    sector {
      id
      name
      slug
    }
    shortlistCount
    slug
    campaigns {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;

export const GET_BRAND_BY_ID = gql`
  query BrandById(
    $brand_id: Int!
    $award_levels: [String]
    $countries: [String]
    $entered_lions: [String]
    $festival_name: [String]
    $media_categories: [String]
    $sectors: [String]
    $search_text: String
    $sort: String
    $years: [Int]
    $page: Int
  ) {
    getBrandWithFilteredCampaigns(
      campaignFilters: {
        awards: $award_levels
        countries: $countries
        enteredLions: $entered_lions
        festivalName: $festival_name
        mediaCategories: $media_categories
        searchText: $search_text
        sectors: $sectors
        sort: $sort
        years: $years
        page: $page
      }
      id: $brand_id
    ) {
      brand {
        ...BrandFragment
      }
      pagination {
        currentPage
        pageSize
        totalRecords
      }
    }
  }
  ${BRAND_FRAGMENT}
`;

export const GET_FILTERED_BRANDS = gql`
  query FilteredBrands(
    $sectors: [String]
    $page: Int
    $countries: [String]
    $sort: String
    $search_text: String
  ) {
    filteredBrands(
      filters: {
        sectors: $sectors
        countries: $countries
        sort: $sort
        page: $page
        searchText: $search_text
      }
    ) {
      brands {
        campaignCount
        id
        name
        slug
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

export const GET_BRAND_FILTERS = gql`
  query (
    $sectors: [String]
    $countries: [String]
    $ids: [Int]
    $page: Int
    $search_text: String
  ) {
    getBrandFilterOptions(
      filters: {
        sectors: $sectors
        countries: $countries
        page: $page
        ids: $ids
        searchText: $search_text
      }
    ) {
      key
      label
      values {
        count
        key
        label
      }
    }
  }
`;
