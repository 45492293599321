import {
  CardData,
  ComponentData,
  ComponentGroupingData,
  LabelCaptionData,
  MarkdownData,
  MediaPlayerData,
  PageTitleBlockData,
} from "@/renderers";
import {
  PATHS,
  buildContainerData,
  buildDarkSectionData,
  buildSectionData,
  defaultComponentsPerRow,
  toDateFormat,
} from "@/services/libs";
import { Report } from "@/services/providers/contentful/types";
import { FilteredContent } from "@/services/providers/gemini/types";

const buildPdfView = (
  report: Report,
  relatedEditorial: FilteredContent,
  accessToken?: string
): ComponentData[] => {
  if (report.report?.url) {
    return [
      buildDarkSectionData([
        buildContainerData([
          ReportShowMapper.toPDFPageData(report, accessToken),
        ]),
      ]),
      buildSectionData([
        buildContainerData([ReportShowMapper.toDatePublishedData(report)]),
        buildContainerData([ReportShowMapper.toBodyData(report)]),
      ]),
      ReportShowMapper.toRelatedEditorialCarouselData(relatedEditorial),
    ].filter(Boolean) as ComponentData[];
  }
  return [];
};

const toCardData = (editorial: any): CardData => {
  let href = `${PATHS.video}/${editorial.slug}`;
  if (editorial.mediaType === "PDF") {
    href = `${PATHS.reports}/${editorial.slug}`;
  }

  return {
    type: "Card",
    variant: "Editorial",
    title: editorial.title,
    link: { href: href, label: editorial.title },
    media: {
      href: editorial.imageUrl,
      alt: editorial.title,
      mediaType: "image/jpeg",
    },
    superText: toDateFormat(editorial?.publishedDate),
    subText: editorial.theme,
  };
};

const toPDFPageData = (
  report: Report,
  accessToken?: string
): MediaPlayerData | undefined => {
  if (report.report?.url) {
    return {
      type: "MediaPlayerData",
      media: {
        mediaType: "application/pdf",
        alt: report.title,
        href: report.report?.url || "",
        thumbnail: report.thumbnail?.url || "",
      },
      title: report.title,
      publishDate: report.publishedDate,
      category: report?.editorialCategory?.title,
      thumbnail: report.thumbnail?.url,
      accessToken: accessToken,
    };
  }
  return undefined;
};

const toBodyData = (report: Report): MarkdownData => ({
  type: "Markdown",
  copySize: "large",
  html: report.description,
});

const toPageTitleBlock = (
  report: Report,
  canDownload: boolean
): PageTitleBlockData => ({
  type: "PageTitleBlock",
  title: report.title,
  subText: `${report.editorialCategory?.title} / ${toDateFormat(
    report.publishedDate
  )}`,
  ...(canDownload && report.report?.url
    ? {
        ctas: [
          {
            type: "Button",
            variant: "primary",
            label: "Download PDF",
            href: report.report?.url,
            icon: {
              name: "Download",
              position: "end",
            },
          },
        ],
      }
    : {}),
});

const toDatePublishedData = (report: Report): LabelCaptionData => {
  return {
    type: "LabelCaption",
    content: toDateFormat(report?.publishedDate),
  };
};

const toRelatedEditorialCarouselData = (
  editorials: FilteredContent
): ComponentGroupingData => {
  const components = editorials?.content?.map(toCardData) || [];
  return {
    type: "ComponentGrouping",
    title: "Up Next",
    variant: "carousel",
    components: components,
    componentsPerRow: defaultComponentsPerRow,
  };
};

export const ReportShowMapper = {
  buildPdfView,
  toBodyData,
  toPDFPageData,
  toDatePublishedData,
  toRelatedEditorialCarouselData,
  toPageTitleBlock,
};
