import { ErrorHandler } from "@/libs/ErrorHandler";

type VideoEventProps = {
  userId?: string;
  videoUrl: string;
  videoTitle: string;
  playerState: "Started" | "Playing" | "Paused" | "Completed";
  percentagePlayed: number;
  currentTime: number;
  accessToken?: string;
};

const STORE_VIDEO_EVENT = `mutation CreateVideoTracking(
    $videoUrl: String!,
    $videoTitle: String!,
    $videoPlayerState: String!,
    $percentagePlayed: Float,
    $playerCurrentTime: Int,
    ) {
      CreateVideoTracking(
        videoUrl:$videoUrl,
        videoTitle: $videoTitle,
        videoPlayerState: $videoPlayerState,
        percentagePlayed: $percentagePlayed,
        playerCurrentTime: $playerCurrentTime
      ) {
        userId
        videoUrl
        videoTitle
        videoPlayerState
        percentagePlayed
        playerCurrentTime
      }
    }`;

const fetchAPI = async (options: RequestInit | undefined) => {
  const url = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT;
  if (url) {
    try {
      const result = await fetch(url, options);
      const data = await result.json();
    } catch (err: any) {
      ErrorHandler.noticeError(err);
    }
  }
};

export const storeVideoEvent = async (eventProps: VideoEventProps) => {
  const {
    videoUrl,
    videoTitle,
    playerState: videoPlayerState,
    percentagePlayed,
    currentTime: playerCurrentTime,
    accessToken,
  } = eventProps;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      query: STORE_VIDEO_EVENT,
      variables: {
        videoUrl,
        videoTitle,
        videoPlayerState,
        percentagePlayed,
        playerCurrentTime: parseInt(playerCurrentTime.toString()),
      },
    }),
  };
  await fetchAPI(options);
};
