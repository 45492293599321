import { ParsedQuery } from "@/libs/QueryParams";
import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_FILTERED_BRANDS } from "@/services/providers/gemini/queries/brand_query";
import { Brand, Pagination } from "@/services/providers/gemini/types";

export const getFilteredBrands = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<{ brands: Array<Brand> | null; pagination: Pagination | null }> => {
  const results = await callGemini(
    { query: GET_FILTERED_BRANDS, variables: queryParams },
    accessToken
  );
  if (results === null) {
    return { brands: null, pagination: null };
  } else {
    const {
      filteredBrands: { brands: brands, pagination: pagination },
    } = results;
    return { brands: brands, pagination: pagination };
  }
};
