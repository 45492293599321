import { gql } from "graphql-request";

export const ENTRY_TYPE_FRAGMENT = gql`
  fragment EntryTypeFragment on FestivalEntryType {
    juryPresident {
      company
      name
      title
      image
    }
    name
    slug
    status {
      currentStatus
      url
    }
  }
`;

export const GET_FESTIVAL_TRACKS_FOR_YEAR = gql`
  query ($festivalYear: Int, $festivalName: String) {
    getFestivalTracks(festivalName: $festivalName, year: $festivalYear) {
      entryTypes {
        ...EntryTypeFragment
      }
      trackName
    }
  }
  ${ENTRY_TYPE_FRAGMENT}
`;
