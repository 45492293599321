import { PdfViewer as HorizonPdfViewer } from "@horizon/components";
import { MediaPlayerData } from "@/renderers";
import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";
import { Analytics } from "@/libs/analytics";

export const PDFViewerComponent = ({
  mediaPlayerData,
}: {
  mediaPlayerData: MediaPlayerData;
}) => {
  const { media, constrainHeight, id } = mediaPlayerData;
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  return (
    <HorizonPdfViewer
      id={id}
      href={media.href}
      constrainHeight={constrainHeight}
      onPageChangeCallback={(pageChange: {
        newPage: number;
        totalPages: number;
        interactionType: "next_page" | "previous_page";
      }) => {
        Analytics.trackPdfViewInteraction(
          {
            interactionType: pageChange.interactionType,
            title: mediaPlayerData.title || "",
            category: mediaPlayerData.category || "",
            publishDate: mediaPlayerData.publishDate || "",
            percentageRead: calcPercentageRead(
              pageChange.newPage,
              pageChange.totalPages,
              pageChange.interactionType
            ),
          },
          clientParams,
          user
        );
      }}
    />
  );
};

const calcPercentageRead = (
  currentPage: number,
  totalPages: number,
  interaction_type: string
) => {
  if (interaction_type === "next_page") {
    return Math.floor((currentPage / totalPages) * 100);
  }
  return undefined;
};
