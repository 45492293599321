import { AwardsBlockData } from "@/renderers";
import { AwardTag, Flex } from "@horizon/components";

import { TextComponent } from "./TextComponent";

export const AwardsBlock = ({ awardTags, label, id }: AwardsBlockData) => {
  return (
    <Flex
      style={{
        alignItems: "center",
        flexWrap: "wrap",
        gap: "0.8rem",
        margin: "1.5rem 0rem",
      }}
      id={id}
    >
      {label && <TextComponent type="Text" size="medium" content={label} />}
      {awardTags?.map((awardTag) => {
        return (
          <AwardTag key={awardTag.variant} variant={awardTag.variant}>
            {awardTag.content}
          </AwardTag>
        );
      })}
    </Flex>
  );
};
