import { MarkdownData } from "@/renderers";
import { MarkdownRenderer } from "@horizon/components";
import { buildKey } from "../libs";
import { MarkdownRendererStyled } from "@/components/MarkdownComponent/MarkdownComponent.style";

export const MarkdownComponent = (
  { copySize, html }: MarkdownData,
  index?: number
) => {
  return (
    <MarkdownRenderer
      key={buildKey("markdown", index)}
      html={html}
      copySize={copySize}
    />
  );
};

export const NoMarginMarkdownComponent = (
  { copySize, html }: MarkdownData,
  index?: number
) => {
  return (
    <MarkdownRendererStyled
      key={buildKey("markdown", index)}
      html={html}
      copySize={copySize}
    />
  );
};
