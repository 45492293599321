import { GeminiProvider } from "@/services/providers";
import { TalkMapper } from "./talk_show.mapper";
import { Service, ServiceArgs } from "@/services/";
import {
  SITE_TITLE,
  buildDarkSectionData,
  buildSectionData,
  buildContainerData,
} from "@/services/libs";
import { PageData, ComponentData } from "@/renderers";
import type { Talk } from "@/services/providers/gemini/types";

import {
  PATHS,
  getIdFromSlug,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { Authentication } from "@/libs";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken } = serviceArgs;
  if (path.startsWith(PATHS.talks)) {
    const id = getIdFromSlug(path);
    const talk = await GeminiProvider.getTalkById(id, accessToken);
    if (talk) {
      const results = {
        title: `${talk.title} | Talks | ${SITE_TITLE}`,
        metaDescription: talk.description,
        slug: `${PATHS.talks}/${talk.id}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.talks}/${talk.slug}`,
        metaTitle: talk.title,
        metaImage: { url: talk.thumbnail },
        components: [
          ...buildComponents(talk, accessToken),
          maybeCreatePaywall(serviceArgs, talk.requiredUserActions),
        ].filter(Boolean) as ComponentData[],
      };
      return stripUndefinedValuesFromObject(results);
    }
  }

  return null;
};

const buildComponents = (talk: Talk, accessToken?: string): ComponentData[] => {
  return [
    talk.video &&
      buildDarkSectionData(
        [buildContainerData([TalkMapper.toVideoPageData(talk, accessToken)])],
        talk?.title
      ),
    buildSectionData([
      buildContainerData([
        TalkMapper.toLabelCaptionData(talk),
        TalkMapper.toBodyData(talk),
      ]),
    ]),
    TalkMapper.toSpeakerCarouselData(talk),
    TalkMapper.toRelatedTalksCarouselData(talk),
  ].filter(Boolean) as ComponentData[];
};

export const TalkShowService: Service = { retrievePageData };
