import { logger } from "@/libs/Logger";

const noticeError = (err: any) => {
  if (process.env.NODE_ENV === "development") {
    console.error(err);
    return;
  }
  if (typeof window === "undefined") {
    logger.error(err);
  }
};

export const ErrorHandler = { noticeError };
