import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { callClientApi, callGetApi } from "@/libs/client_api_caller";

export const searchContent = async ({
  parsedSearchQuery,
}: {
  parsedSearchQuery?: ParsedQuery;
}) => {
  const queryString = QueryParams.queryToString(parsedSearchQuery);

  const response = await callGetApi(`/api/search?${queryString}`);
  if (response.ok) {
    return response.json();
  }
};
