import { gql } from "graphql-request";
import { PAGINATION_FRAGMENT } from "./shared_fragments/pagination_fragment";
export const GET_INSPIRATION_BY_ID = gql`
  query ($id: String) {
    inspirationForId(id: $id) {
      id
      access
      guestEdit
      imageUrl
      introduction
      introductionRichText
      title
      requiredUserAction
      slug
      inspirationResources {
        access
        introduction
        title
        asset {
          fullUrl
          thumbnailUrl
          type
        }
        campaign {
          assets {
            fullUrl
            thumbnailUrl
            type
          }
          slug
        }
        talk {
          videoUrl
          imageUrl
          slug
        }
        classicAsset {
          imageUrl
          videoUrl
          slug
        }
      }
    }
  }
`;

export const GET_FILTERED_INSPIRATIONS = gql`
  query getFilteredInspirations(
    $ids: [String]
    $sort: String
    $page: Int
    $search_text: String
  ) {
    filteredInspirations(
      filters: { ids: $ids, page: $page, sort: $sort, searchText: $search_text }
    ) {
      inspirations {
        access
        guestEdit
        id
        imageUrl
        introduction
        liveAt
        title
        slug
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;
