import React, { use } from "react";
import { useState, useEffect } from "react";
import { MarketoFormData } from "../components.d";
import { Section, Container } from "@horizon/components";
import {
  MarketoTitle,
  MarketoFormStyled,
  resetFormCss,
} from "./MarketoFormComponent.style";
import { Api } from "@/libs/api";
import { Analytics } from "@/libs/analytics";
import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";

export const MarketoFormComponent = (
  { title, formId, redirectSlug, baseUrl, munchkinId, id }: MarketoFormData,
  index?: number
) => {
  useMarketo({
    baseUrl,
    munchkinId,
    formId: formId,
    callback: () => {},
    redirectSlug: redirectSlug,
    title: title,
  });

  return buildForm({ title, formId, index, id });
};

const useMarketo = ({
  baseUrl,
  munchkinId,
  formId,
  callback,
  redirectSlug,
  title,
}: any) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  useEffect(() => {
    if (scriptLoaded) {
      // @ts-ignore
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback);
      // @ts-ignore
      window.MktoForms2.whenReady((form) => {
        form.onSubmit((submittedForm: any) => {
          Api.trackForm(submittedForm.getValues(), () => {});
          Analytics.trackMarketoFormSubmit(clientParams, formId, title, user);

          return submittedForm;
        });
        form.onSuccess((values: any, followUpUrl: any) => {
          form.getFormElem().hide();

          window.location = redirectSlug;
          return false; //Prevents form reloading after success.
        });
      });

      // @ts-ignore
      window.MktoForms2.whenRendered((form: { getFormElem: () => any[] }) => {
        setTimeout(() => resetFormCss(form), 0);
      });
      return;
    }
    appendScript(baseUrl, setScriptLoaded);
  }, [
    scriptLoaded,
    baseUrl,
    munchkinId,
    formId,
    callback,
    redirectSlug,
    clientParams,
    title,
    user,
  ]);
};

const appendScript = (
  baseUrl: any,
  setScriptLoaded: {
    (value: React.SetStateAction<boolean>): void;
    (arg0: boolean): any;
  }
) => {
  // @ts-ignore
  if (window.MktoForms2) return setScriptLoaded(true);

  const script = document.createElement("script");
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  // @ts-ignore
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
};

const buildForm = ({
  title,
  formId,
  index,
  id,
}: {
  title: string;
  formId: string;
  index?: number;
  id: string | number | undefined;
}) => {
  return (
    <Section
      id={id}
      key={index || "Marketo Section"}
      contentSpace={"small"}
      w={"100px"}
    >
      <Container>
        {title && (
          <MarketoTitle size="large" id="marketoFormTitle">
            {title}
          </MarketoTitle>
        )}
        <MarketoFormStyled>
          {/* the div with id "marketoForm" below is used to override original marketo form css */}
          <div id="marketoForm">
            <form className="gs-marketo-form" id={`mktoForm_${formId}`} />
          </div>
        </MarketoFormStyled>
      </Container>
    </Section>
  );
};
