import auth0 from "@/libs/authentication/auth0";
import { IncomingMessage, ServerResponse } from "http";
import { NextApiRequest, NextApiResponse } from "next";
import { createContext, useContext } from "react";

export const getUser = async (
  req: IncomingMessage | NextApiRequest,
  res: ServerResponse<IncomingMessage> | NextApiResponse
): Promise<AuthenticatedUser | undefined> => {
  const session = await auth0().getSession(req, res);
  if (session) {
    return parseAuth0User(session.user);
  } else {
    return undefined;
  }
};

export const userHasAction = (
  action?: (UserAction | null | undefined)[] | UserAction | null,
  userActions?: UserAction[]
): boolean => {
  if (Array.isArray(action) && action.length > 0) {
    return action.some((a) => a && userActions?.includes(a));
  } else if (typeof action === "string") {
    return userActions?.includes(action) || false;
  }
  return true; // Action must be null, so not required
};

export const USER_ACTIONS = {
  AGENCIES_VIEW: "thework:global:search",
  ASSETS_DOWNLOAD: "thework:assets:download",
  ASSETS_VIEW: "thework:assets:show",
  BRANDS_VIEW: "thework:global:search",
  CAMPAIGNS_VIEW: "thework:campaigns:show",
  CAN_SEARCH: "thework:global:search",
  CLASSIC_ASSETS_VIEW: "thework:classic:show",
  COLLECTIONS_CREATE: "thework:playlists:create",
  COLLECTIONS_VIEW: "thework:shared_playlists:show_expired",
  DIGITAL_PASS_VIEW: "gemini:digitalpass:view",
  EDITORIAL_VIEW: "thework:editorial:show",
  ENTRIES_SAVE: "thework:playlists:create",
  ENTRIES_VIEW: "thework:entries:show",
  INDIVIDUALS_VIEW: "thework:portfolios:view",
  INSPIRATIONS_VIEW: "thework:inspiration:show",
  TALKS_VIEW: "thework:talks:show",
};

export type UserAction = (typeof USER_ACTIONS)[keyof typeof USER_ACTIONS];

const parseAuth0User = (user: any): AuthenticatedUser => ({
  metaData: {
    SitecoreUsername:
      user["http://lionscreativity.com/user/app_metadata"]?.SitecoreUsername,
    lions_id: user["http://lionscreativity.com/user/app_metadata"]?.lions_id,
    products: user["http://lionscreativity.com/user/app_metadata"]?.products,
    uma_id: user["http://lionscreativity.com/user/app_metadata"]?.uma_id,
    user_credit_id:
      user["http://lionscreativity.com/user/app_metadata"]?.user_credit_id,
  },
  actions: user["https://users.lovethework.com/actions"] || [],
  products: user["https://users.lovethework.com/products"] || [],
  extended_profile: {
    companyName:
      user["https://users.lovethework.com/user/profile"]?.companyName,
    companyPlaceId:
      user["https://users.lovethework.com/user/profile"]?.companyPlaceId,
    country: user["https://users.lovethework.com/user/profile"]?.country,
    countryCode:
      user["https://users.lovethework.com/user/profile"]?.countryCode,
    permitMarketing:
      user["https://users.lovethework.com/user/profile"]?.permitMarketing,
    playlistIds:
      user["https://users.lovethework.com/user/profile"]?.playlistIds,
    role: user["https://users.lovethework.com/user/profile"]?.role,
    seniority: user["https://users.lovethework.com/user/profile"]?.seniority,
    telephoneNumber:
      user["https://users.lovethework.com/user/profile"]?.telephoneNumber,
  },
  given_name: user.given_name,
  family_name: user.family_name,
  nickname: user.nickname,
  name: user.name,
  picture: user.picture,
  updated_at: user.updated_at,
  email: user.email,
});

export type AuthenticatedUser = {
  metaData: {
    SitecoreUsername: string;
    lions_id: string;
    products: string[];
    uma_id: string;
    user_credit_id: string;
  };
  actions: string[];
  products: { expiration: string; productType: string }[];
  extended_profile: {
    companyName: string;
    companyPlaceId: string;
    country: string;
    countryCode: string;
    permitMarketing: boolean;
    playlistIds: string[];
    role: string;
    seniority: string;
    telephoneNumber: string;
  };
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
};

export const AuthenticatedUserContext = createContext<
  AuthenticatedUser | undefined
>(undefined);

export const useAuthenticatedUser = (): AuthenticatedUser | undefined => {
  return useContext(AuthenticatedUserContext);
};

export const signinHrefPath = (path: string): string => {
  const encodedPath = Buffer.from(path, "utf-8").toString("base64");
  return `/api/auth/login?callback=${encodedPath}`;
};
