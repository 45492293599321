import { PAGE_PARAM, updateHistory, updateURL } from "./query_params";

interface QueryBuilder {
  value: URLSearchParams;
  set: SetQueryBuilder;
  delete: DeleteQueryBuilder;
  update: UpdateQueryBuilder;
  push: UpdateQueryBuilder;
}

type SetQueryBuilder = (key: string, value: string) => QueryBuilder;
type DeleteQueryBuilder = (key: string) => QueryBuilder;
type UpdateQueryBuilder = () => void;

const toQueryBuilder = (query: URLSearchParams): QueryBuilder => ({
  value: query,
  set: buildSet(query),
  delete: buildDelete(query),
  update: buildUpdate(query),
  push: buildPush(query),
});

export const build = (): QueryBuilder => {
  const query = new URLSearchParams(window.location.search);
  return {
    value: query,
    set: buildSet(query),
    delete: buildDelete(query),
    update: buildUpdate(query),
    push: buildPush(query),
  };
};

const buildSet = (query: URLSearchParams) => {
  const newQuery = new URLSearchParams(query);

  return (key: string, value: string): QueryBuilder => {
    newQuery.delete(PAGE_PARAM);
    newQuery.set(key, Array.isArray(value) ? value.toString() : value);
    return toQueryBuilder(newQuery);
  };
};

const buildDelete = (query: URLSearchParams) => {
  const newQuery = new URLSearchParams(query);

  return (key: string): QueryBuilder => {
    newQuery.delete(key);
    return toQueryBuilder(newQuery);
  };
};

const buildUpdate = (query: URLSearchParams) => {
  const newQuery = new URLSearchParams(query);
  return (): void => {
    updateURL(newQuery);
  };
};

const buildPush = (query: URLSearchParams) => {
  const newQuery = new URLSearchParams(query);
  return (): void => {
    updateHistory(newQuery);
  };
};
