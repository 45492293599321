import { RankingsParams } from "@/libs/RankingsParams";
import { combineQueryParams } from "@/libs/QueryParams/query_params";
import { ServiceArgs } from "@/services";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import {
  ComponentGrouping,
  SectionComponent,
  SectionItem,
  SponsorBanner,
} from "@/services/providers/contentful/types";
import { GeminiProvider } from "@/services/providers/gemini";
import {
  FestivalTrack,
  FilterOption,
  FilteredContent,
} from "@/services/providers/gemini/types";
import {
  BannerData,
  CollapsibleNavigationLayoutData,
  ComponentData,
  SectionBlockData,
  SpacerData,
  TabContainerData,
  TableData,
  TableRow,
  TagData,
} from "@/renderers";
import { ComponentMapper } from "@/services/providers/contentful/mappers/component.mapper";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { PortalMapper } from "@/services/providers/portal/mappers";
import { PATHS, buildContainerData, buildSpacerData } from "@/services/libs";
import { toAwardedAwardsBlock } from "@/services/libs/award_utils";
import { createId } from "@/components/libs";
import { Authentication } from "@/libs";

const toComponentData = async (
  content: SectionComponent[],
  serviceArgs: ServiceArgs
): Promise<SectionBlockData[]> => {
  const sections = await buildSectionData(content, serviceArgs);
  return sections.map((section) => toSectionData(section, serviceArgs));
};

export const buildSectionData = async (
  content: SectionComponent[],
  { queryParams }: ServiceArgs
): Promise<SectionComponent[]> => {
  return await Promise.all(
    content.map((component) => populateSectionData(component, queryParams))
  );
};

const populateSectionData = async (
  component: SectionComponent,
  queryParams: string | undefined
): Promise<SectionComponent> => {
  component.content.items = await updateSearchableComponentGroupings(
    component.content.items,
    queryParams
  );
  return component;
};

const updateSearchableComponentGroupings = async (
  content: SectionItem[],
  queryParams: string | undefined
): Promise<any> => {
  return Promise.all(
    content.map(async (component) => {
      component = await maybeGetFilterOptions(component, queryParams);
      component = await maybeGetFilteredGeminiContent(component, queryParams);
      component = await maybeGetFestivalTracks(component);
      component = await maybeGetRankingsData(component, queryParams);

      return component;
    })
  );
};

const maybeGetFilterOptions = async (
  component: SectionItem,
  queryParams: string | undefined
): Promise<SectionItem> => {
  if (
    component.type === "ComponentGrouping" &&
    component.searchQuery &&
    component.showFilters
  ) {
    const combinedParams = combineQueryParams(
      component.searchQuery,
      queryParams
    );
    const searchQuery = combinedParams;
    let results;
    switch (searchQuery?.content_type) {
      case "entries":
        results = await GeminiProvider.getEntryFilters(searchQuery);
        break;
      case "talks":
        results = await GeminiProvider.getTalkFilters(searchQuery);
        break;
      case "campaigns":
        results = await GeminiProvider.getCampaignFilters(searchQuery);
        break;
    }
    component["filterOptions"] = results;
  }
  return component;
};

const maybeGetFilteredGeminiContent = async (
  component: SectionItem,
  queryParams: string | undefined
): Promise<SectionItem> => {
  if (component.type === "ComponentGrouping" && component.searchQuery) {
    const combinedParams = combineQueryParams(
      component.searchQuery,
      queryParams
    );
    const { content, filterCounts, pagination }: FilteredContent =
      await GeminiProvider.getFilteredContent(combinedParams);

    component.componentsCollection = { items: content || [] };
    component.page = pagination?.currentPage;
    component.pageSize = pagination?.pageSize;
    component.totalRecords = pagination?.totalRecords;
    component.pagination = GeminiMapper.toPaginationData(pagination);
    component.combinedQuery = combinedParams;
    component.noResultsText =
      "UNFORTUNATELY, NO RESULTS WERE FOUND FOR YOUR SEARCH.";
    component.filterCounts =
      (filterCounts as FilterOption[]) ?? ([] as FilterOption[]);
  }
  return component;
};

const maybeGetFestivalTracks = async (
  component: SectionItem
): Promise<SectionItem> => {
  if (component.type === "EntryTypeListingComponent") {
    const results = await GeminiProvider.getFestivalTracks({
      festivalName: component.festivalName,
      festivalYear: component.festivalYear,
    });
    component["festivalTracks"] = results || undefined;
  }
  return component;
};

const maybeGetRankingsData = async (
  component: SectionItem,
  queryParams: string | undefined
): Promise<SectionItem> => {
  let combinedParams = {};
  if (component.type === "RankingsTable") {
    const rankingsParams = RankingsParams.parseRankingsQuery(component);
    combinedParams = RankingsParams.combineQueryParams(
      rankingsParams,
      queryParams
    );
    const rankingsData = await GeminiProvider.getRankingsData(combinedParams);
    component["rankingsData"] = rankingsData;
  }
  if (component.type === "RankingsTable" && component.showFilters) {
    const filters = await GeminiProvider.getRankingsFilters(combinedParams);
    component["filterOptions"] = filters;
    component["searchQuery"] = combinedParams;
  }
  return component;
};

const toSectionData = (
  section: SectionComponent,
  serviceArgs: ServiceArgs
): SectionBlockData => ({
  type: "SectionBlock",
  title: section.title,
  subtitle: section.subtitle,
  backgroundMode: section.backgroundMode,
  darkModeBackgroundColour: section.darkModeBackgroundColour,
  content: [
    buildSpacerData(
      section.content?.items
        ?.filter((item, index, sectionItems) =>
          filterLatestLivePlayer(item, index, sectionItems, serviceArgs)
        )
        .map(buildComponentData)
        .flat()
    ),
  ],
  id: createId(section.title),
});

const filterLatestLivePlayer = (
  sectionItem: SectionItem,
  _index: number,
  sectionItems: SectionItem[],
  serviceArgs: ServiceArgs
): boolean => {
  if ("type" in sectionItem && sectionItem.type === "LivePlayer") {
    if (
      !Authentication.userHasAction(
        sectionItem.requiredUserAction,
        serviceArgs?.userActions
      )
    ) {
      return false;
    }

    const currentDateTime = new Date();
    const startTime = new Date(sectionItem.liveEventDate || "");
    return (
      startTime < currentDateTime &&
      sectionItems.filter((item) => {
        if (item.type === "LivePlayer") {
          const itemStartTime = new Date(item.liveEventDate || "");
          return itemStartTime > startTime && itemStartTime < currentDateTime;
        }
        return false;
      }).length === 0
    );
  }
  return true;
};

const buildComponentData = (
  component: SectionItem
):
  | ComponentData
  | ComponentData[]
  | TableData[]
  | (ComponentData | TableData)[] => {
  switch (component.type) {
    case "RankingsTable":
      return buildContainerData(toRankingsBlock(component) as ComponentData[]);
    case "EntryTypeListingComponent":
      return buildContainerData([toEntryTypeListing(component)]);
    case "EventSchedule":
      return PortalMapper.toScheduleData(component);
    case "ComponentGrouping":
      return toComponentGrouping(component);
    default:
      return ComponentMapper.buildComponent(component);
  }
};

const toRankingsBlock = (component: any) => {
  const { filterOptions, searchQuery, variant } = component;

  const tableData = GeminiMapper.rankingsDataToTableData(component);
  if (component.showFilters) {
    return [
      {
        type: "RankingsBlock",
        filterOptions: GeminiMapper.parseRankingFilterOptions(
          filterOptions,
          searchQuery
        ),
        variant,
        searchQuery,
      },
      tableData,
    ];
  } else {
    return [tableData];
  }
};

const toEntryTypeListing = (component: any) => {
  return festivalTracksToTableDataArray(
    component.festivalTracks,
    component.festivalName,
    component.sponsorBanner?.items
  );
};

type Status =
  | "Bronze Winners Available"
  | "Bronze & Silver Winners Available"
  | "Bronze, Silver & Gold Winners Available"
  | "All Winners"
  | "Winners Available"
  | "Shortlist Available"
  | "Entries Available";

const statusTagVariant = new Map<Status, string>([
  ["Bronze Winners Available", "awarded"],
  ["Bronze & Silver Winners Available", "awarded"],
  ["Bronze, Silver & Gold Winners Available", "awarded"],
  ["All Winners", "winners"],
  ["Winners Available", "winners"],
  ["Shortlist Available", "shortlist"],
  ["Entries Available", "entries"],
]);

const festivalNameToAwardsPath: { [Key: string]: string } = {
  "Cannes Lions": "cannes-lions",
  Eurobest: "eurobest",
  "Dubai Lynx": "dubai-lynx",
  "Spikes Asia": "spikes-asia",
};

const toComponentGrouping = (component: ComponentGrouping): ComponentData[] => {
  const filters = component.showFilters ? toFilters(component) : [];
  return [...filters, GeminiMapper.toComponentGroupingData(component)];
};

const toFilters = (component: ComponentGrouping): ComponentData[] => {
  let filters = [];
  filters.push(toComponentGroupingFilters(component));
  if (component.filterCounts && component.totalRecords) {
    filters.push(toCountTitle(component.totalRecords));
  }
  const awardsCount = component.filterCounts?.find(
    (filter: FilterOption) => filter.key === "award_levels"
  );
  if (awardsCount) {
    filters.push(toAwardedAwardsBlock(awardsCount));
  }
  return filters;
};

const toComponentGroupingFilters = ({
  filterOptions,
  combinedQuery,
}: ComponentGrouping): ComponentData => {
  return ComponentMapper.buildComponent({
    type: "EntriesFilter",
    filterOptions: GeminiMapper.Filters.parseFilterOptions(
      filterOptions ?? [],
      combinedQuery
    ),
    filtersToShow: 2,
  });
};

const toCountTitle = (totalRecords: number): ComponentData => {
  const heroCountTitle = {
    type: "HeroTitle",
    size: "medium",
    content: `${totalRecords} Entries`,
  } as ComponentData;
  return buildContainerData([heroCountTitle]);
};

const festivalTracksToTableDataArray = (
  festivalTracks: FestivalTrack[],
  festivalName: string,
  sponsorBanner?: SponsorBanner[]
): SpacerData => {
  const tableDataArray: TableData[] =
    festivalTracks?.map(({ trackName, entryTypes }) => {
      const trackBanner = sponsorBanner?.find(
        (sponsor) => sponsor.itemName === trackName
      );
      const trackBannerData = trackBanner
        ? toBannerData(trackBanner)
        : undefined;

      return {
        banner: trackBannerData,
        type: "Table",
        title: trackName as string,
        id: createId(trackName),
        headers: [
          { label: { copy: "Award" } },
          {
            label: { copy: trackNameToJuryLabel(trackName) },
            cellWidth: "24rem",
          },
          { label: { copy: "Status" }, cellWidth: "auto" },
        ],
        rows: entryTypes?.map((entryType): TableRow => {
          const juryPresident = entryType.juryPresident;
          const status = entryType.status?.currentStatus;
          const url = entryType.status?.url;
          return {
            cells: [
              {
                label: {
                  copy: entryType.name.toUpperCase(),
                  href:
                    status && url ? hrefForEntryType(url, festivalName) : "",
                  size: "large",
                },
                type: status && url ? "link" : "label",
              },
              juryPresident?.name && juryPresident?.image
                ? {
                    type: "avatar",
                    avatar: {
                      name: juryPresident?.name || "",
                      size: "small",
                      src: juryPresident?.image || "",
                    },
                  }
                : {},
              status
                ? {
                    tag: getStatusTag(status),
                    type: "tag",
                  }
                : {},
            ],
          };
        }),
      };
    }) || [];

  return ComponentMapper.buildComponent({
    type: "Spacer",
    content: tableDataArray,
    size: "large",
  }) as SpacerData;
};

// NOTE: As this is the only use case we are implementing this custom logic.
// However if we want to have custom labels, we should drive the labels to the backend.
const trackNameToJuryLabel = (trackName: string): string => {
  return trackName.toUpperCase() === "YOUNG COMPETITIONS"
    ? "Jury Chair"
    : "Jury President";
};

const hrefForEntryType = (url: string, festivalName: string): string => {
  if (url.startsWith("http")) {
    return url;
  } else {
    return `${PATHS.results}/${festivalNameToAwardsPath[festivalName]}/${url}`;
  }
};

const getStatusTag = (status: string): TagData => {
  if (status === null) {
    return status;
  }
  const variant = statusTagVariant.get(status as Status) || "coming";
  return {
    type: "Tag",
    component: "Status",
    content: status,
    variant,
  };
};

const toBannerData = (component: any) =>
  ({
    type: "Banner",
    image: component.media
      ? {
          src: component.media?.url,
        }
      : undefined,
    label: component.text,
    position: "flex-start",
  } as BannerData);

const toSectionComponents = async (
  content: SectionComponent[] | undefined,
  navigationType: string,
  serviceArgs: ServiceArgs
): Promise<TabContainerData | ComponentData[] | ComponentData | undefined> => {
  if (!content) return undefined;
  switch (navigationType) {
    case "segmented":
      const spacerContent = await toSegmentedControls(content, serviceArgs);
      return buildSpacerData([spacerContent]);
    case "collapsible":
      return await toCollapsibleNavigationLayout(content, serviceArgs);
    case "none":
      return await toComponentData(content, serviceArgs);
    default:
      return await toComponentData(content, serviceArgs);
  }
};

const toSegmentedControls = async (
  content: SectionComponent[],
  serviceArgs: ServiceArgs
): Promise<TabContainerData> => {
  const parsedQuery = QueryParams.parseSearchQuery(serviceArgs.queryParams);
  const currentQuery = getTabIndexFromQuery(parsedQuery, defualtQuery(content));
  return {
    type: "TabContainer",
    currentTabQuery: currentQuery,
    reloadOnTabChange: false,
    tabs: await Promise.all(
      content.map(async (section) => ({
        type: "Tab",
        title: section.title || "",
        label: defaultLabel(section),
        components: [
          buildContainerData(await toComponentData([section], serviceArgs)),
        ],
      })) ?? []
    ),
  };
};

const getTabIndexFromQuery = (
  parsedQuery: ParsedQuery,
  defaultQuery: string
) => {
  return parsedQuery?.segment
    ? buildTabIndex(parsedQuery.segment)
    : buildTabIndex(defaultQuery);
};

const buildTabIndex = (value: string | string[]) => ({
  key: "segment",
  value: valueToString(value).toLowerCase(),
});
const valueToString = (value: string | string[]) =>
  Array.isArray(value) ? value.join(" ") : value;

const defualtQuery = (content: SectionComponent[]) =>
  content[0] ? defaultLabel(content[0]) : "";
const defaultLabel = (content: SectionComponent) =>
  (content.subtitle || content.title || "").toLowerCase();

const toCollapsibleNavigationLayout = async (
  content: SectionComponent[],
  serviceArgs: ServiceArgs
): Promise<CollapsibleNavigationLayoutData> => ({
  type: "CollapsibleNavigationLayout",
  sections: await SectionDataMapper.toComponentData(content, serviceArgs),
});

const SectionDataMapper = {
  toComponentData,
  toSectionComponents,
  festivalTracksToTableDataArray,
};
export default SectionDataMapper;
