import { Asset } from "../providers/gemini/types";

export const typeFromAsset = (
  asset: Asset | any
):
  | "image/jpeg"
  | "image/png"
  | "application/pdf"
  | "video/mp4"
  | "audio/mp3"
  | "image/zoom" => {
  const { type } = asset || {};

  switch (type) {
    case "Zoom":
      return "image/zoom";
    case "Video:MP4":
      return "video/mp4";
    case "PDF":
      return "application/pdf";
    case "other":
      return "application/pdf";
    case "Audio:MP3":
      return "audio/mp3";
  }
  return "image/jpeg";
};

export const listToCommaSeparatedString = (
  list: (string | undefined | null)[]
): string => {
  return list.filter(Boolean).join(", ");
};

export const getRandomNumber = (min: number = 1, max: number = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const groupArrayByKey = (array: any[], key: string) => {
  if (!array || array.length === 0) {
    return {};
  }

  return array.reduce((hash, obj) => {
    hash[obj[key]] = hash[obj[key]] || [];
    hash[obj[key]].push(obj);
    return hash;
  }, {});
};

export const stripHtmlTags = (str: string) => str.replace(/(<([^>]+)>)/gi, "");
