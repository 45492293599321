import {
  assureDefaultSort,
  currentSearchQuery,
  parseSearchQuery,
  queryToString,
  replaceAllQueryParams,
  replaceQueryParam,
} from "./query_params";

import { build } from "./query_builder";

export type { ParsedQuery } from "./query_params";

export const QueryParams = {
  assureDefaultSort,
  build,
  currentSearchQuery,
  parseSearchQuery,
  queryToString,
  replaceAllQueryParams,
  replaceQueryParam,
};
