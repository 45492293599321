import type { PageData } from "../components/components.d";

export interface Renderer {
  render(page: PageData): JSX.Element | null;
}

export * from "@/renderers/page_renderer";
export * from "@/renderers/component_renderer";
export * from "@/components/components.d";
export * from "@/components/Media/media.d";
export * from "@/components/Collections/collections_types";
export * from "@/components/PageTitleBlockComponent/PageTitleBlockComponent.d";
export * from "@/components/PaywallComponent/PaywallComponent.d";
