import { useEffect, useState } from "react";
import { useWindowWidth } from "@/libs/hooks";
import { BackToTopButton, Wrapper } from "./BackToTopComponent.style";

export const BackToTopComponent = (): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    let oldScrollPos = document.documentElement.scrollTop;
    const toggleVisible = (): void => {
      const scrollPos = document.documentElement.scrollTop;
      // Show if the user starts scrolling up but is not at the top of the page
      const shouldBeVisible = scrollPos > 0 && oldScrollPos > scrollPos;
      setIsVisible(shouldBeVisible);
      oldScrollPos = scrollPos;
    };
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const mobileBreakpoint = 576;
  const buttonLabel = windowWidth > mobileBreakpoint ? "Back to top" : null;

  return (
    <Wrapper>
      {isVisible && (
        <BackToTopButton
          accessibilityLabel="Back to top button"
          icon={{
            position: "start",
            name: "ArrowUp",
          }}
          onClick={scrollToTop}
        >
          {buttonLabel}
        </BackToTopButton>
      )}
    </Wrapper>
  );
};
