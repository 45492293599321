import { CaptionData } from "@/renderers";
import { Caption } from "@horizon/components";
import { buildKey } from "./libs";

export const CaptionComponent = (
  { size, content, id }: CaptionData,
  index?: number
) => {
  return (
    <Caption key={buildKey("caption", index)} size={size} id={id}>
      {content}
    </Caption>
  );
};

export const MediaCaptionComponent = (
  { size, content, id }: CaptionData,
  index?: number
) => {
  const captionStyle = {
    display: "block",
    textAlign: "left",
  };
  return (
    <Caption
      style={captionStyle}
      key={buildKey("caption", index)}
      size={size}
      id={id}
    >
      {content}
    </Caption>
  );
};
