import { Sort } from "@/services/libs";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { GeminiProvider } from "@/services/providers";

import { ServiceArgs } from "@/services";
import { IndividualListingMapper } from "@/services/directory/individual_listing/individual_listing_mapper";
import {
  SITE_TITLE,
  buildContainerData,
  buildSectionData,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";

import {
  Individual,
  FilterOption,
  Pagination,
} from "@/services/providers/gemini/types";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Authentication } from "@/libs";

const retrievePageData = async (serviceArgs: ServiceArgs) => {
  const { path, accessToken, queryParams } = serviceArgs;
  let parsedQuery = QueryParams.parseSearchQuery(
    appendContentType(queryParams || "")
  );
  delete parsedQuery?.id;
  const filterOptions = await GeminiProvider.getIndividualFilters(
    parsedQuery,
    accessToken
  );
  parsedQuery = QueryParams.assureDefaultSort(
    parsedQuery,
    "title_ascending" as Sort.SortValue
  );
  const { individuals, pagination } =
    await GeminiProvider.getFilteredIndividuals(parsedQuery, accessToken);

  if (individuals) {
    const result = {
      title: `${SITE_TITLE} | Individuals`,
      metaDescription: `${SITE_TITLE} | Individuals`,
      slug: `${path}`,
      cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${path}`,
      metaTitle: `${SITE_TITLE} | Individuals`,
      components: [
        ...buildComponents(individuals, filterOptions, parsedQuery, pagination),
        maybeCreatePaywall(
          serviceArgs,
          Authentication.USER_ACTIONS.INDIVIDUALS_VIEW
        ),
      ].filter(Boolean),
    };
    return stripUndefinedValuesFromObject(result);
  }
  return null;
};

const buildComponents = (
  individuals: Individual[],
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery,
  pagination: Pagination | null
) => {
  return [
    buildSectionData(
      [
        IndividualListingMapper.toSearchBarData(
          (parsedQuery?.search_text as string) || ""
        ),
        GeminiMapper.toFilterBlockData(
          filterOptions,
          parsedQuery,
          buildSortOptions(parsedQuery)
        ),
        buildContainerData([
          IndividualListingMapper.maybeAddNoResults(pagination),
        ]),
        buildContainerData([
          IndividualListingMapper.toIndividualTable(individuals, pagination),
        ]),
      ],
      IndividualListingMapper.toTitleText(pagination)
    ),
  ];
};

const SORT_OPTIONS = [
  { value: "relevance", label: "Best match" },
  { value: "most_awarded", label: "Most awarded" },
  { value: "title_ascending", label: "Title - A-Z" },
  { value: "title_descending", label: "Title - Z-A" },
];

const buildSortOptions = (
  parsedQuery: ParsedQuery
): {
  options: { label: string; value: string }[];
  initialValue: string;
} => ({
  options: SORT_OPTIONS,
  initialValue: parsedQuery?.sort ? (parsedQuery.sort as string) : "relevance",
});

const appendContentType = (queryString: string) => {
  if (queryString.length > 1) {
    return queryString.concat(`&content_type=individuals`);
  }
  return `content_type=individuals`;
};

export const IndividualListingService = { retrievePageData };
