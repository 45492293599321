import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_RANKINGS_FILTERS } from "@/services/providers/gemini/queries/rankings_query";
import { FilterOption } from "@/services/providers/gemini/types/filters";

export const getRankingsFilters = async (
  queryParams: any,
  accessToken?: string
): Promise<Array<FilterOption>> => {
  const { getRankingsFilterOptions: filterOptions } = (await callGemini(
    { query: GET_RANKINGS_FILTERS, variables: queryParams },
    accessToken
  )) || { getRankingsFilterOptions: [] };
  return filterOptions;
};
