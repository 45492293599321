import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_CAMPAIGN_BY_ID } from "@/services/providers/gemini/queries/campaign_query";
import { Campaign } from "@/services/providers/gemini/types";

export const getCampaignById = async (
  id: string,
  accessToken?: string
): Promise<Campaign | null> => {
  const results = await callGemini(
    { query: GET_CAMPAIGN_BY_ID, variables: { id: parseInt(id) } },
    accessToken
  );
  if (results === null) {
    return null;
  } else {
    const {
      getCampaignsByIds: [campaign],
    } = results;
    return campaign;
  }
};
