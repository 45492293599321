const DISALLOWED_PII_KEYS = [
  "socialId=",
  "firstName=",
  "lastName=",
  "email=",
  "provider=",
];

const noDisallowedKeys = (queryField: string) => {
  return !DISALLOWED_PII_KEYS.find((k) => queryField.indexOf(k) === 0);
};

export default function purgePii(url: any) {
  if (typeof url !== "string") return url;

  const queryPosition = url.indexOf("?");
  if (queryPosition < 0) return url;

  const locn = url.slice(0, queryPosition);
  const query = url
    .slice(queryPosition + 1)
    .split("&")
    .filter(noDisallowedKeys)
    .join("&");
  return `${locn}?${query}`;
}
