import { gql } from "graphql-request";

export const GET_DIGITAL_SCHEDULE = gql`
  query events($digitalSchedule: Boolean) {
    events(digitalSchedule: $digitalSchedule) {
      eventId
      endTime
      titleOnly
      dateIso
      startTime
      imageSrc
      description
      eventHost
      eventTags {
        eventTag
      }
      speakerData {
        speaker {
          company
          fullName
          imageSrc
          jobTitle
        }
      }
    }
  }
`;
