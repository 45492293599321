import {
  SearchBarData,
  SpacerData,
  TableCell,
  TableData,
  TextData,
} from "@/renderers";
import { PATHS, TEXT_CONSTS, formatNumber, pluralize } from "@/services/libs";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Individual, Pagination } from "@/services/providers/gemini/types";

const toTitleText = (pagination: Pagination | null): string => {
  if (!pagination) {
    return `${TEXT_CONSTS.PERSON}`;
  }

  return `${formatNumber(pagination?.totalRecords)} ${pluralize(
    pagination?.totalRecords || 0,
    TEXT_CONSTS.PERSON
  ).toUpperCase()}`;
};

const toIndividualTable = (
  individuals: Individual[],
  pagination: Pagination | null
): TableData => {
  const rows = individuals.map((individual) => {
    const cells = [
      {
        label: {
          copy: individual.fullname,
          href: individual.slug ? `${PATHS.people}/${individual.slug}` : "",
        },
        subText: {
          copy: individual.jobtitle || "",
          lines: 2,
        },
        separator: false,
        type: individual.slug ? "link" : "label",
      },
      individual.awardCount && individual.awardCount > 0
        ? {
            alignment: "right",
            cellWidth: "auto",
            tag: {
              content: `${formatNumber(individual.awardCount)} ${pluralize(
                individual.awardCount || 0,
                TEXT_CONSTS.AWARD
              )}`,
              component: "Festival",
              variant: "aggregated",
              type: "Tag",
            },
            type: "tag",
          }
        : {},
    ] as TableCell[];

    return { cells };
  });

  return GeminiMapper.toDirectoryTableData({
    headers: [],
    rows: rows || [],
    pagination: pagination || undefined,
  });
};

const toSearchBarData = (searchText: string): SearchBarData => ({
  type: "SearchBar",
  searchText: searchText,
  searchBarLabel: "Search Individuals by name",
});

const toEmptyResultsText = (): TextData => ({
  type: "Text",
  content: "UNFORTUNATELY, NO RESULTS WERE FOUND FOR YOUR SEARCH.",
  size: "xxlarge",
});

const maybeAddNoResults = (pagination: Pagination | null): SpacerData => {
  const shouldShowNoResults = pagination?.totalRecords === 0;
  return {
    type: "Spacer",
    size: "large",
    content: shouldShowNoResults ? [toEmptyResultsText()] : undefined,
  };
};

export const IndividualListingMapper = {
  maybeAddNoResults,
  toIndividualTable,
  toSearchBarData,
  toTitleText,
};
