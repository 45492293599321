import { FeaturePanel, Spacer } from "@horizon/components";

import type { FeaturePanelData, Link, MediaContent } from "@/renderers";
import { buildKey } from "./libs";

export const FeaturePanelComponent = (
  { textPosition, title, copy, media, link, id }: FeaturePanelData,
  index?: number
) => {
  return (
    <Spacer
      space={["medium", "xxlarge"]}
      key={buildKey(title || "featurepanel", index)}
    >
      <FeaturePanel
        textPosition={textPosition}
        title={title}
        copy={copy}
        video={videoForFeaturePanel(media)}
        image={imageForFeaturePanel(media)}
        cta={ctaForFeaturePanel(link)}
        id={id}
      />
    </Spacer>
  );
};

const ctaForFeaturePanel = (link: Link | null) => {
  return link ? link : undefined;
};
const videoForFeaturePanel = (media: MediaContent | null) => {
  return media?.mediaType === "video/mp4"
    ? {
        url: media.href,
        alt: media.alt,
        aspectRatio: "16:9",
        thumbnail: media.thumbnail,
      }
    : undefined;
};

const imageForFeaturePanel = (media: MediaContent | null) => {
  return media?.mediaType.includes("image")
    ? { src: media.href, alt: media.alt }
    : undefined;
};
