import { PageData, ComponentData } from "@/renderers";
import { ServiceArgs, Service } from "@/services";
import {
  PATHS,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
  SITE_TITLE,
  buildSectionData,
  Sort,
} from "@/services/libs";

import {
  FilterOption,
  FilteredContent,
} from "@/services/providers/gemini/types";
import { GeminiProvider } from "@/services/providers";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Authentication } from "@/libs";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { ClassicListingMapper } from "./classic_listing_mapper";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { accessToken, queryParams } = serviceArgs;
  const parsedQuery = QueryParams.parseSearchQuery(
    appendClassicContentType(queryParams || "")
  );
  const filteredContent = await GeminiProvider.getFilteredContent(
    parsedQuery,
    accessToken
  );

  const filterOptions = await GeminiProvider.getClassicFilters(
    null,
    accessToken
  );

  const result = {
    title: `Classic | ${SITE_TITLE}`,
    metaDescription:
      "Classic is the story of our shared creative history, as told by Lion-winning work itself. Here we present a selection of the very best.",
    slug: `${PATHS.classic}`,
    cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.classic}`,
    metaTitle: "Classic | The Work",
    components: await buildListingComponents(
      filteredContent,
      filterOptions,
      parsedQuery,
      serviceArgs
    ),
  };
  return stripUndefinedValuesFromObject(result);
};

const buildListingComponents = async (
  filteredContent: FilteredContent,
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery,
  serviceArgs: ServiceArgs
): Promise<ComponentData[]> => {
  const filterBlockData = GeminiMapper.toFilterBlockData(
    filterOptions,
    parsedQuery,
    Sort.buildSortOptions(
      Sort.CLASSIC_SORT_OPTIONS,
      Sort.parseSortValueFromQuery(parsedQuery, "date_ascending")
    )
  );

  return [
    buildSectionData(
      [
        ClassicListingMapper.toSearchBarData(
          (parsedQuery?.search_text as string) || ""
        ),
        filterBlockData,
        ClassicListingMapper.toGrid(filteredContent),
      ],
      "",
      "offwhite"
    ),
    maybeCreatePaywall(serviceArgs, Authentication.USER_ACTIONS.CAN_SEARCH),
  ].filter(Boolean) as ComponentData[];
};

const appendClassicContentType = (queryString: string) => {
  if (queryString.length > 1) {
    return queryString.concat("&content_type=classic");
  }
  return "content_type=classic";
};

export const ClassicListingService: Service = { retrievePageData };
