import { PageData } from "@/renderers";
import { ServiceArgs } from "@/services";
import { AccountShowMapper } from "@/services/account/account_show/account_show_mapper";
import {
  buildContainerData,
  PATHS,
  SITE_TITLE,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { GeminiProvider } from "@/services/providers";
import { UserProfile } from "@/services/providers/gemini/types";

const retrievePageData = async ({
  path,
  accessToken,
}: ServiceArgs): Promise<PageData | null> => {
  if (path.startsWith(`${PATHS.myProfile}`)) {
    const myProfile = await GeminiProvider.getMyProfile(accessToken);

    if (myProfile) {
      const result = {
        title: `Account Details | ${SITE_TITLE}`,
        components: buildComponents(myProfile),
      };
      return stripUndefinedValuesFromObject(result);
    }
  }
  return null;
};

const buildComponents = (myprofile: UserProfile) => {
  return [buildContainerData([AccountShowMapper.toFormData(myprofile)])];
};

export const AccountShowService = { retrievePageData };
