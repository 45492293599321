import Head from "next/head";

import { ComponentRenderer } from "./component_renderer";

import type { PageData } from "@/components/components.d";
import { Analytics } from "@/libs/analytics";
import type { Renderer } from ".";

const render = (page: PageData): JSX.Element | null => {
  if (page) {
    return (
      <>
        <Head>
          <title>{page.title}</title>
          <link rel="canonical" href={page.cannonicalUrl} key="canonical" />
          <meta name="description" content={page.metaDescription} key="desc" />
          <meta name="title" content={page.metaTitle} />
          <meta property="og:title" content={page.metaTitle} />
          <meta property="og:description" content={page.metaDescription} />
          <meta property="og:image" content={page.metaImage?.url} />
        </Head>
        <Analytics.AnalyticsPageTracking pageData={page}>
          {page.components?.map(ComponentRenderer.render)}
        </Analytics.AnalyticsPageTracking>
      </>
    );
  }

  return null;
};

export const PageRenderer: Renderer = { render };
