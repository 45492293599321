export const callClientApi = async (path: string, payload: object) => {
  const response = await fetch(getRequestUrl(path), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response;
};

export const callGetApi = async (path: string) => {
  const response = await fetch(getRequestUrl(path), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const callDeleteApi = async (path: string) => {
  const response = await fetch(getRequestUrl(path), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

const getRequestUrl = (path: string): string =>
  `${window.location.origin}${path}`;
