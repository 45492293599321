import { QuoteData } from "@/renderers";
import { Quote } from "@horizon/components";
import { buildKey } from "./libs";

export const QuoteComponent = (
  { copy, name, jobTitle, company, variant, media, id }: QuoteData,
  index?: number
) => {
  return (
    <Quote
      id={id}
      company={company}
      copy={copy}
      image={
        media && {
          src: media.href,
        }
      }
      jobTitle={jobTitle}
      key={buildKey("quote", index)}
      name={name}
      variant={variant}
    />
  );
};
