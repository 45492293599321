import type { Speaker, Talk } from "@/services/providers/gemini/types";

import {
  AvatarData,
  CardData,
  ComponentGroupingData,
  LabelCaptionData,
  MarkdownData,
  MediaPlayerData,
  PageTitleData,
} from "@/renderers";

import { PATHS, defaultComponentsPerRow, toDateFormat } from "@/services/libs";

const toCardData = (talk: Talk): CardData => ({
  type: "Card",
  variant: "Video",
  title: talk.title,
  link: { href: `${PATHS.talks}/${talk.id}`, label: talk.title },
  media: {
    href: talk.thumbnail,
    alt: talk.title,
    mediaType: "image/jpeg",
  },
});

const toVideoPageData = (
  talk: Talk,
  accessToken?: string
): MediaPlayerData => ({
  type: "MediaPlayerData",
  media: {
    mediaType: "video/mp4",
    alt: talk.title,
    href: talk.video || "",
    thumbnail: talk.thumbnail,
    captionsUrl: talk.captionsUrl,
  },
  accessToken: accessToken,
  title: talk.title,
  publishDate: talk.startsAt,
  category: talk?.stage,
});

const toBodyData = (talk: Talk): MarkdownData => ({
  type: "Markdown",
  copySize: "large",
  html: talk.description,
});

const toTitleData = (talk: Talk): PageTitleData => ({
  type: "PageTitle",
  size: "medium",
  content: talk.title,
});

const toSpeakerCarouselData = (talk: Talk): ComponentGroupingData =>
  talk.speakers && {
    type: "ComponentGrouping",
    title: "Speakers",
    variant: "grid",
    components: talk.speakers.map(toSpeakerAvatarData),
    componentsPerRow: defaultComponentsPerRow,
  };

const toSpeakerAvatarData = (speaker: Speaker): AvatarData => ({
  type: "Avatar",
  image: speaker.avatar
    ? {
        alt: speaker.name,
        href: speaker.avatar,
        mediaType: "image/jpeg",
      }
    : undefined,
  name: speaker.name,
  subText: speaker.title,
});

const toRelatedTalksCarouselData = (
  talk: Talk
): ComponentGroupingData | undefined =>
  talk.related && {
    type: "ComponentGrouping",
    title: "Related",
    variant: "carousel",
    components: talk.related.map(toCardData),
    componentsPerRow: defaultComponentsPerRow,
  };

const getHostText = (talk: Talk): string => {
  if (!talk.host || !talk.host.name) {
    return "";
  }
  return `Host: ${talk.host.name}`;
};

const getStageText = (talk: Talk): string => {
  if (!talk.stage) {
    return "";
  }
  return `Stage: ${talk.stage}`;
};

const toLabelCaptionData = (talk: Talk): LabelCaptionData | undefined => {
  const datePublished = toDateFormat(talk?.startsAt);
  const host = getHostText(talk);
  const stage = getStageText(talk);
  const caption = [datePublished, host, stage].filter(Boolean).join(" / ");
  return {
    type: "LabelCaption",
    content: caption,
  };
};

export const TalkMapper = {
  toBodyData,
  toCardData,
  toLabelCaptionData,
  toRelatedTalksCarouselData,
  toSpeakerCarouselData,
  toTitleData,
  toVideoPageData,
};
