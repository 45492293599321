import { Service, ServiceArgs } from "@/services/";
import type { PageData, ComponentData } from "@/renderers";
import { GeminiProvider } from "@/services/providers";
import { YoungEntry } from "@/services/providers/gemini/types";
import { UserAction } from "@/libs/authentication/user";

import {
  PATHS,
  SITE_TITLE,
  buildSectionData,
  getIdFromSlug,
  stripUndefinedValuesFromObject,
} from "@/services/libs";

import { YoungEntryShowMapper } from "./young_entry_show_mapper";
const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { accessToken, path, userActions } = serviceArgs;
  if (path.startsWith(PATHS.youngEntries)) {
    const id = getIdFromSlug(path);
    const youngEntry = await GeminiProvider.getYoungEntryById(id, accessToken);
    if (youngEntry) {
      const result = {
        title: `${youngEntry.title} | Young Entry | ${SITE_TITLE}`,
        metaDescription: youngEntry.title,
        slug: `${PATHS.youngEntries}${youngEntry.slug}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.youngEntries}${youngEntry.slug}`,
        metaTitle: youngEntry.title,
        metaImage: {
          url: (youngEntry.assets && youngEntry.assets[0]?.thumbnailUrl) || "",
        },
        components: await buildComponents(youngEntry, userActions),
      };
      return stripUndefinedValuesFromObject(result);
    }
  }

  return null;
};

const buildComponents = async (
  youngEntry: YoungEntry,
  userActions?: UserAction[]
) => {
  const galleryCarousel = YoungEntryShowMapper.maybeToGalleryCarousel(
    youngEntry,
    userActions
  );
  return [
    YoungEntryShowMapper.toTitleData(youngEntry),
    galleryCarousel ? buildSectionData([galleryCarousel]) : {},
    buildSectionData([YoungEntryShowMapper.toTabContainer(youngEntry)]),
  ]
    .flat()
    .filter(Boolean) as ComponentData[];
};

export const YoungEntryShowService: Service = { retrievePageData };
