import {
  Column,
  Container,
  Grid,
  Spacer,
  Text,
  Flex,
} from "@horizon/components";

import { TagListData, TagData } from "@/renderers";
import { buildKey } from "./libs";
import { TagComponent } from "./TagComponent";

export const TagListComponent = (
  { label, tags, id }: TagListData,
  index?: number
) => {
  return (
    <Container key={buildKey(label, index)} id={id}>
      <Spacer space="medium">
        <Grid>
          <Column>
            <Text size="large">{label || ""}</Text>
          </Column>
          <Column>
            <Flex
              style={{
                flexWrap: "wrap",
                gap: "0.8rem",
              }}
            >
              {tags.map((tag, index) => {
                return TagComponent(tag as TagData, index);
              })}
            </Flex>
          </Column>
        </Grid>
      </Spacer>
    </Container>
  );
};
