import { SectionBlockData } from "@/renderers";
import { Section } from "@horizon/components";

export const SectionComponent = ({
  sectionData,
  children,
}: {
  sectionData: SectionBlockData;
  children: JSX.Element | JSX.Element[] | null;
}): JSX.Element => {
  const { title, subtitle, backgroundMode, darkModeBackgroundColour, id } =
    sectionData;
  return (
    <Section
      title={{ copy: title, as: "h2", size: "large" }}
      subtitle={{ copy: subtitle }}
      backgroundColor={darkModeBackgroundColour}
      backgroundMode={backgroundMode}
      data-color-mode={backgroundMode}
      contentSpace={title ? "xxlarge" : "none"}
      id={id}
    >
      {children}
    </Section>
  );
};
