import { CollapsibleNavigationLayoutData, SectionBlockData } from "@/renderers";
import {
  CollapsibleNavigation,
  Container,
  Grid,
  Column,
} from "@horizon/components";

import { ComponentRenderer } from "@/renderers";
import { createId } from "@/components/libs";

export const CollapsibleNavigationLayoutComponent = (
  collapsibleNavigationLayoutData: CollapsibleNavigationLayoutData
) => {
  return collapsibleNavigationLayoutData.isFullWidth
    ? navigationLayout(collapsibleNavigationLayoutData)
    : wrapInContainer(navigationLayout(collapsibleNavigationLayoutData));
};

const navigationLayout = ({
  sections,
  columnBreakPoints = {
    navigationBreakPoints: [12, null, 3],
    contentBreakPoints: [12, null, 9],
  },
}: CollapsibleNavigationLayoutData): JSX.Element => {
  return (
    <Grid>
      <Column
        mt={["small", "xxxxlarge"]}
        width={columnBreakPoints.navigationBreakPoints}
      >
        <CollapsibleNavigation
          links={getCollapsibleNavigationLinks(sections)}
          active={"#"}
        ></CollapsibleNavigation>
      </Column>
      <Column width={columnBreakPoints.contentBreakPoints}>
        {sectionContent(sections)}
      </Column>
    </Grid>
  );
};

const wrapInContainer = (content: JSX.Element) => {
  return <Container>{content}</Container>;
};

const sectionContent = (sections: SectionBlockData[]) => {
  return sections?.map((component, index) => {
    return ComponentRenderer.render(component, index);
  });
};

const getCollapsibleNavigationLinks = (sections: SectionBlockData[]) => {
  return sections.map(buildCollapsibleNavigation).filter(Boolean);
};

const buildCollapsibleNavigation = (section: SectionBlockData) => {
  return section.title
    ? {
        href: `#${createId(section.title)}`,
        label: { copy: section.title, lines: 2 },
        navItems: buildSubNavItems(section),
      }
    : null;
};

const buildSubNavItems = ({ content }: SectionBlockData) => {
  return content
    ?.map((component: any) =>
      component && component.title
        ? {
            label: { copy: component.title },
            href: `#${createId(component.title)}`,
          }
        : null
    )
    .filter(Boolean);
};
