import { Speaker, Talk } from "@/services/providers/gemini/types";

const getTalks = async (params: String): Promise<any> => {
  return {
    data: [t1, t2, t3, t4, t1, t2, t3, t4, t1, t2, t3, t4, t1, t2, t3, t4],
  };
};

export const StoreProvider = { getTalks };

const s1: Speaker = {
  id: 8281,
  name: "Davis Lin",
  avatar:
    "https://cdn.filespin.io/api/v1/conversion/8c8715ce749c4180bf7ffa28c0d77cd5",
  role: "Corporate Vice President",
  company: "Tencent",
  location: "",
  bio: "Davis Lin joined Tencent in April 2013, where he is currently Corporate Vice President, leading Corporate Strategy, Tencent Social Ads and Tencent Smart Retail. In these capacities, he is responsible for planning and developing Corporate- and Business Group-level strategic initiatives, researching and incubating new business ventures, coordinating Tencent’s business partnerships with its major investee companies, leading Tencent’s social and performance advertising business, and facilitating the strategic development of Tencent’s Smart Retail solution and key partnerships.\n\nBefore joining Tencent, Davis was a partner at McKinsey & Company and Office Manager of Taipei for 12 years. He received his MBA degree from Harvard Business School in 2001.",
};

const s2: Speaker = {
  id: 38232,
  name: "Janet Scardino",
  avatar: "",
  role: "CEO",
  company: "MTV",
  location: "",
  bio: "A seasoned television and digital media executive, Janet Scardino launched, operated and expanded many successful businesses for leading brands including MTV, Disney Channel, AOL, Reuters and American Idol.\n\nAs a start-up intrapreneur, she has built teams and successful businesses for MTV and Disney Channel and led profitable multi-platform digital businesses, driven by advertising, subscription and e-commerce, for AOL, The Knot and Reuters.",
};

const s3: Speaker = {
  id: 39521,
  name: "Keith Weed",
  avatar:
    "http://www-assets.lionsfestivals.com.s3.amazonaws.com/cannes/cms-assets/speakers/ac20d2e625434842a55e15fe0418aff1_keithweed_unilever_mg_9965.jpg",
  role: "Chief Marketing Officer",
  company: "Unilever",
  location: "",
  bio: "As Chief Marketing and Communications Officer, Keith is a member of the Unilever Executive and responsible for the Marketing, Communications and Sustainable Business functions. His responsibilities are aligned to support Unilever’s vision: to double the size of the business while reducing its environmental footprint and increasing positive social impact.  Keith sees sustainability as a driver of consumer-led profitable growth. He led the creation of the Unilever Sustainable Living Plan and has also pioneered new ways of integrating sustainability into the business. Keith has led a step change in marketing at Unilever, most notably with significant advances in digital marketing and technologies, and has championed the development of brands with purpose through Unilever’s crafting Brands for Life strategy. Outside Unilever he is a Fellow of The Marketing Society, and as an engineering graduate, a Fellow of the Institute of Mechanical Engineers.  He is also Chairman of the World Economic Forum Consumer Industry Board, Chairman of Business in the Community International, Chairman of the not-for-profit Collectively and a Non Executive Director of Sun Products Corporation.",
};

const s4: Speaker = {
  id: 38945,
  name: "Armando Iannucci",
  avatar:
    "http://portal-assets.lionsfestivals.com.s3.amazonaws.com/archive/cannes/speakers/2014/speaker_20140409114727.jpg",
  role: "Writer, Producer",
  company: "Freelance",
  location: "",
  bio: "Armando Iannucci is the Creator, and a writer and executive producer, of the acclaimed HBO comedy series Veep, currently in its third season. The show was Emmy-nominated for Outstanding Comedy Series for both its first two seasons, and recently received the Writers Guild Award for Best Comedy Series. Star Julia Louis-Dreyfus has received a Screen Actors Guild Award and two Emmys for the show, and series regular Tony Hale received the Emmy for Outstanding Supporting Actor in a Comedy Series last year. Veep has received eight Emmy nominations to date.\n\nIn 2009, <span>Armando</span>&nbsp;directed and co-wrote In the Loop, which was nominated for a Best Adapted Screenplay Academy Award. His other TV credits include the series The Day Today, The Friday Night Armistice and The Thick of It, which won three BAFTA TV Awards. He co-created the series I&rsquo;m Alan Partridge, which garnered two BAFTAs, two British Comedy Awards and a Royal Television Society Award. <span>Armando</span>&nbsp;was an executive producer and writer of the feature film Alan Partridge.",
};

const t1: Talk = {
  id: 46437,
  title: "Reconfiguring Retail",
  description:
    "The 2022 Jury President for the Creative Commerce Lions, Beth Ann Kaminkow Global CEO, VMLY&R Commerce, describes retail as ‘a new centre of gravity for marketing and an incredibly important part of people’s lives.’ And adapting your business so you can deliver on consumer expectations of buying anytime, anywhere means refreshing your retail strategy. This session explores the creative innovations that are reconfiguring the global retail landscape. It will focus on trends and trailblazers, and offer practical advice to steer you towards success.",
  thumbnail:
    "https://ascentialcdn.filespin.io/api/v1/conversion/49e348b84a1c45d1bde58067f09dc6a4",
  video:
    "https://ascentialcdn.filespin.io/api/v1/video/e1ca5e2809c346faa6cc90d0a532b135/1080p-wm-video.mp4",
  tags: ["red", "viszla", "afghanistan"],
  related: [],
  speakers: [s1, s2, s3],
  host: s4,
};

const t2: Talk = {
  id: 48291,
  title: "Creative Best Practice: The Antidote to Rising Media Costs",
  description:
    "What does it take to be effective in 2022? We look at the evidence, and show the building blocks of success - and how they're changing. What does it take to be effective in 2022? We look at the evidence, and show the building blocks of success - and how they're changing. What does it take to be effective in 2022? We look at the evidence, and show the building blocks of success - and how they're changing. What does it take to be effective in 2022? We look at the evidence, and show the building blocks of success - and how they're changing.",
  thumbnail:
    "https://cdn.filespin.io/api/v1/storyboard/e45af56cf0fb44b39c8a89595c645e11/storyboard_000020.jpg?resize=480,360",
  video:
    "https://ascentialcdn.filespin.io/api/v1/video/e45af56cf0fb44b39c8a89595c645e11/1080p-wm-video.mp4",
  tags: ["green", "poodle", "kenya"],
  related: [],
  speakers: [s4, s1, s2],
  host: s3,
};

const t3: Talk = {
  id: 46428,
  title: "The Entertainment Lions for Sport Debrief ",
  description:
    "This session brings together two selected jury members and the Jury President fresh from the jury room. A deep dive into the work that won and why, exploring some of the insights coming from the Lions as well as practical advice from the Jury.",
  thumbnail:
    "https://ascentialcdn.filespin.io/api/v1/conversion/8788ff274cab41959eb61a2be6fa08d3",
  video:
    "https://ascentialcdn.filespin.io/api/v1/video/432b94a2de99457fbb15b99e8741e66f/1080p-wm-video.mp4",
  tags: ["yellow", "schnauser", "new zealand"],
  related: [],
  speakers: [s3, s2, s4],
  host: s1,
};

const t4: Talk = {
  id: 46491,
  title: "The Lion of St. Mark Seminar with Colleen DeCourcy",
  description:
    "The celebration of creativity is at the heart of the Festival, and the Lion of St. Mark recognises individuals who have made significant and outstanding contributions to creativity across our industry. This year’s recipient is an inspiring industry leader, Colleen DeCourcy, former Chief Creative Officer and President of Wieden+Kennedy. DeCourcy has spent her career leading innovative companies and advocating for a more equitable industry. She was named in Fast Company magazine’s Most Creative People in Business, honoured with the Fearless Voices award by the organisation She Runs It, and in 2019 was selected as the creative leader of the decade by AdWeek magazine. Come and be inspired by a masterclass of creativity with this year’s Lion of St. Mark.",
  thumbnail:
    "https://cdn.filespin.io/api/v1/storyboard/418e54c75c884575a9fa33272cb9197f/storyboard_000020.jpg?resize=480,360",
  video:
    "https://ascentialcdn.filespin.io/api/v1/video/418e54c75c884575a9fa33272cb9197f/1080p-wm-video.mp4",
  tags: ["blue", "greyhound", "belgium"],
  related: [],
  speakers: [s3, s4, s1],
  host: s2,
};
