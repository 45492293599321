import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_RANKINGS } from "@/services/providers/gemini/queries/rankings_query";
import { FilterOption } from "@/services/providers/gemini/types/filters";

export const getRankingsData = async (
  queryParams: any,
  accessToken?: string
): Promise<Array<FilterOption>> => {
  const { filteredRankings: rankings } = (await callGemini(
    { query: GET_RANKINGS, variables: queryParams },
    accessToken
  )) || { filteredRankings: [] };
  return rankings;
};
