import { PAGINATION_FRAGMENT } from "@/services/providers/gemini/queries/shared_fragments/pagination_fragment";
import { gql } from "graphql-request";

export const GET_FILTERED_CLASSIC_ASSETS = gql`
  query getFilteredClassicAssets(
    $ids: [Int]
    $agencies: [String]
    $awards: [String]
    $brands: [String]
    $lions: [String]
    $productions: [String]
    $countries: [String]
    $sectors: [String]
    $years: [String]
    $page: Int
    $search_text: String
    $sort: String
  ) {
    filteredClassicAssets(
      filters: {
        ids: $ids
        agencies: $agencies
        awards: $awards
        brands: $brands
        lions: $lions
        productions: $productions
        countries: $countries
        sectors: $sectors
        years: $years
        page: $page
        searchText: $search_text
        sort: $sort
      }
    ) {
      classicAssets {
        id
        slug
        year
        title
        agency
        brand
        sector
        production
        region
        country
        lion
        award
        imageUrl
        videoUrl
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;

export const GET_CLASSIC_FILTERS = gql`
  query getClassicAssetFilterOptions(
    $ids: [Int]
    $agencies: [String]
    $awards: [String]
    $brands: [String]
    $lions: [String]
    $productions: [String]
    $countries: [String]
    $sectors: [String]
    $years: [String]
    $page: Int
    $search_text: String
  ) {
    getClassicAssetFilterOptions(
      filters: {
        ids: $ids
        agencies: $agencies
        awards: $awards
        brands: $brands
        lions: $lions
        productions: $productions
        countries: $countries
        sectors: $sectors
        years: $years
        page: $page
        searchText: $search_text
      }
    ) {
      key
      label
      values {
        label
        key
      }
    }
  }
`;
