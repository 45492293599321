import { LinkData } from "@/renderers";
import { buildKey } from "./libs";

export const LinkComponent = (
  { link }: LinkData,
  index?: number
): JSX.Element => {
  // TODO Change to Horizon Link when available.
  return (
    <a href={link.href} key={buildKey("links", index)}>
      {link.label}
    </a>
  );
};
