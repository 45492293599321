import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_FILTERED_CLASSIC_ASSETS } from "@/services/providers/gemini/queries/classic_asset_query";
import { ClassicAsset } from "@/services/providers/gemini/types";

export const getClassicAssetById = async (
  id: string,
  accessToken?: string
): Promise<ClassicAsset | null> => {
  const results = await callGemini(
    {
      query: GET_FILTERED_CLASSIC_ASSETS,
      variables: { ids: [parseInt(id)] },
    },
    accessToken
  );

  if (results === null) {
    return null;
  } else {
    const {
      filteredClassicAssets: { classicAssets: classicAssets },
    } = results;
    return classicAssets[0];
  }
};
