import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";
import { QueryParams } from "@/libs/QueryParams";
import { Analytics } from "@/libs/analytics";
import { SearchBarDataExtend } from "@/renderers";
import { Container } from "@horizon/components";
import { SearchBlock, SelectDropdown, Button } from "@lions/ui-components";
import { SetStateAction, useEffect, useState, useCallback } from "react";
import { Api } from "@/libs/api";
import { type ISelectDropdownOption } from "@/components/components.d";

const mapOptions = (
  currentOptions: ISelectDropdownOption[],
  prop: "label" | "value",
  value: string
) => {
  return currentOptions.map((option) => {
    return {
      ...option,
      checked: option[prop] === value,
    };
  });
};

export const SearchBarComponent = ({
  searchText,
  searchBarLabel,
  suggestions,
  contentType,
  contentLabel,
  searchOptions,
}: SearchBarDataExtend) => {
  const [text, setSearchText] = useState(searchText || "");
  const [selectOptions, setSelectOptions] = useState(searchOptions);
  const [selectLabel, setSelectLabel] = useState(contentLabel || "All");
  const [selectValue, setSelectValue] = useState(contentType || "all");

  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  const onSearchHandler = useCallback(
    (label: string): any => {
      const searchText = label || text || "";
      Analytics.trackSearchTextEntered(clientParams, searchText, user);
      Api.storeUserSeach(searchText);
      QueryParams.replaceAllQueryParams({
        search_text: searchText,
        content_type: selectValue,
      });
    },
    [selectValue, text, clientParams, user]
  );

  const onOptionChange = (_name: string, label: string, value: string) => {
    const newOptions = mapOptions(selectOptions, "label", label);
    setSelectOptions(newOptions);
    setSelectLabel(label);
    setSelectValue(value);
  };

  // This effect handles changing the select dropdown options
  useEffect(() => {
    // Change only if there are search options and the content type is not the same as the current value
    if (searchOptions && text && selectValue !== contentType) {
      onSearchHandler(text);
    }
  }, [searchOptions, selectValue, text, contentType, onSearchHandler]);

  const selectDropdown = searchOptions ? (
    <SelectDropdown
      onOptionChange={onOptionChange}
      label={selectLabel}
      options={selectOptions}
      trigger={
        <Button
          icon={{ position: "end", name: "ChevronDown" }}
          variant="secondary"
        >
          {selectLabel}
        </Button>
      }
    />
  ) : undefined;

  return (
    <Container>
      <SearchBlock
        focus={true}
        initialValue={text}
        placeholder={searchBarLabel}
        optionalElement={selectDropdown}
        onChange={(e: { target: { value: SetStateAction<string> } }) => {
          setSearchText(e.target.value);
        }}
        suggestions={suggestions}
        callback={onSearchHandler}
      />
    </Container>
  );
};
