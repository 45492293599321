import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_CLASSIC_FILTERS } from "@/services/providers/gemini/queries/classic_asset_query";
import { FilterOption } from "@/services/providers/gemini/types/filters";

export const getClassicFilters = async (
  queryParams: any,
  accessToken?: string
): Promise<Array<FilterOption>> => {
  const { getClassicAssetFilterOptions: options } = (await callGemini(
    { query: GET_CLASSIC_FILTERS, variables: queryParams },
    accessToken
  )) || { getClassicAssetFilterOptions: [] };
  return options;
};
