import { Authentication } from "@/libs";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { ServiceArgs } from "@/services";
import { BrandListingMapper } from "@/services/directory/brand_listing/brand_listing_mapper";
import {
  SITE_TITLE,
  buildContainerData,
  buildSectionData,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { GeminiProvider } from "@/services/providers";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import {
  Brand,
  FilterOption,
  Pagination,
} from "@/services/providers/gemini/types";

const retrievePageData = async (serviceArgs: ServiceArgs) => {
  const { path, accessToken, queryParams } = serviceArgs;
  const parsedQuery = QueryParams.parseSearchQuery(
    appendContentType(queryParams || "")
  );
  delete parsedQuery?.id;
  const filterOptions = await GeminiProvider.getBrandFilters(
    parsedQuery,
    accessToken
  );
  const { brands, pagination } = await GeminiProvider.getFilteredBrands(
    parsedQuery,
    accessToken
  );

  if (brands) {
    const result = {
      title: `${SITE_TITLE} | Brands`,
      metaDescription: `${SITE_TITLE} | Brands`,
      slug: `${path}`,
      cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${path}`,
      metaTitle: `${SITE_TITLE} | Brands`,
      components: [
        ...buildComponents(brands, filterOptions, parsedQuery, pagination),
        maybeCreatePaywall(
          serviceArgs,
          Authentication.USER_ACTIONS.BRANDS_VIEW
        ),
      ].filter(Boolean),
    };
    return stripUndefinedValuesFromObject(result);
  }
  return null;
};

const appendContentType = (queryString: string) => {
  if (queryString.length > 1) {
    return queryString.concat(`&content_type=brands`);
  }
  return `content_type=brands`;
};

const buildComponents = (
  brands: Brand[],
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery,
  pagination: Pagination | null
) => {
  return [
    buildSectionData(
      [
        BrandListingMapper.toSearchBarData(
          (parsedQuery?.search_text as string) || ""
        ),
        GeminiMapper.toFilterBlockData(
          filterOptions,
          parsedQuery,
          buildSortOptions(parsedQuery)
        ),
        buildContainerData([BrandListingMapper.maybeAddNoResults(pagination)]),
        buildContainerData([
          BrandListingMapper.toBrandTable(brands, pagination),
        ]),
      ],
      BrandListingMapper.toTitleText(pagination)
    ),
  ];
};

const SORT_OPTIONS = [
  { value: "date_descending", label: "Newest to oldest" },
  { value: "date_ascending", label: "Oldest to newest" },
  { value: "relevance", label: "Best match" },
  { value: "most_awarded", label: "Most awarded" },
  { value: "title_ascending", label: "Title - A-Z" },
  { value: "title_descending", label: "Title - Z-A" },
];

const buildSortOptions = (
  parsedQuery: ParsedQuery
): {
  options: { label: string; value: string }[];
  initialValue: string;
} => ({
  options: SORT_OPTIONS,
  initialValue: parsedQuery?.sort ? (parsedQuery.sort as string) : "relevance",
});

export const BrandListingService = { retrievePageData };
