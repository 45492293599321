import { CollectionActionsData, MediaPlayerData } from "@/renderers";
import {
  Button_v2,
  FlexGroup,
  Box,
  Modal_v2,
  TextInput,
  Spacer,
} from "@horizon/components";
import { LightboxComponent } from "@/components";

import { Api } from "@/libs/api";
import { useState } from "react";
import { PATHS } from "@/services/libs";
import { Authentication } from "@/libs";

const copyToClipboard = async (copyText: string) => {
  await navigator.clipboard.writeText(copyText);
};

const deleteModal = (
  deleteModalOpen: boolean,
  closeDeleteModal: Function,
  id: number
) => {
  const redirectToCollections = () => {
    return (window.location.href = PATHS.collections);
  };
  return (
    <Modal_v2
      isOpen={deleteModalOpen}
      closeModal={closeDeleteModal}
      headerTitle={{
        as: "h4",
        copy: "Delete Collection",
      }}
      title={{
        as: "h4",
        copy: "Are you sure you want to delete this Collection?",
      }}
      ctas={[
        {
          variant: "primary",
          children: "Delete Collection",
          onClick: () => Api.deleteCollection(id, redirectToCollections),
        },
        {
          variant: "secondary",
          children: "Cancel",
          onClick: closeDeleteModal,
        },
      ]}
    />
  );
};

const shareModal = (
  shareModalOpen: boolean,
  closeShareModal: Function,
  shareLink: string
) => {
  return (
    <Modal_v2
      isOpen={shareModalOpen}
      closeModal={closeShareModal}
      title={{
        as: "h4",
        copy: "Share",
      }}
      ctas={[
        {
          variant: "tertiary",
          children: "Copy Link",
          onClick: () => copyToClipboard(shareLink),
        },
      ]}
    >
      <p>
        Non-subscribers may access a Playlist for 48 hours after this share link
        is created.
      </p>
      <Spacer space="medium" />
      <TextInput
        inputType="text"
        name="TextInput"
        onChangeCallback={() => {}}
        value={shareLink}
      />
    </Modal_v2>
  );
};

const playModal = (
  playModalOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  assets: MediaPlayerData[]
) => {
  return (
    <LightboxComponent
      media={assets}
      isOpen={playModalOpen}
      setIsOpen={setIsOpen}
      shouldAutoplay={false}
      showDownloadCta={false}
    />
  );
};

export const updateModal = (
  updateModalOpen: boolean,
  closeUpdateModal: Function,
  setTitleValue: Function,
  collectionData: {
    id: number;
    campaignIds: number[];
    entryIds: number[];
    title: string;
  }
) => {
  const { id, campaignIds, entryIds, title } = collectionData;

  const handleChange = (e: React.FormEvent<HTMLAllCollection>): void => {
    e.preventDefault();
    const inputtedValue = e.currentTarget as unknown as HTMLInputElement;
    setTitleValue(inputtedValue.value);
  };

  const reloadPage = () => {
    return location.reload();
  };

  return (
    <Modal_v2
      isOpen={updateModalOpen}
      closeModal={closeUpdateModal}
      title={{
        as: "h4",
        copy: "Update Title",
      }}
      ctas={[
        {
          variant: "tertiary",
          children: "Save",
          onClick: () =>
            Api.updateCollection(
              { id, campaignIds, entryIds, name: title },
              reloadPage
            ),
        },
      ]}
    >
      <Spacer space="medium" />
      <TextInput
        inputType="text"
        name="TextInput"
        onChangeCallback={handleChange}
        value={title}
      />
    </Modal_v2>
  );
};

export const CollectionActions = ({
  assets,
  id,
  campaignIds,
  entryIds,
  title,
  actions,
  shareLink,
}: CollectionActionsData) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [playModalOpen, setPlayModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [titleValue, setTitleValue] = useState(title);
  const user = Authentication.useAuthenticatedUser();

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const openShareModal = (id: number) => {
    Api.shareCollection(id);
    setShareModalOpen(true);
  };
  const closeShareModal = () => {
    setShareModalOpen(false);
  };
  const closeUpdateModal = () => {
    setUpdateModalOpen(false);
  };

  return (
    <FlexGroup gap={["medium", "base"]}>
      {actions.includes("delete") && (
        <Box key="delete">
          {deleteModal(deleteModalOpen, closeDeleteModal, id)}
          <Button_v2
            variant="secondary"
            size="small"
            icon={{
              name: "X",
              position: "end",
            }}
            onClick={() => setDeleteModalOpen(true)}
          >
            Delete
          </Button_v2>
        </Box>
      )}

      {actions.includes("share") && (
        <Box key="share">
          {shareModal(shareModalOpen, closeShareModal, shareLink)}
          <Button_v2
            variant="secondary"
            size="small"
            icon={{
              name: "ExternalLink",
              position: "end",
            }}
            onClick={() => openShareModal(id)}
          >
            Share
          </Button_v2>
        </Box>
      )}

      {actions.includes("play") && (
        <Box key="play">
          {playModal(playModalOpen, setPlayModalOpen, assets)}
          <Button_v2
            variant="secondary"
            size="small"
            icon={{
              name: "Play",
              position: "end",
            }}
            onClick={() => setPlayModalOpen(true)}
          >
            Play
          </Button_v2>
        </Box>
      )}

      {actions.includes("rename") && (
        <Box key="rename">
          {updateModal(updateModalOpen, closeUpdateModal, setTitleValue, {
            id,
            campaignIds,
            entryIds,
            title: titleValue,
          })}
          <Button_v2
            variant="secondary"
            size="small"
            icon={{
              name: "Edit",
              position: "end",
            }}
            onClick={() => setUpdateModalOpen(true)}
          >
            Rename
          </Button_v2>
        </Box>
      )}
    </FlexGroup>
  );
};
