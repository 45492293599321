import * as Yup from "yup";
import { Form } from "@horizon/components";
import { FormData, FormField } from "@/components/components.d";
import { callClientApi } from "@/libs/client_api_caller";
import { Analytics } from "@/libs/analytics";
import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";

export const FormComponent = (formData: FormData) => {
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  return (
    <Form
      id={formData.id}
      formSchema={buildFormSchema(formData)}
      initialData={formData.initialValues || {}}
      onSubmit={(values: Record<string, unknown>) => {
        Analytics.trackFormSubmit(
          clientParams,
          formData.id,
          formData.sections[0].title,
          user
        );
        onFormSubmit(values, formData);
      }}
      formFieldDataset={formData.fieldDataSets || {}}
      validationSchema={buildValidationSchema(
        formData.sections.flatMap((section) => section.fields)
      )}
    />
  );
};

const buildFormSchema = ({ sections }: FormData) => {
  return sections.map(({ fields, id, title }) => ({
    fields: fields.map(parseFormField),
    id: id,
    title: title,
  }));
};

const parseFormField = (field: FormField) => ({
  disabled: field.disabled,
  id: field.id,
  name: field.name,
  type: field.type,
  required: field.required,
  placeholder: field.placeholder,
  opts: field.opts || {},
});

const buildValidationSchema = (fields: FormField[]): Yup.AnyObjectSchema => {
  let validationSchema: Record<string, unknown> | undefined = undefined;
  fields.forEach((field) => {
    if (field.validations) {
      validationSchema = validationSchema || {};
      validationSchema[field.id] = getValidationSchema(field.validations);
    }
  });
  return Yup.object().shape(validationSchema || {});
};

const getValidationSchema = (validationType: string) => {
  switch (validationType) {
    case "telephone":
      return Yup.string()
        .required("A phone number is required")
        .matches(
          /^[+].+/,
          'Phone number should be valid, it should start with a "+" followed by a country dialing code.'
        );
    case "required":
      return Yup.string().required("This field is required");
    case "email":
      return Yup.string().email("Invalid email").required("Required");
    case "terms and conditions":
      return Yup.string()
        .required("This field is required")
        .matches(
          new RegExp("true"),
          "To continue, you must accept the terms and conditions."
        );
  }
  return Yup.string();
};

const onFormSubmit = async (
  values: Record<string, unknown>,
  formData: FormData
) => {
  const result = await callClientApi(
    `/api/forms/submit/${formData.id}`,
    values
  );
  if (result) {
    const payload = await result.json();
    if (payload && payload.status === 302) {
      window && window.location.replace(payload.location);
    }
  }
};
