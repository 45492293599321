// components/Logger.tsx
import winston from "winston";

const logger = winston.createLogger({
  transports: [
    new winston.transports.Console({
      format: winston.format.combine(
        winston.format.colorize(),
        winston.format.timestamp(),
        winston.format.errors({ stack: true }),
        winston.format.printf((info: any) => {
          return `${info.timestamp} ${info.level}: ${info.message}`;
        })
      ),
      silent: process.env.NODE_ENV === "test",
      handleExceptions: true,
    }),
  ],
});

export { logger };
