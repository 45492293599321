import {
  CardData,
  ComponentGroupingData,
  LabelCaptionData,
  MarkdownData,
  MediaPlayerData,
  PageTitleBlockData,
} from "@/renderers";
import { PATHS, defaultComponentsPerRow, toDateFormat } from "@/services/libs";
import { Editorial, FilteredContent } from "@/services/providers/gemini/types";

const toCardData = (editorial: any): CardData => {
  let href = `${PATHS.video}/${editorial.slug}`;
  if (editorial.mediaType === "PDF") {
    href = `${PATHS.reports}/${editorial.slug}`;
  }

  return {
    type: "Card",
    variant: "Editorial",
    title: editorial.title,
    link: { href: href, label: editorial.title },
    media: {
      href: editorial.imageUrl,
      alt: editorial.title,
      mediaType: "image/jpeg",
    },
    superText: toDateFormat(editorial?.publishedDate),
    subText: editorial.theme,
  };
};

const toVideoPageData = (
  editorial: Editorial,
  accessToken?: string
): MediaPlayerData => {
  return {
    type: "MediaPlayerData",
    media: {
      mediaType: "video/mp4",
      href: editorial.assetUrl || "",
      thumbnail: editorial.imageUrl,
      alt: editorial.title,
      captionsUrl: editorial.captionsUrl,
    },
    accessToken: accessToken,
    title: editorial.title,
    publishDate: editorial.publishedDate,
    category: editorial?.editorialCategory?.title,
  };
};

const toBodyData = (editorial: Editorial): MarkdownData => ({
  type: "Markdown",
  copySize: "large",
  html: editorial.description,
});

const toPageTitleBlock = (
  editorial: Editorial,
  canDownload: boolean
): PageTitleBlockData => ({
  type: "PageTitleBlock",
  title: editorial.title,
  subText: `${editorial.editorialCategory?.title} / ${toDateFormat(
    editorial.publishedDate
  )}`,
  ...(canDownload
    ? {
        ctas: [
          {
            type: "Button",
            variant: "primary",
            label: "Download PDF",
            href: editorial.assetUrl,
            icon: {
              name: "Download",
              position: "end",
            },
          },
        ],
      }
    : {}),
});

const toDatePublishedData = (editorial: Editorial): LabelCaptionData => {
  return {
    type: "LabelCaption",
    content: toDateFormat(editorial?.publishedDate),
  };
};

const toRelatedEditorialCarouselData = (
  editorials: FilteredContent
): ComponentGroupingData => {
  const components = editorials?.content?.map(toCardData) || [];
  return {
    type: "ComponentGrouping",
    title: "Up Next",
    variant: "carousel",
    components: components,
    componentsPerRow: defaultComponentsPerRow,
  };
};

export const VideoShowMapper = {
  toBodyData,
  toVideoPageData,
  toDatePublishedData,
  toRelatedEditorialCarouselData,
  toPageTitleBlock,
};
