import {
  GalleryItemMedia,
  GalleryBlockData,
  MediaItem,
} from "@/components/Media/media.d";
import { GalleryBlock } from "@lions/ui-components";
import { Image } from "@horizon/components";

const sortMediaArray = (mediaItem: GalleryItemMedia): MediaItem => {
  return (
    <Image
      {...mediaItem}
      mediaType="image"
      style={{ width: "100%", height: "100%", objectFit: "cover" }}
    />
  );
};

export const GalleryBlockComponent = ({
  media,
  topCaption,
  bottomCaption,
}: GalleryBlockData) => {
  const mediaItems: MediaItem[] = media.map(sortMediaArray);
  const props = {
    bottomCaption,
    topCaption,
    mediaItems,
  };

  return <GalleryBlock {...props} />;
};
