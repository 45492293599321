import { DescriptionBlockData } from "@/renderers";
import { Column, Grid, SectionHeader } from "@horizon/components";
import { buildKey } from "./libs";

export const DescriptionBlockComponent = (
  { label, content, id }: DescriptionBlockData,
  index?: number
) => {
  return (
    <Grid key={buildKey(label, index)}>
      <Column width={[12, null, 6, 8]}>
        <SectionHeader title={label} copy={content} id={id} />
      </Column>
    </Grid>
  );
};
