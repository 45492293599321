import { CollectionData, MediaPlayerData } from "@/renderers";
import { typeFromAsset } from "@/services/libs";
import { Collection, PlaylistAsset } from "@/services/providers/gemini/types";

export const toCollectionData = (collection: Collection): CollectionData => {
  return {
    id: collection.id,
    title: collection.name,
    campaignIds: collection.campaign_ids,
    entryIds: collection.entry_ids,
    assets: collection.assets?.map((asset) => assetToMediaContent(asset)),
  };
};

const assetToMediaContent = (asset: PlaylistAsset): MediaPlayerData => {
  return {
    type: "MediaPlayerData",
    media: {
      href: asset.full_asset,
      alt: "",
      mediaType: typeFromAsset(asset),
      thumbnail: asset.thumbnail,
    },
  };
};
