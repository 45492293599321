import { Agency, Pagination } from "@/services/providers/gemini/types";
import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { ParsedQuery } from "@/libs/QueryParams";
import { GET_AGENCY_BY_ID } from "@/services/providers/gemini/queries/agency_query";

export const getAgencyById = async (
  id: string,
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<{ agency: Agency | null; pagination: Pagination | null }> => {
  const results = await callGemini(
    {
      query: GET_AGENCY_BY_ID,
      variables: { id: parseInt(id), ...queryParams },
    },
    accessToken
  );
  if (results === null) {
    return { agency: null, pagination: null };
  } else {
    const { getAgencyWithFilteredCampaigns: agency } = results;
    return agency;
  }
};
