import {
  ComponentData,
  LabelCaptionData,
  PageTitleBlockData,
} from "@/renderers";
import { TEXT_CONSTS, formatNumber, pluralize } from "@/services/libs";
import { awardCountsToTags } from "@/services/libs/award_utils";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Agency, Pagination } from "@/services/providers/gemini/types";

const toTitleData = (
  { name, awardCount, shortlistCount }: Agency,
  pagination: Pagination | null
): PageTitleBlockData => {
  return {
    type: "PageTitleBlock",
    title: `${formatNumber(
      pagination?.totalRecords
    )} ${name.toUpperCase()} ${pluralize(
      pagination?.totalRecords || 0,
      TEXT_CONSTS.CAMPAIGN
    ).toUpperCase()}`,
    awardTags: awardCountsToTags(awardCount, shortlistCount),
  };
};

const toCampaignGrouping = (
  { campaigns }: Agency,
  pagination: Pagination | null
): ComponentData =>
  GeminiMapper.toComponentGroupingData({
    type: "ComponentGrouping",
    searchQuery: `content_type=campaigns`,
    componentsCollection: { items: campaigns },
    variant: "grid",
    totalRecords: pagination?.totalRecords,
    page: pagination?.currentPage,
    pageSize: pagination?.pageSize,
  });

export const AgencyShowMapper = {
  toTitleData,
  toCampaignGrouping,
};
