import { UserProfile } from "@/services/providers/gemini/types";
import { FormData } from "@/components/components.d";
import { COUNTRIES } from "@/services/account/account_show/countries";
import { ROLES } from "@/services/account/account_show/roles";
import { SENIORITIES } from "@/services/account/account_show/seniorities";

const toFormData = (myprofile: UserProfile): FormData => ({
  type: "Form",
  id: "account-form",
  sections: [
    {
      id: "profile-form",
      title: "Personal details",
      fields: [
        {
          id: "firstName",
          name: "First Name",
          type: "text",
          placeholder: "First Name",
          required: true,
          validations: "required",
        },
        {
          id: "lastName",
          name: "Last Name",
          type: "text",
          placeholder: "Last Name",
          required: true,
          validations: "required",
        },
        {
          id: "country",
          name: "Location",
          type: "select",
          placeholder: "Select a country",
          required: true,
          validations: "required",
          opts: {
            remote: {
              labelKey: "label",
              valueKey: "value",
              name: "countries",
            },
          },
        },
        {
          id: "country_code",
          name: "Country code",
          type: "hidden",
        },
        {
          id: "permit_marketing",
          name: "permit_marketing",
          type: "hidden",
        },
        {
          id: "telephone_number",
          name: "Telephone number (inc. country code)",
          type: "text",
          placeholder: "Telephone number (inc. country code)",
          required: true,
          validations: "telephone",
        },
      ],
    },
    {
      id: "company-form",
      title: "Company details",
      fields: [
        {
          id: "company_name",
          name: "Company Name",
          type: "text",
          placeholder: "Company Name",
          required: true,
          validations: "required",
        },
        {
          id: "location",
          name: "Company Location",
          type: "hidden",
          placeholder: "Company Location",
        },
        {
          id: "role",
          name: "How would you describe your role?",
          type: "select",
          placeholder: "Select",
          required: true,
          validations: "required",
          opts: {
            remote: {
              labelKey: "label",
              valueKey: "value",
              name: "roles",
            },
          },
        },
        {
          id: "seniority",
          name: "What is your seniority?",
          type: "select",
          placeholder: "Select",
          required: true,
          validations: "required",
          opts: {
            remote: {
              labelKey: "label",
              valueKey: "value",
              name: "seniorities",
            },
          },
        },
      ],
    },
  ],
  initialValues: {
    firstName: myprofile.firstName || "",
    lastName: myprofile.lastName || "",
    country: myprofile.country || "",
    telephone_number: myprofile.telephone_number || "",
    company_name: myprofile.company_name || "",
    role: myprofile.role || "",
    seniority: myprofile.seniority || "",
    location: myprofile.location || "",
    permit_marketing: myprofile.permit_marketing || false,
  },
  fieldDataSets: {
    countries: COUNTRIES.map((country) => ({
      label: country,
      value: country,
    })),
    roles: ROLES.map((role) => ({
      label: role,
      value: role,
    })),
    seniorities: SENIORITIES.map((seniority) => ({
      label: seniority,
      value: seniority,
    })),
  },
});

export const AccountShowMapper = { toFormData };
