import styled from "@emotion/styled";
import { PageTitle } from "@horizon/components";

const breakpoints = {
  default: "0px",
  small: "576px",
  medium: "768px",
  large: "992px",
  xlarge: "1200px",
};

const mediaQueries = {
  untilSmall: `@media screen and (max-width: calc(${breakpoints.small} - 1px))`,
  small: `@media screen and (min-width: ${breakpoints.default})`,
  untilMedium: `@media screen and (max-width: calc(${breakpoints.medium} - 1px))`,
  medium: `@media screen and (min-width: ${breakpoints.small})`,
  untilLarge: `@media screen and (max-width: calc(${breakpoints.large} - 1px))`,
  large: `@media screen and (min-width: ${breakpoints.medium})`,
  untilXLarge: `@media screen and (max-width: calc(${breakpoints.xlarge} - 1px))`,
  xLarge: `@media screen and (min-width: ${breakpoints.large})`,
  xxLarge: `@media screen and (min-width: ${breakpoints.xlarge})`,
};

const colors = {
  primary: "#2E2E2E",
  secondary: "#228181",
  accent1: "#50D0D0",
  accent2: "#D7B07D",
  accent3: "#EB9879",
  accent4: "#D1B002",
  accent5: "#2E6C92",
  white: "#FFFFFF",
  offwhite: "#F4F4F4",
  greylight: "#ECECEC",
  greymid: "#D2D2D2",
  greydark: "#707070",
  offblack: "#262626",
  black: "#000000",
  success: "#008810",
  error: "#EB0023",
  none: "transparent",
  initial: "initial",
};

export const spaceNumbers = {
  0: 0,
  1: 0.4,
  2: 0.8,
  3: 1.2,
  4: 1.6,
  5: 2.4,
  6: 3.2,
  7: 4.8,
  8: 7.2,
  9: 14.4,
};

const space = {
  0: `${spaceNumbers[0]}`,
  1: `${spaceNumbers[1]}rem`,
  2: `${spaceNumbers[2]}rem`,
  3: `${spaceNumbers[3]}rem`,
  4: `${spaceNumbers[4]}rem`,
  5: `${spaceNumbers[5]}rem`,
  6: `${spaceNumbers[6]}rem`,
  7: `${spaceNumbers[7]}rem`,
  8: `${spaceNumbers[8]}rem`,
  9: `${spaceNumbers[9]}rem`,
  none: `${spaceNumbers[0]}`, // 0px
  xsmall: `${spaceNumbers[1]}rem`, // 4px
  small: `${spaceNumbers[2]}rem`, // 8px
  medium: `${spaceNumbers[3]}rem`, // 12px
  base: `${spaceNumbers[4]}rem`, // 16px
  large: `${spaceNumbers[5]}rem`, // 24px
  xlarge: `${spaceNumbers[6]}rem`, // 32px
  xxlarge: `${spaceNumbers[7]}rem`, // 48px
  xxxlarge: `${spaceNumbers[8]}rem`, // 72px
  xxxxlarge: `${spaceNumbers[9]}rem`, // 144px
};

const marketoForm = {
  ctaBorderRadius: space.large,
  textLinkColor: "secondary",
  ctaTexDecorationHover: null,
  ctaPaddingIE: "16px 24px 10px",
  ctaColorHover: "white",
  ctaBgColorHover: "white",
};

export const MarketoTitle = styled(PageTitle)(() => ({
  marginTop: space.base,
  marginBottom: space.xxlarge,
}));

export const MarketoFormStyled = styled("div")(() => ({
  "#marketoForm .gs-marketo-form": {
    width: "79%",
    margin: "auto",
    marginBottom: space.xlarge,

    "input.mktoField, textarea, select": {
      borderRadius: "0",
      border: `none`,
      padding: space.small,
      paddingBottom: space.none,
      width: "100%",
      resize: "vertical",
      color: "black",
      font: "inherit",
      height: "auto",
      lineHeight: "inherit",
      boxShadow: "none",
      backgroundColor: colors["offwhite"],
      marginBottom: space.base,

      "&:focus": {
        borderBottom: `2px solid ${colors["greymid"]}`,
      },
    },

    "input.mktoField": {
      color: colors["black"],
      outline: "0",
      fontSize: "2.0rem",
      border: "0px",
      borderBottom: `1px solid ${colors["greymid"]}`,
    },

    ".mktoCheckboxList": {
      paddingTop: space.base,
      dispaly: "flex",
      flexDirection: "column",
      "input[type=checkbox]": {
        display: "inline-block",
        width: "13px",
        marginRight: "5px",
        verticalAlign: "top",
        cursor: "pointer",

        [mediaQueries.small]: {
          width: "13px",
        },
      },
      label: {
        cursor: "pointer",
        display: "inline-block",
        width: "93%",

        [mediaQueries.small]: {
          width: "95%",
        },
        [mediaQueries.large]: {
          width: "96%",
        },
      },
    },

    select: {
      borderBottom: `1px solid ${colors["greymid"]}`,
      paddingBottom: space.small,
    },

    "select option": {
      border: `1px solid ${colors.greydark}`,
      padding: `${space.base} ${space.medium} ${space.base} ${space.base}`,
      width: "100%",
      font: "inherit",
      fontSize: "2rem",
      boxShadow: "none",
      backgroundColor: "transparent",
      backgroundImage: "none",
      appearance: "none",
    },

    ".mktoFieldWrap": {
      marginBottom: `${space.large}`,

      "select + .mktoClear": {
        position: "relative",
      },
    },

    ".mktoError": {
      fontSize: "1.4rem",
      lineHeight: "inherit",
      marginTop: space.xsmall,
      color: `${colors.error}`,
      fontFamily: "inherit",

      ".mktoErrorArrowWrap, .mktoErrorMsg": {
        color: `${colors.error}`,
        lineHeight: "inherit",
        fontFamily: "inherit",
        float: "none",
      },
    },

    ".mktoRequiredField .mktoAsterix": {
      display: "block",
    },

    ".mktoAsterix": {
      float: "right",
      color: `${colors.error}`,
      paddingLeft: space.xsmall,
      display: "none",
    },

    ".mktoLabel": {
      width: "auto",
      display: "inline-block",
      letterSpacing: ".5px",
      marginBottom: space.xsmall,
      paddingBottom: "0",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: "normal",
      color: `${colors.black}`,
      fontFamily: "inherit",
    },

    ".mktoButton": {
      display: "inline-block",
      verticalAlign: "middle",
      font: "inherit",
      textalign: "center",
      margin: 0,
      marginTop: space.medium,
      cursor: "pointer",
      transition: "all .3s ease-in-out",
      border: "none",
      fontSize: "inherit",
      color: colors.white,
      textDecoration: "none",
      padding: `${space.medium} ${space.large}`,
      fontWeight: "bold",
      backgroundImage: "none",
      backgroundColor: `${colors.secondary}`,
      width: "100%",
      height: "auto",
      lineHeight: "inherit",
      borderRadius: marketoForm.ctaBorderRadius,

      "@media screen and (min-width: 768px)": {
        width: "auto",
        textAlign: "center",
      },

      "&:hover": {
        outlineWidth: "0.3rem",
        outlineStyle: "solid",
        outlineColor: colors.secondary,
        outlineOffset: "0.1rem",
      },

      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        padding: marketoForm.ctaPaddingIE,
      },
    },

    ".mktoHtmlText a": {
      color: colors.secondary,
    },
  },
}));

export const resetFormCss = (form: { getFormElem: () => any[] }) => {
  const formEl = form.getFormElem()[0];
  const stylesBase = document.getElementById("mktoForms2BaseStyle");
  const stylesTheme = document.getElementById("mktoForms2ThemeStyle");

  if (stylesTheme) {
    const inlineMarketoStyle = stylesTheme.nextSibling;

    if (
      inlineMarketoStyle &&
      inlineMarketoStyle.textContent &&
      inlineMarketoStyle.textContent.indexOf(`mktoForm_`) >= 0
    ) {
      // @ts-ignore
      inlineMarketoStyle.outerHTML = "";
    }

    const inFormStyles = formEl.getElementsByTagName("style");
    if (inFormStyles.length) {
      for (let i = 0; i < inFormStyles.length; i += 1) {
        inFormStyles[i].outerHTML = "";
      }
    }

    const styledEls = Array.from(formEl.querySelectorAll("[style]")).concat(
      formEl
    );
    styledEls.forEach((el: any) => el.removeAttribute("style"));
    stylesTheme.outerHTML = "";
  }
  if (stylesBase) {
    stylesBase.outerHTML = "";
  }
};
