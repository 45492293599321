import { TextComponent } from "@/components";
import { Api } from "@/libs/api";
import { CollectionData, SaveCollectionButtonData } from "@/renderers";
import {
  Button_v2,
  Modal_v2,
  Box,
  TextInput,
  Selector,
  LoadingSpinner,
} from "@horizon/components";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const CollectionsModalComponent = ({
  saveCollectionButtonData,
  saveModalState,
  updateModalState,
}: {
  saveCollectionButtonData: SaveCollectionButtonData;
  saveModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  updateModalState: [boolean, Dispatch<SetStateAction<boolean>>];
}) => {
  const { contentId, contentType } = saveCollectionButtonData;
  const [isSaveModalOpen, setSaveModalOpen] = saveModalState;
  const [isCreateModalOpen, setCreateModalOpen] = updateModalState;
  return (
    <>
      <SaveModal
        isOpen={isSaveModalOpen}
        setOpen={setSaveModalOpen}
        setCreateModalOpen={setCreateModalOpen}
        saveCollectionButtonData={saveCollectionButtonData}
      />
      <CreateModal
        isOpen={isCreateModalOpen}
        setOpen={setCreateModalOpen}
        contentId={contentId}
        contentType={contentType}
      />
    </>
  );
};

const CreateModal = ({
  isOpen,
  setOpen,
  contentId,
  contentType,
}: {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  contentId: string;
  contentType: string;
}): JSX.Element => {
  const [title, setTitle] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const inputValue = e.currentTarget as unknown as HTMLInputElement;
    setTitle(inputValue.value);
  };
  useEffect(() => {
    if (isOpen) {
      setTitle("");
    }
  }, [isOpen, setTitle]);

  return (
    <Modal_v2
      isOpen={isOpen}
      closeModal={() => setOpen(false)}
      title={{
        as: "h4",
        copy: "Create a collection",
      }}
      ctas={[
        {
          variant: "primary",
          children: "Create Collection",
          disabled: title.length === 0,
          loading: isSubmitting,
          onClick: async () => {
            await handleOnCreateCollection(
              setSubmitting,
              setOpen,
              title,
              contentId,
              contentType
            );
          },
        },
        {
          variant: "secondary",
          children: "Cancel",
          disabled: isSubmitting,
          onClick: () => {
            setOpen(false);
          },
        },
      ]}
    >
      {isSubmitting && (
        <LoadingSpinner
          description="Saving your new collection"
          title="Saving"
        />
      )}
      {!isSubmitting && (
        <Box padding="medium">
          <TextInput
            inputType="text"
            name="CollectionName"
            label="Collection Name"
            onChangeCallback={handleChange}
            value={title}
          />
        </Box>
      )}
    </Modal_v2>
  );
};

const SaveModal = ({
  isOpen,
  setOpen,
  setCreateModalOpen,
  saveCollectionButtonData,
}: {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  setCreateModalOpen: (isOpen: boolean) => void;
  saveCollectionButtonData: SaveCollectionButtonData;
}): JSX.Element => {
  const { collections, contentId, contentType } = saveCollectionButtonData;
  const [selectorOptions, setSelectorOptions] = useState<SelectorOption[]>();

  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSelectorOptions(
        getSelectorOptionsFromCollection(collections, contentType, contentId)
      );
    }
  }, [isOpen, collections, contentType, contentId]);

  return (
    <Modal_v2
      isOpen={isOpen}
      closeModal={() => {
        setSubmitting(false);
        setOpen(false);
      }}
      title={{
        as: "h4",
        copy: "Save",
      }}
      ctas={[
        {
          variant: "primary",
          children: "Save",
          loading: isSubmitting,
          onClick: async () => {
            await handleOnUpdateCollection(
              setSubmitting,
              saveCollectionButtonData,
              selectorOptions
            );
          },
        },
        {
          variant: "secondary",
          children: "Cancel",
          disabled: isSubmitting,
          onClick: () => {
            setSubmitting(false);
            setOpen(false);
          },
        },
      ]}
    >
      <>
        <TextComponent
          content="Existing collections"
          type="Text"
          size="xsmall"
        />
        {isSubmitting && (
          <LoadingSpinner
            description="Saving your collections"
            title="Saving"
          />
        )}
        {!isSubmitting && (
          <>
            <Box padding="medium">
              {buildSelectors(selectorOptions, setSelectorOptions)}
            </Box>
            <Button_v2
              variant="ghost"
              icon={{ name: "Plus", position: "end" }}
              size="small"
              onClick={() => {
                setOpen(false);
                setCreateModalOpen(true);
              }}
            >
              Create new collection
            </Button_v2>
          </>
        )}
      </>
    </Modal_v2>
  );
};

const handleOnCreateCollection = async (
  setSubmitting: (isSubmitting: boolean) => void,
  setOpen: (isOpen: boolean) => void,
  title: string,
  contentId: string,
  contentType: string
) => {
  setSubmitting(true);
  const result = await Api.createCollection({ playlistName: title });
  const newCollection = addItemToCollection(result, contentType, contentId);
  if (newCollection) {
    const { id, campaignIds, entryIds, title } = newCollection;
    await Api.updateCollection(
      { id, campaignIds, entryIds, name: title },
      () => {}
    );
  }
  setSubmitting(false);
  setOpen(false);
  location.reload();
};

const handleOnUpdateCollection = async (
  setSubmitting: (isSubmitting: boolean) => void,
  saveCollectionButtonData: SaveCollectionButtonData,
  selectorOptions?: SelectorOption[]
) => {
  setSubmitting(true);
  const changedCollections = getChangedCollections(
    saveCollectionButtonData,
    selectorOptions
  );
  await Promise.all(
    changedCollections?.map(({ id, campaignIds, entryIds, title }) => {
      return Api.updateCollection(
        { id, campaignIds, entryIds, name: title },
        () => {}
      );
    })
  );
  location.reload();
};

const isContentInCollection = (
  contentType: string,
  contentId: string,
  collection: CollectionData
) => {
  if (contentType === "entry") {
    return collection.entryIds.includes(Number(contentId));
  } else if (contentType === "campaign") {
    return collection.campaignIds.includes(Number(contentId));
  }
  return false;
};

type SelectorOption = { label: string; name: number; selected: boolean };

const buildSelectors = (
  selectorOptions: SelectorOption[] | undefined,
  setSelectorOptions: any
) => {
  return selectorOptions?.map((option) => (
    <Selector
      inputType="checkbox"
      name={option.name}
      label={option.label}
      checked={option.selected}
      key={option.name}
      onChangeCallback={(event: any) => {
        const selected = event.currentTarget.checked;
        setSelectorOptions(
          selectorOptions?.map((option) => {
            if (option.name === parseInt(event.target.name, 10)) {
              return {
                ...option,
                selected,
              };
            }
            return option;
          })
        );
      }}
    />
  ));
};

const getChangedCollections = (
  saveCollectionButtonData: SaveCollectionButtonData,
  selectorOptions?: SelectorOption[]
): CollectionData[] => {
  const { collections, contentId, contentType } = saveCollectionButtonData;
  return collections
    ?.map((collection) => {
      if (
        isContentInCollection(contentType, contentId, collection) &&
        selectorOptions?.find(
          (option: SelectorOption) =>
            option.name === collection.id && !option.selected
        )
      ) {
        return removeItemFromCollection(collection, contentType, contentId);
      }
      if (
        !isContentInCollection(contentType, contentId, collection) &&
        selectorOptions?.find(
          (option: SelectorOption) =>
            option.name === collection.id && option.selected
        )
      ) {
        return addItemToCollection(collection, contentType, contentId);
      }
    })
    .filter(Boolean) as CollectionData[];
};

const addItemToCollection = (
  collection: CollectionData | undefined,
  contentType: string,
  contentId: string
) => {
  if (!collection) {
    return collection;
  }
  if (contentType === "entry") {
    return {
      ...collection,
      entryIds: [...collection.entryIds, Number(contentId)],
    };
  } else if (contentType === "campaign") {
    return {
      ...collection,
      campaignIds: [...collection.campaignIds, Number(contentId)],
    };
  }
  return collection;
};

const removeItemFromCollection = (
  collection: CollectionData,
  contentType: string,
  contentId: string
) => {
  if (contentType === "entry") {
    return {
      ...collection,
      entryIds: collection.entryIds.filter((id) => id !== Number(contentId)),
    };
  } else if (contentType === "campaign") {
    return {
      ...collection,
      campaignIds: collection.campaignIds.filter(
        (id) => id !== Number(contentId)
      ),
    };
  }
  return collection;
};

const getSelectorOptionsFromCollection = (
  collections: CollectionData[] | undefined,
  contentType: string,
  contentId: string
): SelectorOption[] => {
  return (
    collections?.map((collection) => ({
      label: collection.title,
      name: collection.id,
      selected: isContentInCollection(contentType, contentId, collection),
    })) || []
  );
};
