import { UploadFileData } from "@/renderers";
import { Text, HeroTitle, Button_v2 as Button } from "@horizon/components";

export const UploadFileComponent = ({
  title,
  copy,
  ctaLabel,
  media,
}: UploadFileData) => {
  return (
    <>
      {title && (
        <HeroTitle my="medium" size="medium">
          {title}
        </HeroTitle>
      )}

      {copy && (
        <Text my="medium" size="xlarge">
          {copy}
        </Text>
      )}

      <Button
        variant="primary"
        icon={{
          name: "Download",
          position: "end",
        }}
        href={media.href}
      >
        {ctaLabel}
      </Button>
    </>
  );
};
