import { SearchPageTitleData } from "@/components/components.d";
import { Container } from "@horizon/components";
import { Typography } from "@lions/ui-components";

export const SearchPageTitle = ({ content }: SearchPageTitleData) => {
  return content ? (
    <Container data-panda-theme="intelligence">
      <Container>
        <Typography
          size="heading-large"
          className="m_0_auto"
          as="h3"
          style={{
            color: "black",
            fontWeight: "normal",
            marginTop: "-28px",
            marginBottom: "10px",
          }}
        >
          {content}
        </Typography>
      </Container>
    </Container>
  ) : null;
};
