import {
  Document,
  Block,
  Inline,
  Text,
  Mark,
} from "@contentful/rich-text-types";
import { TextComponentData } from "@/renderers";

const jsonToTextComponents = (json: Document): TextComponentData[] => {
  const nodes = json.content
    .flatMap(nodeToTextComponent)
    .filter((node) => node);
  return nodes as TextComponentData[];
};

const nodeToTextComponent = (
  node: Block | Inline | Text
): TextComponentData | TextComponentData[] | null => {
  switch (node.nodeType) {
    case "paragraph":
      return toParagraph(node.content);
    case "heading-1":
      return toHeroTitle(node.content, "medium", "h2");
    case "heading-2":
      return toHeroTitle(node.content, "small", "h3");
    case "heading-3":
      return toPageTitle(node.content, "medium", "h4");
    case "heading-4":
      return toPageTitle(node.content, "small", "h5");
    case "heading-5":
      return toPageTitle(node.content, "xsmall", "h6");
    case "heading-6":
      return toPageTitle(node.content, "xxsmall", "h6");
    case "hr":
      return toLineBreak();
    case "unordered-list":
      return toUnorderedList(node.content);
    case "ordered-list":
      return toOrderedList(node.content);
    case "list-item":
      return toListItem(node.content);
  }
  return null;
};

const toHeroTitle = (
  content: Array<Block | Inline | Text>,
  size: string,
  as: string
): TextComponentData[] => {
  return content
    .map((node) => {
      switch (node.nodeType) {
        case "text":
          return {
            as,
            type: "TextComponent",
            element: "heroTitle",
            size,
            content: node.value,
          };
      }
    })
    .filter((node) => node) as TextComponentData[];
};

const toLineBreak = (): TextComponentData => ({
  type: "TextComponent",
  element: "lineBreak",
  content: "",
  marks: [],
});

const toPageTitle = (
  content: Array<Block | Inline | Text>,
  size: string,
  as: string = "p"
): TextComponentData[] => {
  return content
    .map((node) => {
      switch (node.nodeType) {
        case "text":
          return {
            as,
            type: "TextComponent",
            element: "pageTitle",
            size,
            content: node.value,
          };
      }
    })
    .filter((node) => node) as TextComponentData[];
};

const toParagraph = (
  content: Array<Block | Inline | Text>
): TextComponentData => ({
  type: "TextComponent",
  element: "inline",
  marks: [],
  content: content
    .map((node) => {
      switch (node.nodeType) {
        case "text":
          return splitToNodesOnCustomMark(node).map((subNode) => ({
            type: "TextComponent",
            element: "text",
            size: "small",
            content: subNode.value,
            marks: subNode.marks.map((mark) => mark.type),
          }));

        case "hyperlink":
          return {
            type: "TextComponent",
            element: "hyperlink",
            size: "small",
            content: node.content
              .map((subNode) => ("value" in subNode ? subNode.value : ""))
              .join(" "),
            marks: [],
            href: node.data.uri,
          };
      }
    })
    .flat()
    .filter((node) => node) as TextComponentData[],
});

/**
 * Splits a text node into multiple text nodes based on the <mark> tag.
 * Odd-indexed nodes will contain the text within <mark> tags.
 *
 * Contentful's editor does not support custom marks, so we have to parse them manually.
 * See tests for examples of behavior.
 *
 * @param {Text} node - The text node to split.
 * @return {Text[]} - An array of new text nodes with <mark> applied to the appropriate nodes.
 */
const splitToNodesOnCustomMark = (node: Text): Text[] => {
  const markRegex = /<mark>(.*?)<\/mark>/g;
  const nodes = node.value.split(markRegex);
  return nodes.map((text, index) => ({
    ...node,
    value: text,
    marks:
      index % 2 === 1 ? [...node.marks, { type: "mark" } as Mark] : node.marks,
  }));
};

const toUnorderedList = (
  content: Array<Block | Inline | Text>
): TextComponentData =>
  ({
    type: "TextComponent",
    element: "unorderdList",
    content: content.map(nodeToTextComponent),
  } as TextComponentData);

const toListItem = (content: Array<Block | Inline | Text>): TextComponentData =>
  ({
    type: "TextComponent",
    element: "listItem",
    content: content.map(nodeToTextComponent),
  } as TextComponentData);

const toOrderedList = (
  content: Array<Block | Inline | Text>
): TextComponentData =>
  ({
    type: "TextComponent",
    element: "orderedList",
    content: content.map(nodeToTextComponent),
  } as TextComponentData);

export const RichTextMapper = {
  jsonToTextComponents,
  parseCustomMarks: splitToNodesOnCustomMark,
};
