import { SessionStore, SessionStorePayload } from "@auth0/nextjs-auth0";
import UMASessionStore from "./uma";

class Store implements SessionStore {
  private store: UMASessionStore;

  constructor() {
    this.store = new UMASessionStore();
  }

  get(id: string) {
    return this.store.get(id);
  }

  async set(id: string, val: SessionStorePayload) {
    await this.store.set(id, val);
  }

  async delete(id: string) {
    await this.store.delete(id);
  }
}

export default Store;
