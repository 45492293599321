import { PAGINATION_FRAGMENT } from "@/services/providers/gemini/queries/shared_fragments/pagination_fragment";
import { gql } from "graphql-request";

export const GET_TALKS_BY_ID = gql`
  query ($id: Int) {
    getTalksByIds(ids: [$id]) {
      access
      captionsUrl
      description
      endsAt
      host {
        name
      }
      id
      thumbnail: imageUrl
      slug
      speakers {
        biography
        id
        avatar: imageUrl
        name
        title
      }
      stage
      startsAt
      title
      video: videoUrl
      year
      requiredUserActions
    }
  }
`;

export const GET_TALKS_BY_EXTERNAL_IDS = gql`
  query getTalksByIds($externalIds: [Int]!) {
    getTalksByExternalIds(externalIds: $externalIds) {
      title
      startsAt
      endsAt
      slug
      id
      videoUrl
      description
      host {
        name
      }
      access
      captionsUrl
      stage
      year
      imageUrl
      externalId
      speakers {
        name
        biography
        imageUrl
        title
      }
    }
  }
`;

export const GET_FILTERED_TALKS = gql`
  query (
    $ids: [Int]
    $search_text: String
    $theme: [String]
    $year: [Int]
    $festival_name: [String]
    $page: Int
    $sort: String
    $tags: [String]
  ) {
    filteredTalks(
      filters: {
        ids: $ids
        search_text: $search_text
        theme: $theme
        year: $year
        festival_name: $festival_name
        page: $page
        sort: $sort
        tags: $tags
      }
    ) {
      talks {
        access
        captionsUrl
        description
        endsAt
        host {
          name
        }
        id
        thumbnail: imageUrl
        slug
        speakers {
          biography
          id
          avatar: imageUrl
          name
          title
        }
        stage
        startsAt
        title
        video: videoUrl
        year
        requiredUserActions
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;
