import { gql } from "graphql-request";

const YOUNG_ENTRY_FRAGMENT = gql`
  fragment YoungEntryFragment on YoungEntry {
    id
    year
    title
    award
    youngEntryAward {
      level
      levelLabel
    }
    shortlisted
    asset {
      id
      thumbnailUrl
      fullUrl
      type
      label
    }
    assets {
      id
      thumbnailUrl
      fullUrl
      type
      label
    }
    country
    slug
    entryTypeName
    synopses {
      question
      questionId
      answer
      displayOrder
    }
    credits {
      name
      company
      role
    }
  }
`;

export const YOUNG_ENTRY_QUERY = gql`
  query ($ids: [Int]!) {
    getYoungEntriesByIds(ids: $ids) {
      ...YoungEntryFragment
    }
  }
  ${YOUNG_ENTRY_FRAGMENT}
`;

export const GET_FILTERED_YOUNG_ENTRIES = gql`
  query filteredYounEntries(
    $festivalName: String!
    $year: Int!
    $entryTypeId: Int!
  ) {
    filteredYoungEntries(
      filters: {
        festivalName: $festivalName
        year: $year
        entryTypeId: $entryTypeId
      }
    ) {
      ...YoungEntryFragment
    }
  }
  ${YOUNG_ENTRY_FRAGMENT}
`;
