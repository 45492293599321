import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { Api } from "@/libs/api";
import {
  PubSubProvider,
  SearchEvents,
  SearchQueryResponse,
} from "@/libs/pubSub";
import { GeminiMapper } from "@/services/providers/gemini/mappers";

export const searchContent = ({
  parsedSearchQuery,
}: {
  parsedSearchQuery?: ParsedQuery;
}) => {
  Api.searchContent({ parsedSearchQuery }).then((results) => {
    const { content, pagination } = results;
    const cards = GeminiMapper.mapComponentsToCards(
      QueryParams.queryToString(parsedSearchQuery),
      {
        items: content,
      }
    );

    PubSubProvider.pubishEvent<SearchQueryResponse>(
      SearchEvents.SEARCH_RESULTS,
      {
        nextResults: cards || [],
        pagination: GeminiMapper.toPaginationData(pagination),
      }
    );
  });
};
