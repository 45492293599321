import TagManager from "react-gtm-module";
import { AuthenticatedUser } from "@/libs/authentication/user";
import { ClientParameters } from "@/libs/ClientParameterProvider";

// Event shape is documented here:
// https://developers.google.com/tag-platform/devguides/events#gtag.js

type DataLayerEventProps = {
  event: string;
} & any;

export const trackEvent = (
  params: ClientParameters,
  eventProps: DataLayerEventProps,
  user?: AuthenticatedUser
) => {
  const dataLayer = {
    dataLayer: { ...eventProps, user_id: user?.metaData?.uma_id },
  };
  if (params.GTM_TAG_ID) {
    TagManager.dataLayer(dataLayer);
  } else {
    // console.log("Tagmanager push", dataLayer);
  }
};

export const init = (params: ClientParameters, user?: AuthenticatedUser) => {
  const userData = getAnalyticsUserData(user);
  const initialTagManagerArgs = {
    gtmId: params.GTM_TAG_ID || "",
    dataLayer: { ...userData },
  };
  if (params.GTM_TAG_ID) {
    TagManager.initialize(initialTagManagerArgs);
  } else {
    // console.log("Tagmanager Init", initialTagManagerArgs);
  }
};

const getAnalyticsUserData = (user?: AuthenticatedUser) => {
  if (user && Object.entries(user).length !== 0) {
    const { metaData, products, ...rest } = user;
    const UserId = metaData?.uma_id;
    return {
      userId: UserId,
      subscription_type: products?.map(({ productType }) => productType),
    };
  }
  return {};
};
