import { gql } from "graphql-request";

export const LINK_SEGMENT = gql`
  {
    link
    label
    buttonVariant
    iconName
    iconPosition
  }
`;

const MEDIA_SEGMENT = gql`
  {
    fileName
    width
    height
    size
    title
    description
    url
    contentType
    publishedAt: sys {
      publishedAt
    }
  }
`;

const TAG_SEGMENT = gql`
  {
    copy
    variant
    component
  }
`;

export const CARD_FRAGMENT = gql`fragment CardFragment on Card {
  variant
  link ${LINK_SEGMENT}
  media ${MEDIA_SEGMENT}
  subText
  title
  name
  superText
  textPosition
  caption
  aspectRatio
  mediaTagsCollection(limit:1, preview: $preview){
    items ${TAG_SEGMENT}
  }
  primaryTagsCollection(limit:5, preview: $preview) {
    items ${TAG_SEGMENT}
  }
  secondaryTagsCollection(limit:5, preview: $preview) {
    items ${TAG_SEGMENT}
  }
}`;

const FEATURE_PANEL_FRAGMENT = gql`fragment FeaturePanelFragment on FeaturePanel {
  title
  copy
  variant
  link ${LINK_SEGMENT}
  media ${MEDIA_SEGMENT}
}`;

const RANKINGS_TABLE_FRAGMENT = gql`
  fragment RankingsTableFragment on RankingsTable {
    title
    awardType
    awardCategory
    year
    variant
    showFilters
  }
`;

const COMPONENT_GROUPING_FRAGMENT = gql`
  fragment ComponentGroupingFragment on ComponentGrouping {
    title
    variant
    instanceName
    searchQuery
    componentsPerRow
    maxRecords
    showFilters
    carouselControlPosition
    autoplayCarousel
    carouselItemsIndicator
    link ${LINK_SEGMENT}
    componentsCollection(limit: 24, preview: $preview) {
      items {
        type: __typename
        ... on Card {
          ...CardFragment
        }
        ... on Avatar {
          image {
            url
          }
          name
          size
          subText
          tag {
            copy
            variant
            component
          }
        }
      }
    }
  }
`;

const ENTRY_TYPE_LISTING_COMPONENT_FRAGEMENT = gql`
  fragment EntryTypeListingComponentFragment on EntryTypeListingComponent {
    festivalName
    festivalYear
    sponsorBanner: sponsorBannerCollection {
      items {
        itemName
        text
        media ${MEDIA_SEGMENT}
      }
    }
  }
`;

const RICH_TEXT_BLOCK_FRAGMENT = gql`
  fragment RichTextBlockFragment on TextBlock {
    heading
    richTextCopy {
      json
    }
    isFeatured
    fullWidth
  }
`;

const ACCORDION_FRAGMENT = gql`
  fragment AccordionFragment on Accordion {
    title
    link {
      link
      label
    }
    contentCollection(limit: 10, preview: $preview) {
      items {
        type: __typename
        ... on TextBlock {
          ...RichTextBlockFragment
        }
      }
    }
  }
`;

const UPLOAD_FILE_FRAGMENT = gql`
  fragment UploadFileFragment on UploadFile {
    title
    copy
    ctaLabel
    file {
      fileName
      width
      height
      size
      title
      description
      url
      contentType
    }
  }
`;

export const PAGE_TITLE_BLOCK_FRAGMENT = gql`
  fragment PageTitleBlockFragment on PageTitleBlock {
    title
    superText
    subText
    quote
    links: linksCollection(limit: 2, preview: $preview) {
      items {
        link
        label
        iconName
        iconPosition
      }
    }
    awardTags: awardTagsCollection(limit: 10, preview: $preview) {
      items {
        copy
        variant
        component
      }
    }
    profileCard {
      ...CardFragment
    }
  }
`;

const MARKETO_FORM_FRAGMENT = gql`
  fragment MarketoFormFragment on MarketoForm {
    title
    formId
    redirectPage {
      slug
    }
  }
`;

const QUOTE_FRAGMENT = gql`
  fragment QuoteFragment on Quote {
    copy
    name
    jobTitle
    company
    variant
    image ${MEDIA_SEGMENT}
  }
`;

const LIVE_PLAYER_FRAGMENT = gql`
  fragment LivePlayerFragment on LivePlayer {
    liveEventDate
    liveStreamUrl
    loopVideoStream
    startTimeOffsetSeconds
    requiredUserAction
  }
`;

const EVENT_SCHEDULE_FRAGMENT = gql`
  fragment EventScheduleFragment on EventSchedule {
    title
  }
`;

export const PAYWALL_COMPONENT_FRAGMENT = gql`
  fragment PaywallComponentFragment on PaywallComponent {
    instanceName
    title
    copy {
      json
    }
    ctasCollection {
      items ${LINK_SEGMENT}
    }
  }
`;

const MEDIA_PLAYER_FRAGMENT = gql`
  fragment MediaPlayerFragment on MediaPlayer {
    media {
      fileName
      width
      height
      size
      title
      description
      url
      contentType
    }
    thumbnail {
      fileName
      width
      height
      size
      title
      description
      url
      contentType
    }
    captionTop
    captionBottom
  }
`;

export const HERO_BLOCK_FRAGMENT = gql`
  fragment HeroBlockFragment on HeroBlock {
    title
    subtitle
    link {
      link
      label
    }
    media {
      fileName
      width
      height
      size
      title
      description
      url
      contentType
    }
    headlineMedia {
      fileName
      width
      height
      size
      title
      description
      url
      contentType
    }
    bannerMessage
    bannerMedia {
      fileName
      width
      height
      size
      title
      description
      url
      contentType
    }
    variant
  }
`;

const GALLERY_BLOCK_FRAGMENT = gql`
   fragment GalleryBlockFragment on GalleryBlock {
    imagesCollection {
      items ${MEDIA_SEGMENT}
    }
    captionTop
    captionBottom
   }
 `;

const SECTION_COMPONENT_FRAGMENT = gql`
  fragment SectionComponentFragment on Section {
    title
    subtitle
    backgroundMode
    darkModeBackgroundColour
    content: contentCollection(limit: 30, preview: $preview) {
      items {
        type: __typename
        ... on FeaturePanel {
          ...FeaturePanelFragment
        }
        ... on ComponentGrouping {
          ...ComponentGroupingFragment
        }
        ... on EntryTypeListingComponent {
          ...EntryTypeListingComponentFragment
        }
        ... on TextBlock {
          ...RichTextBlockFragment
        }
        ... on HeroBlock {
          ...HeroBlockFragment
        }
        ... on RankingsTable {
          ...RankingsTableFragment
        }
        ... on MediaPlayer {
          ...MediaPlayerFragment
        }
        ... on Accordion {
          ...AccordionFragment
        }
        ... on UploadFile {
          ...UploadFileFragment
        }
        ... on PageTitleBlock {
          ...PageTitleBlockFragment
        }
        ... on MarketoForm {
          ...MarketoFormFragment
        }
        ... on Quote {
          ...QuoteFragment
        }
        ... on LivePlayer {
          ...LivePlayerFragment
        }
        ... on EventSchedule {
          ...EventScheduleFragment
        }
        ... on GalleryBlock {
          ...GalleryBlockFragment
        }
      }
    }
  }
`;

export const SECTION_BY_ID = gql`
  query ($preview: Boolean, $id: String) {
    sectionCollection(
      preview: $preview
      where: { sys: { id: $id } }
      limit: 1
    ) {
      items {
        type: __typename
        ... on Section {
          ...SectionComponentFragment
        }
      }
    }
  }
  ${CARD_FRAGMENT}
  ${FEATURE_PANEL_FRAGMENT}
  ${COMPONENT_GROUPING_FRAGMENT}
  ${ENTRY_TYPE_LISTING_COMPONENT_FRAGEMENT}
  ${SECTION_COMPONENT_FRAGMENT}
  ${RICH_TEXT_BLOCK_FRAGMENT}
  ${HERO_BLOCK_FRAGMENT}
  ${RANKINGS_TABLE_FRAGMENT}
  ${MEDIA_PLAYER_FRAGMENT}
  ${ACCORDION_FRAGMENT}
  ${UPLOAD_FILE_FRAGMENT}
  ${PAGE_TITLE_BLOCK_FRAGMENT}
  ${MARKETO_FORM_FRAGMENT}
  ${QUOTE_FRAGMENT}
  ${LIVE_PLAYER_FRAGMENT}
  ${EVENT_SCHEDULE_FRAGMENT}
  ${GALLERY_BLOCK_FRAGMENT}
`;
