import { ParsedQuery } from "@/libs/QueryParams";
import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_AWARD_RESULTS } from "@/services/providers/gemini/queries/award_results_query";
import { AwardCategory } from "@/services/providers/gemini/types/award_category";

export const getAwardResults = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<AwardCategory> => {
  const variables = {
    year: queryParams?.year?.[0]?.toString(),
    festival_name: getFestivalName(queryParams),
    entry_type_id: queryParams?.entry_type_id?.[0]?.toString(),
  };
  const { getAwardCategories: options } = (await callGemini(
    { query: GET_AWARD_RESULTS, variables: variables },
    accessToken
  )) || {
    getAwardCategories: { shortlisted: [], winners: [], festivalEntryType: {} },
  };
  return options;
};

const getFestivalName = (queryParams: ParsedQuery): string => {
  const festivalName = queryParams?.festival_name?.[0] || "";
  return festivalName.replace("-", " ");
};
