import { logger } from "@/libs/Logger";
import { GraphQLQuery } from "@/services/libs/grapqhl";

export const callGemini = async (query: GraphQLQuery, accessToken?: string) => {
  const URL = (process.env && process.env.GEMINI_GRAPHQL_ENDPOINT) || "";
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
    body: JSON.stringify(query),
  };

  try {
    const result = await fetch(URL, options);
    if (result.status != 200) {
      logger.error(
        `Received status code not 200 when calling Gemini: ${result.status} for query: ${query}`
      );
      return null;
    }
    const { data, errors } = await result.json();
    if (result.ok && errors == undefined) {
      return data;
    } else {
      logger.error(errors?.map((e: any) => e.message).join("\n") ?? "unknown");
      return null;
    }
  } catch (err) {
    logger.info(err);
  }
};

export const callRestGeminiGet = async (path: string, accessToken?: string) => {
  const options: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
  return callRestGemini(path, options);
};

export const callRestGeminiPut = async (
  path: string,
  payload: any,
  accessToken?: string
) => {
  const options: RequestInit = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
    body: JSON.stringify(payload),
  };
  return callRestGemini(path, options);
};

export const callRestGeminiPost = async (
  path: string,
  payload: any,
  accessToken?: string
) => {
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
    body: JSON.stringify(payload),
  };
  return callRestGemini(path, options);
};

export const callRestGeminiDelete = async (
  path: string,
  accessToken?: string
) => {
  const options: RequestInit = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
  return callRestGemini(path, options);
};

const callRestGemini = async (path: string, options: RequestInit) => {
  const BASE_URL = (process.env && process.env.GEMINI_REST_ENDPOINT) || "";
  const URL = BASE_URL + path;
  try {
    const result = await fetch(URL, options);
    logger.info(result.status);
    if (result.status < 200 || result.status >= 300) {
      logger.error(
        `Received status code not 200 when calling Gemini: ${result.status} for path: ${path}`
      );
      return null;
    }
    const json = await result.json();
    const { data, message, errors, error } = json;
    if (result.ok && errors == undefined && error == undefined) {
      return data || message;
    } else if (errors) {
      logger.error(errors?.map((e: any) => e.message).join("\n") ?? "unknown");
      return null;
    } else if (error) {
      logger.error(error);
      return null;
    }
  } catch (err) {
    logger.info(err);
    return null;
  }
};
