import { Caption } from "@horizon/components";
import { LabelCaptionData } from "@/renderers";
import { buildKey } from "./libs";

export const LabelCaptionComponent = (
  { content, id }: LabelCaptionData,
  index?: number
) => {
  return (
    <Caption id={id} key={buildKey("label", index)}>
      {content}
    </Caption>
  );
};
