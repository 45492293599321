import React, { useEffect, useState } from "react";
import { MediaPlayerData } from "@/renderers";
import { Image, Zoom } from "@horizon/components";
import { VideoComponent, PDFViewerComponent } from "@/components";
import { MediaUtils, ZoomUrlOptions } from "./media_utils";

export const MediaPlayerComponent = (mediaPlayerData: MediaPlayerData) => {
  const { media, id } = mediaPlayerData;
  const [zoomUrl, setZoomUrl] = useState<string | ZoomUrlOptions | null>(null);

  useEffect(() => {
    if (media.mediaType === "image/zoom") {
      const fetchZoomUrl = async () => {
        const url = await MediaUtils.buildZoomSettings(media.href);
        setZoomUrl(url);
      };
      fetchZoomUrl();
    }
  }, [media]);

  switch (media.mediaType) {
    case "audio/mp3":
    case "video/mp4":
      return <VideoComponent mediaPlayerData={mediaPlayerData} />;
    case "image/png":
    case "image/jpeg":
      return <Image id={id} alt={media.alt} src={media.href} />;
    case "application/pdf":
      return <PDFViewerComponent mediaPlayerData={mediaPlayerData} />;
    case "image/zoom":
      return zoomUrl ? <Zoom url={zoomUrl} id={id} /> : null;
    default:
      return null;
  }
};
