import { CollectionData } from "@/renderers";
import { callClientApi, callDeleteApi } from "../client_api_caller";

export const updateCollection = async (
  payload: {
    id: number;
    campaignIds: number[];
    entryIds: number[];
    name: string;
  },
  callback: () => void
) => {
  const result = await callClientApi(
    `/api/collections/update/${payload.id}`,
    payload
  );
  callback();
};

export const deleteCollection = async (id: number, callback: Function) => {
  await callDeleteApi(`/api/collections/delete/${id}`);
  callback();
};

export const shareCollection = async (id: number) => {
  await callClientApi(`/api/collections/share/${id}`, {});
};

export const createCollection = async (payload: {
  playlistName: string;
}): Promise<CollectionData | undefined> => {
  const response = await callClientApi(`/api/collections/create`, payload);
  if (response.status === 200) {
    return (await response.json()) as CollectionData;
  } else {
    return undefined;
  }
};
