import { GeminiProvider } from "@/services/providers";
import { VideoShowMapper } from "./video_show_mapper";

import { PageData, ComponentData } from "@/renderers";
import { QueryParams } from "@/libs/QueryParams";
import {
  SITE_TITLE,
  buildContainerData,
  buildDarkSectionData,
  buildSectionData,
} from "@/services/libs";
import { Service, ServiceArgs } from "@/services/";
import {
  PATHS,
  getIdFromSlug,
  maybeCreatePaywall,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { Editorial, FilteredContent } from "@/services/providers/gemini/types";
import { ComponentMapper } from "@/services/providers/contentful/mappers";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken } = serviceArgs;
  const id = getIdFromSlug(path);
  const video = await GeminiProvider.getVideoById(id, accessToken);
  const relatedQuery = `content_type=editorials&categoryId=${video?.editorialCategory?.id}`;
  const relatedEditorial = await GeminiProvider.getFilteredContent(
    QueryParams.parseSearchQuery(relatedQuery),
    accessToken
  );

  let components = buildVideoComponents(video, relatedEditorial, accessToken);

  const result = {
    title: `${video.title} | Videos | ${SITE_TITLE}`,
    metaDescription: video.description || "",
    slug: `${PATHS.video}/${video.id}`,
    cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.video}/${video.slug}`,
    metaTitle: video.title || "",
    metaImage: { url: video.imageUrl || "" },
    components: [
      ...components,
      maybeCreatePaywall(
        serviceArgs,
        video.requiredUserActions,
        ComponentMapper.buildPaywallData(video.paywall, path),
        ComponentMapper.buildPaywallData(video.paywall, path)
      ),
    ].filter(Boolean) as ComponentData[],
  };

  return stripUndefinedValuesFromObject(result);
};

const buildVideoComponents = (
  editorial: Editorial,
  relatedEditorial: FilteredContent,
  accessToken?: string
): ComponentData[] => {
  return [
    buildDarkSectionData(
      [
        buildContainerData([
          VideoShowMapper.toVideoPageData(editorial, accessToken),
        ]),
      ],
      editorial?.title
    ),
    buildSectionData([
      buildContainerData([VideoShowMapper.toDatePublishedData(editorial)]),
      buildContainerData([VideoShowMapper.toBodyData(editorial)]),
    ]),
    VideoShowMapper.toRelatedEditorialCarouselData(relatedEditorial),
  ];
};

export const VideoShowService: Service = { retrievePageData };
