import { AccordionData } from "@/renderers";
import { Accordion } from "@horizon/components";

export const AccordionComponent = ({
  title,
  link,
  content,
  id,
}: AccordionData) => {
  return (
    <Accordion
      id={id}
      title={title}
      columnWidth={[12]}
      cta={link}
      content={content.map((item) => {
        return { copy: item.html, heading: item.heading };
      })}
    ></Accordion>
  );
};
