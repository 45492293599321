import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_VIDEO_BY_ID } from "@/services/providers/gemini/queries/editorial_query";
import { Editorial } from "@/services/providers/gemini/types";

export const getVideoById = async (
  id: string,
  accessToken?: string
): Promise<Editorial> => {
  const { getVideoForId: video } = await callGemini(
    { query: GET_VIDEO_BY_ID, variables: { id: id } },
    accessToken
  );

  if (video.paywall.copy) {
    video.paywall.copy = { json: JSON.parse(video.paywall.copy) };
    video.paywall.ctasCollection = { items: video.paywall.ctas };
  } else {
    video.paywall = null;
  }
  return video;
};
