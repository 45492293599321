import { Video_v2 as Video } from "@horizon/components";
import { Analytics } from "@/libs/analytics";
import { MediaPlayerData } from "@/renderers";
import { buildKey } from "../libs";
import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";

type videoProgressEvent = {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
};

export const VideoComponent = ({
  mediaPlayerData,
  index,
}: {
  mediaPlayerData: MediaPlayerData;
  index?: number;
}) => {
  const {
    media: { href, captionsUrl, thumbnail: mediaThumbnail },
    playing = false,
    center = true,
    id,
    accessToken,
    thumbnail,
  } = mediaPlayerData;
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  if (!href) {
    return null;
  }

  const config = captionsUrl
    ? {
        file: {
          tracks: [
            {
              kind: "subtitles",
              src: captionsUrl,
              srcLang: "en",
              default: true,
            },
          ],
          attributes: {
            crossOrigin: "true",
            controlsList: "nodownload",
            onContextMenu: (e: any) => e.preventDefault(),
          },
        },
      }
    : {
        file: {
          attributes: {
            crossOrigin: "true",
            controlsList: "nodownload",
            onContextMenu: (e: any) => e.preventDefault(),
          },
        },
      };

  const videoThumbnail = playing ? null : thumbnail || mediaThumbnail;

  return (
    <Video
      id={id}
      key={buildKey("video", index)}
      aspectRatio="16:9"
      url={href}
      thumbnail={videoThumbnail}
      playing={playing}
      config={config}
      center={center}
      onVideoStart={() =>
        href &&
        Analytics.trackVideoView(
          clientParams,
          {
            playerState: "Started",
            videoUrl: href,
            percentagePlayed: 0,
            currentTime: 0,
            videoTitle: "",
            accessToken: accessToken,
          },
          user
        )
      }
      onVideoPause={(e: any) =>
        href &&
        Analytics.trackVideoView(
          clientParams,
          {
            playerState: "Paused",
            videoUrl: href,
            percentagePlayed: e.target.currentTime / e.target.duration,
            currentTime: e.target.currentTime,
            videoTitle: "",
            accessToken: accessToken,
          },
          user
        )
      }
      onVideoProgress={(e: videoProgressEvent) =>
        href &&
        e &&
        e.played !== 0 &&
        Analytics.trackVideoView(
          clientParams,
          {
            playerState: "Playing",
            videoUrl: href,
            percentagePlayed: e.played * 100,
            currentTime: e.playedSeconds,
            videoTitle: "",
            accessToken: accessToken,
          },
          user
        )
      }
      stopOutOfViewport={false}
    />
  );
};
