const LEGACY_URL = "portal-assets.lionsfestivals.com";

const buildZoomSettings = async (
  url: string
): Promise<string | ZoomUrlOptions> =>
  url.includes(LEGACY_URL) ? await buildLegacyZoomSettings(url) : url;

const buildLegacyZoomSettings = async (
  url: string
): Promise<ZoomUrlOptions> => {
  let xmlData: ZoomTileOptions = {
    height: 0,
    tileSize: 0,
    width: 0,
  };
  try {
    xmlData = await fetchLegacyZoomXml(url);
  } catch (error) {
    console.error("Error fetching legacy zoom settings", error);
  }
  return {
    ...xmlData,
    minLevel: 4,
    getTileUrl: (level: number, x: number, y: number) => {
      level = level - 8;
      level = level < 0 ? level * -1 : level;
      const tileUrl = url.replace(
        "ImageProperties.xml",
        `TileGroup0/${level}-${x}-${y}.jpg`
      );
      return tileUrl;
    },
  };
};

const fetchLegacyZoomXml = async (url: string): Promise<ZoomTileOptions> =>
  await fetch(url)
    .then((response) => response.text())
    .then((text) => matchXmlAttributes(text));

const matchXmlAttributes = (xml: string): ZoomTileOptions => {
  const width = xml.match(/WIDTH="(\d+)"/);
  const height = xml.match(/HEIGHT="(\d+)"/);
  const tileSize = xml.match(/TILESIZE="(\d+)"/);
  return {
    height: parseInt((height && height[1]) || "0"),
    tileSize: parseInt((tileSize && tileSize[1]) || "0"),
    width: parseInt((width && width[1]) || "0"),
  };
};

interface ZoomTileOptions {
  height: number;
  tileSize: number;
  width: number;
}
export interface ZoomUrlOptions extends ZoomTileOptions {
  getTileUrl: (level: number, x: number, y: number) => string;
  minLevel: number;
}

export const MediaUtils = {
  buildZoomSettings,
};
