import { useCallback, useEffect } from "react";
import { EventEmitter } from "eventemitter3";

const emitter = new EventEmitter();

const useSub = (event: string, callback: any) => {
  const unsubscribe = useCallback(() => {
    emitter.off(event, callback);
  }, [callback, event]);

  useEffect(() => {
    emitter.on(event, callback);
    return unsubscribe;
  }, [callback, event, unsubscribe]);

  return unsubscribe;
};

const pubishEvent = <Type,>(event: string, data: Type) => {
  emitter.emit(event, data);
};

export const PubSubProvider = Object.freeze({ useSub, pubishEvent });
