import { callGemini } from "@/services/providers/gemini/gemini_provider";
import { GET_FILTERED_CAMPAIGNS } from "@/services/providers/gemini/queries/campaign_query";
import { GET_FILTERED_TALKS } from "@/services/providers/gemini/queries/talk_query";
import { GET_FILTERED_EDITORIALS } from "@/services/providers/gemini/queries/editorial_query";
import { GET_FILTERED_INSPIRATIONS } from "@/services/providers/gemini/queries/inspiration_query";
import { GET_FILTERED_CLASSIC_ASSETS } from "@/services/providers/gemini/queries/classic_asset_query";
import {
  Campaign,
  FilteredContent,
  Pagination,
  Talk,
  Editorial,
  Inspiration,
} from "@/services/providers/gemini/types";
import { GET_FILTERED_ENTRIES } from "../queries/entry_query";
import { ParsedQuery } from "@/libs/QueryParams";
import { GET_FILTERED_YOUNG_ENTRIES } from "../queries/young_entry_query";

export const getFilteredContent = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<FilteredContent> => {
  switch (queryParams?.content_type) {
    case "talks":
      return getFilteredTalks(queryParams, accessToken);
    case "campaigns":
      return getFilteredCampaigns(queryParams, accessToken);
    case "entries":
      return getFilteredEntries(queryParams, accessToken);
    case "editorials":
      return getFilteredEditorials(queryParams, accessToken);
    case "inspirations":
      return getFilteredInspirations(queryParams, accessToken);
    case "classic":
      return getFilteredClassics(queryParams, accessToken);
    case "young_entries":
      return getFiltereYoungEntries(queryParams, accessToken);
    default:
      return { content: null, pagination: null };
  }
};

const getFiltereYoungEntries = async (
  queryParams: ParsedQuery,
  accessToken?: string
) => {
  const year = queryParams?.year[0];
  const festivalName = queryParams?.festivalName[0];
  const entryTypeId = queryParams && parseInt(queryParams?.entryTypeId[0], 10);
  const variables = { year, festivalName, entryTypeId };
  const results = await callGemini(
    {
      query: GET_FILTERED_YOUNG_ENTRIES,
      variables,
    },
    accessToken
  );
  if (results === null) {
    return { content: null, pagination: null };
  } else {
    const { filteredYoungEntries: youngEntries } = results;
    return { content: youngEntries, pagination: null };
  }
};

const getFilteredTalks = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<{ content: Array<Talk> | null; pagination: Pagination | null }> => {
  const results = await callGemini(
    { query: GET_FILTERED_TALKS, variables: queryParams },
    accessToken
  );
  if (results === null) {
    return { content: null, pagination: null };
  } else {
    const {
      filteredTalks: { talks: talks, pagination: pagination },
    } = results;
    return { content: talks, pagination: pagination };
  }
};

const getFilteredClassics = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<{ content: Array<Talk> | null; pagination: Pagination | null }> => {
  const results = await callGemini(
    { query: GET_FILTERED_CLASSIC_ASSETS, variables: queryParams },
    accessToken
  );
  if (results === null) {
    return { content: null, pagination: null };
  } else {
    const {
      filteredClassicAssets: {
        classicAssets: classicAssets,
        pagination: pagination,
      },
    } = results;
    return { content: classicAssets, pagination: pagination };
  }
};

const getFilteredCampaigns = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<{
  content: Array<Campaign> | null;
  pagination: Pagination | null;
}> => {
  const results = await callGemini(
    { query: GET_FILTERED_CAMPAIGNS, variables: queryParams },
    accessToken
  );
  if (results === null) {
    return { content: null, pagination: null };
  } else {
    const {
      filteredCampaigns: { campaigns: campaigns, pagination: pagination },
    } = results;
    return { content: campaigns, pagination: pagination };
  }
};

const getFilteredEntries = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<FilteredContent> => {
  const results = await callGemini(
    { query: GET_FILTERED_ENTRIES, variables: queryParams },
    accessToken
  );
  if (results === null) {
    return { content: null, filterCounts: null, pagination: null };
  }
  const {
    filteredEntries: {
      entries: content,
      filterCounts: filterCounts,
      pagination: pagination,
    },
  } = results;
  return { content, filterCounts, pagination };
};

const getFilteredEditorials = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<{
  content: Array<Editorial> | null;
  pagination: Pagination | null;
}> => {
  const results = await callGemini(
    { query: GET_FILTERED_EDITORIALS, variables: queryParams },
    accessToken
  );
  if (results === null) {
    return { content: null, pagination: null };
  } else {
    const {
      filteredEditorial: { editorials: editorials, pagination: pagination },
    } = results;
    return { content: editorials, pagination: pagination };
  }
};

const getFilteredInspirations = async (
  queryParams: ParsedQuery,
  accessToken?: string
): Promise<{
  content: Array<Inspiration> | null;
  pagination: Pagination | null;
}> => {
  const results = await callGemini(
    { query: GET_FILTERED_INSPIRATIONS, variables: queryParams },
    accessToken
  );
  if (results === null) {
    return { content: null, pagination: null };
  } else {
    const {
      filteredInspirations: {
        inspirations: inspirations,
        pagination: pagination,
      },
    } = results;
    return { content: inspirations, pagination: pagination };
  }
};
