import {
  VerticalCard,
  TopContentCard,
  BottomContentCard,
  HorizontalCard,
} from "@horizon/components";
import { AspectRatio, CardData, MediaContent } from "@/renderers";
import { buildKey, tagToTagProps } from "./libs";

export const CardComponent = (cardData: CardData, index?: number) => {
  const { variant } = cardData;

  switch (variant) {
    case "Signpost":
      return signPostCard(cardData, index);
    case "Campaign":
      return campaignCard(cardData, index);
    case "Entry":
      return campaignCard(cardData, index);
    case "Collection":
      return defaultCard(cardData, index);
    case "Video":
      return videoCard(cardData, index);
    case "Talk":
      return videoCard(cardData, index);
    case "Editorial":
      return defaultCard(cardData, index);
    case "Horizontal":
      return horizontalCard(cardData, index);
  }

  return defaultCard(cardData, index);
};

const videoCard = (
  {
    media,
    title,
    link,
    subText,
    superText,
    caption,
    mediaTag,
    primaryTags,
    secondaryTags,
    id,
  }: CardData,
  index?: number
) => {
  const aspectRatio = "16:9";

  return (
    <BottomContentCard
      id={id}
      key={buildKey(title || "card", index)}
      aspectRatio={aspectRatio}
      href={link?.href}
      media={media && toMediaProps(media, aspectRatio)}
      title={{
        copy: title,
        as: "h3",
        lines: 2,
        size: "small",
      }}
      caption={{ copy: caption }}
      subText={{ copy: subText }}
      supText={{ copy: superText }}
      mediaTags={mediaTag && [tagToTagProps(mediaTag)]}
      primaryTags={primaryTags?.map(tagToTagProps)}
      secondaryTags={secondaryTags?.map(tagToTagProps)}
    />
  );
};

const signPostCard = (
  { media, title, link, mediaTag, id }: CardData,
  index?: number
) => {
  const aspectRatio = "16:9";
  return (
    <TopContentCard
      id={id}
      key={buildKey(title || "card", index)}
      aspectRatio={aspectRatio}
      href={link?.href}
      media={media && toMediaProps(media, aspectRatio)}
      mediaOverlay={true}
      mediaTitle={{
        copy: title,
        as: "h2",
        lines: 2,
        size: "small",
      }}
      mediaTags={mediaTag && [tagToTagProps(mediaTag)]}
    />
  );
};

const horizontalCard = (
  {
    aspectRatio,
    caption,
    link,
    media,
    title,
    subText,
    superText,
    textPosition,
    mediaTag,
    primaryTags,
    secondaryTags,
    id,
  }: CardData,
  index?: number
) => {
  return (
    <HorizontalCard
      id={id}
      key={buildKey(title || "card", index)}
      textPosition={textPosition}
      aspectRatio={aspectRatio}
      caption={{ copy: caption }}
      href={link?.href}
      media={media && toMediaProps(media, aspectRatio)}
      title={{
        copy: title,
        as: "h3",
        lines: 2,
        size: "small",
      }}
      subText={{
        copy: subText,
      }}
      supText={{
        copy: superText,
      }}
      mediaTags={mediaTag && [tagToTagProps(mediaTag)]}
      primaryTags={primaryTags?.map(tagToTagProps)}
      secondaryTags={secondaryTags?.map(tagToTagProps)}
    />
  );
};

const campaignCard = (
  {
    actionButton,
    caption,
    link,
    media,
    mediaTag,
    primaryTags,
    secondaryTags,
    subText,
    superText,
    title,
    id,
  }: CardData,
  index?: number
) => {
  const asceptRatio = "4:3";

  return (
    <VerticalCard
      id={id}
      title={{
        copy: title,
        as: "h3",
        size: "small",
      }}
      key={buildKey(title || "card", index)}
      actionButton={actionButton}
      aspectRatio={asceptRatio}
      media={media && toMediaProps(media, asceptRatio)}
      mediaTags={mediaTag && [tagToTagProps(mediaTag)]}
      primaryTags={primaryTags?.map(tagToTagProps)}
      secondaryTags={secondaryTags?.map(tagToTagProps)}
      href={link?.href}
      supText={{ copy: superText }}
      subText={{ copy: subText }}
      caption={{ copy: caption }}
    />
  );
};

const defaultCard = (
  {
    media,
    title,
    link,
    subText,
    superText,
    mediaTag,
    primaryTags,
    secondaryTags,
    id,
  }: CardData,
  index?: number
) => {
  const asceptRatio = "1:1";
  return (
    <VerticalCard
      id={id}
      title={{
        copy: title,
        as: "h3",
        size: "small",
      }}
      key={buildKey(title || "card", index)}
      aspectRatio={asceptRatio}
      media={media && toMediaProps(media, asceptRatio)}
      mediaTags={mediaTag && [tagToTagProps(mediaTag)]}
      primaryTags={primaryTags?.map(tagToTagProps)}
      secondaryTags={secondaryTags?.map(tagToTagProps)}
      href={link?.href}
      supText={{ copy: superText }}
      subText={{ copy: subText }}
    />
  );
};

const toMediaProps = (media: MediaContent, aspectRatio?: AspectRatio) => {
  if (media.mediaType === "video/mp4") {
    return mediaToVideoProps(media, aspectRatio);
  }

  return mediaToImageProps(media);
};

const mediaToImageProps = (media: MediaContent) => {
  return {
    src: media.href,
    alt: media.alt,
    mediaType: media.mediaType?.split("/")[0],
    target: "_self",
  };
};

const mediaToVideoProps = (media: MediaContent, aspectRatio?: AspectRatio) => {
  return {
    url: media.href,
    alt: media.alt,
    mediaType: media.mediaType?.split("/")[0],
    aspectRatio: aspectRatio,
    target: "_self",
  };
};
