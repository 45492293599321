import {
  ButtonData,
  CollectionData,
  PageTitleBlockData,
  SaveCollectionButtonData,
} from "@/renderers";
import { PageTitleBlock, Container } from "@horizon/components";
import { buildKey } from "../libs";
import { useState } from "react";
import { CollectionsModalComponent } from "@/components/Collections/CollectionsModalComponent";

export const PageTitleBlockComponentWithSaveCollections = ({
  pageTitleBlockData,
  index,
}: {
  pageTitleBlockData: PageTitleBlockData;
  index?: number;
}) => {
  const {
    title,
    superText,
    subText,
    awardTags,
    saveCollectionButtonData,
    quote,
    profileCard,
    ctas,
    id,
  } = pageTitleBlockData;
  const [isSaveModalOpen, setSaveModalOpen] = useState<boolean>(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const contentInCollection = isContentInCollections(saveCollectionButtonData);
  const { collections } = saveCollectionButtonData || {};

  return (
    <>
      {saveCollectionButtonData && (
        <CollectionsModalComponent
          saveModalState={[isSaveModalOpen, setSaveModalOpen]}
          updateModalState={[isCreateModalOpen, setCreateModalOpen]}
          saveCollectionButtonData={saveCollectionButtonData}
        />
      )}
      <Container>
        <PageTitleBlock
          id={id}
          key={buildKey("pagetitle", index)}
          title={title}
          superText={superText}
          subText={subText}
          awardTags={
            awardTags &&
            awardTags.map((awardTag) => ({
              variant: awardTag.variant,
              copy: awardTag.content,
            }))
          }
          ctas={[
            {
              variant: contentInCollection ? "primary" : "secondary",
              icon: { name: "Heart", position: "end" },
              onClick: () => {
                collections ? setSaveModalOpen(true) : setCreateModalOpen(true);
              },
              children: contentInCollection ? "Saved" : "Save",
            },
            ...(ctas || []).map(ctaFromButtonData),
          ]}
          quote={quote && { copy: quote }}
          profileCard={
            profileCard && {
              title: profileCard?.name,
              media: {
                src: profileCard?.media?.href,
                alt: profileCard?.media?.alt,
              },
            }
          }
        />
      </Container>
    </>
  );
};

const isContentInCollections = (
  saveCollectionButtonData?: SaveCollectionButtonData
): boolean => {
  const { contentType, contentId, collections } =
    saveCollectionButtonData || {};
  if (collections && contentType && contentId) {
    return collections.some((collection) =>
      isContentInCollection(contentType, contentId, collection)
    );
  }
  return false;
};

const isContentInCollection = (
  contentType: string,
  contentId: string,
  collection: CollectionData
) => {
  if (contentType === "entry") {
    return collection.entryIds.includes(Number(contentId));
  } else if (contentType === "campaign") {
    return collection.campaignIds.includes(Number(contentId));
  }
  return false;
};

const ctaFromButtonData = (buttonData: ButtonData) => {
  const { label, href, variant, icon } = buttonData;
  return {
    variant,
    icon: icon && { name: icon.name, position: icon.position },
    href,
    children: label,
  };
};
