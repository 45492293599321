import { Pagination } from "@/services/providers/gemini/types";
import { TableCell, TableData, TableRow } from "@/components/components.d";

export const toDirectoryTableData = ({
  headers = [],
  rows = [],
  pagination = undefined,
}: {
  headers: TableCell[];
  rows: TableRow[];
  pagination?: Pagination;
}): TableData =>
  ({
    type: "Table",
    layout: "auto",
    headers,
    rows,
    pagination: pagination
      ? {
          page: pagination?.currentPage,
          pageSize: pagination?.pageSize,
          totalRecords: pagination?.totalRecords,
          type: "Pagination",
        }
      : undefined,
  } as TableData);
