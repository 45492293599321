import { callRestGeminiGet } from "@/services/providers/gemini/gemini_provider";
import { Playlist } from "@/services/providers/gemini/types";

export const getCollection = async (
  id: string,
  accessToken?: string
): Promise<Playlist> => {
  const response = await callRestGeminiGet(`/api/playlists/${id}`, accessToken);

  return response;
};
