import { Fragment } from "react";
import { CalendarEvent, EventItemData } from "@/renderers";
import {
  Button_v2 as Button,
  DarkModeWrapper,
  EventItem,
} from "@horizon/components";
import { listToCommaSeparatedString } from "@/services/libs";
import { AuthenticatedUser, Authentication } from "@/libs";
import {
  ClientParameters,
  useClientParameters,
} from "@/libs/ClientParameterProvider";
import { Analytics } from "@/libs/analytics";

export const EventItemComponent = ({
  calendarEvent,
  description,
  duration,
  host,
  speakers,
  tags,
  time,
  title,
  status,
  videoUrl,
  id,
}: EventItemData) => {
  const variantsPerStatus = {
    live: "primary",
    past: "secondary",
    upcoming: "primary",
  };
  let rightTag = null;
  const ctas = [];
  const eventTags: {
    label: string;
    props: { backgroundColor: string; textColor: string };
  }[] = [];
  const hostText = host ? `Hosted by ${host}` : "";
  const timeText = status === "past" ? `Event ended ${time}` : time;
  const speakersCount = speakers?.length || 0;
  const speakersLabel =
    speakersCount === 0 ? "" : speakersCount > 1 ? "Speakers: " : "Speaker:";
  const isLive = status === "live";
  const Wrapper = videoUrl || isLive ? DarkModeWrapper : Fragment;

  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  if (videoUrl) {
    rightTag = generateTagProps("On demand", "accent1", "black");
    ctas.push(generateOnDemandButtonProps(videoUrl));
  } else if (status === "upcoming" && calendarEvent) {
    ctas.push(generateCalendarEventProps(calendarEvent, clientParams, user));
  }

  if (isLive) {
    rightTag = generateTagProps("Live now", "error", "white");
  }

  tags?.map((tag: string) => {
    eventTags.push(generateTagProps(tag));
  });

  return (
    <Wrapper>
      <EventItem
        id={id}
        ctas={ctas}
        availableMessage={duration}
        description={
          description
            ? {
                copy: description,
                lines: Math.max(2, speakersCount),
                isCollapsed: !isLive, // Collapse description by default except for the live event
              }
            : null
        }
        tags={eventTags}
        time={timeText}
        title={title}
        rightTag={rightTag}
        speakers={
          speakers?.map((speaker) => {
            return {
              src: speaker.imageSrc,
              name: speaker.fullName,
              subtitle: listToCommaSeparatedString([
                speaker.jobTitle,
                speaker.company,
              ]),
            };
          }) || []
        }
        speakersLabel={speakersLabel}
        titleSubText={hostText}
        variant={variantsPerStatus[status]}
      />
    </Wrapper>
  );
};

const generateCalendarEventProps = (
  calendarEvent: CalendarEvent,
  clientParams: ClientParameters,
  user?: AuthenticatedUser
) => {
  return {
    props: {
      calendarEvent,
      calendarTypes: ["google", "apple", "outlook", "office365"],
      id: `add-to-calendar-${calendarEvent.title}`,
      onClick: () => {
        Analytics.trackAddToCalendar(calendarEvent, clientParams, user);
      },
      trigger: (
        <Button icon={{ name: "Calendar", position: "end" }} size="small">
          Add to Calendar
        </Button>
      ),
    },
    type: "addToCalendar",
  };
};

const generateOnDemandButtonProps = (videoUrl: string) => {
  return {
    label: "Watch now",
    props: {
      href: videoUrl,
      icon: {
        name: "Play",
        position: "end",
      },
    },
    type: "button",
  };
};

const generateTagProps = (
  tag: string,
  backgroundColor?: string,
  textColor?: string
) => {
  return {
    label: tag,
    props: {
      backgroundColor: backgroundColor || "accent3",
      textColor: textColor || "black",
    },
  };
};
