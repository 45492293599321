import { Authentication } from "@/libs";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { PageData } from "@/renderers";
import { Service, ServiceArgs } from "@/services";
import { BrandShowMapper } from "@/services/directory/brand_show/brand_show_mapper";
import {
  PATHS,
  SITE_TITLE,
  buildContainerData,
  buildSectionData,
  getIdFromSlug,
  maybeCreatePaywall,
  maybeRedirectToSlug,
  stripUndefinedValuesFromObject,
} from "@/services/libs";
import { GeminiProvider } from "@/services/providers";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { Brand, Pagination } from "@/services/providers/gemini/types";
import { FilterOption } from "@/services/providers/gemini/types/filters";

const retrievePageData = async (
  serviceArgs: ServiceArgs
): Promise<PageData | null> => {
  const { path, accessToken, queryParams } = serviceArgs;
  if (path.startsWith(`${PATHS.brands}/`)) {
    const id = getIdFromSlug(path);
    const parsedQuery = QueryParams.parseSearchQuery(
      appendBrandIdAndContentType(queryParams || "", id)
    );

    delete parsedQuery?.id;
    const filterOptions = await GeminiProvider.getCampaignFilters(
      parsedQuery,
      accessToken
    );
    const { brand, pagination } = await GeminiProvider.getBrandById(
      id,
      parsedQuery,
      accessToken
    );
    if (brand) {
      maybeRedirectToSlug(path, id, PATHS.brands, brand.slug);

      const result = {
        title: `${SITE_TITLE} | Brand | ${brand.name}`,
        metaDescription: brand.name,
        slug: `${PATHS.brands}/${brand.slug}`,
        cannonicalUrl: `${process.env.NEXT_PUBLIC_DOMAIN}${PATHS.brands}/${brand.slug}`,
        metaTitle: brand.name,
        metaImage: { url: brand.campaigns[0]?.assets[0].thumbnailUrl || "" },
        components: [
          ...buildComponents(brand, pagination, filterOptions, parsedQuery),
          maybeCreatePaywall(
            serviceArgs,
            Authentication.USER_ACTIONS.BRANDS_VIEW
          ),
        ].filter(Boolean),
      };
      return stripUndefinedValuesFromObject(result);
    }
  }
  return null;
};

const buildComponents = (
  brand: Brand,
  pagination: Pagination | null,
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery
) => {
  return [
    BrandShowMapper.toTitleData(brand, pagination),
    buildSectionData([
      GeminiMapper.toFilterBlockData(
        filterOptions,
        parsedQuery,
        buildSortOptions(parsedQuery)
      ),
    ]),
    BrandShowMapper.toCampaignGrouping(brand, pagination),
  ];
};

const SORT_OPTIONS = [
  { value: "date_descending", label: "Newest to oldest" },
  { value: "date_ascending", label: "Oldest to newest" },
  { value: "relevance", label: "Best match" },
  { value: "most_awarded", label: "Most awarded" },
  { value: "title_ascending", label: "Title - A-Z" },
  { value: "title_descending", label: "Title - Z-A" },
];

const buildSortOptions = (
  parsedQuery: ParsedQuery
): {
  options: { label: string; value: string }[];
  initialValue: string;
} => ({
  options: SORT_OPTIONS,
  initialValue: parsedQuery?.sort ? (parsedQuery.sort as string) : "relevance",
});

const appendBrandIdAndContentType = (queryString: string, brand_id: string) => {
  if (queryString.length > 1) {
    return queryString.concat(`&brand_id=${brand_id}&content_type=brands`);
  }
  return `brand_id=${brand_id}&content_type=brands`;
};

export const BrandShowService: Service = { retrievePageData };
