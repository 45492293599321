import { FormData, TextBlockData } from "@/components/components.d";
import { COUNTRIES } from "@/services/account/account_show/countries";
import { ROLES } from "@/services/account/account_show/roles";
import { SENIORITIES } from "@/services/account/account_show/seniorities";
import { PATHS } from "@/services/libs";

const toFormData = (auth_token?: string, state?: string): FormData => ({
  type: "Form",
  id: "create-profile-form",
  sections: [
    {
      id: "profile-form",
      title: "Personal details",
      fields: [
        {
          id: "firstName",
          name: "First Name",
          type: "text",
          placeholder: "First Name",
          required: true,
          validations: "required",
        },
        {
          id: "lastName",
          name: "Last Name",
          type: "text",
          placeholder: "Last Name",
          required: true,
          validations: "required",
        },
        {
          id: "country",
          name: "Location",
          type: "select",
          placeholder: "Select a country",
          required: true,
          validations: "required",
          opts: {
            remote: {
              labelKey: "label",
              valueKey: "value",
              name: "countries",
            },
          },
        },
        {
          id: "country_code",
          name: "Country code",
          type: "hidden",
        },
        {
          id: "permit_marketing",
          name: "permit_marketing",
          type: "hidden",
        },
        {
          id: "telephone_number",
          name: "Telephone number (inc. country code)",
          type: "text",
          placeholder: "Telephone number (inc. country code)",
          required: true,
          validations: "telephone",
        },
      ],
    },
    {
      id: "company-form",
      title: "Company details",
      fields: [
        {
          id: "company_name",
          name: "Company Name",
          type: "text",
          placeholder: "Company Name",
          required: true,
          validations: "required",
        },
        {
          id: "location",
          name: "Company Location",
          type: "hidden",
          placeholder: "Company Location",
        },
        {
          id: "role",
          name: "How would you describe your role?",
          type: "select",
          placeholder: "Select",
          required: true,
          validations: "required",
          opts: {
            remote: {
              labelKey: "label",
              valueKey: "value",
              name: "roles",
            },
          },
        },
        {
          id: "seniority",
          name: "What is your seniority?",
          type: "select",
          placeholder: "Select",
          required: true,
          validations: "required",
          opts: {
            remote: {
              labelKey: "label",
              valueKey: "value",
              name: "seniorities",
            },
          },
        },
        {
          id: "accept_terms",
          name: "Please accept the Terms and Conditons",
          type: "select",
          placeholder: "Accept Terms",
          required: true,
          validations: "terms and conditions",
          opts: {
            remote: {
              labelKey: "label",
              valueKey: "value",
              name: "accept_terms",
            },
          },
        },
        {
          id: "auth_token",
          type: "hidden",
          name: "auth_token",
        },
        {
          id: "state",
          type: "hidden",
          name: "state",
        },
      ],
    },
  ],
  initialValues: {
    firstName: "",
    lastName: "",
    country: "",
    telephone_number: "",
    company_name: "",
    role: "",
    seniority: "",
    location: "",
    permit_marketing: true,
    accept_terms: false,
    auth_token: auth_token || "",
    state: state || "",
  },
  fieldDataSets: {
    countries: COUNTRIES.map((country) => ({
      label: country,
      value: country,
    })),
    roles: ROLES.map((role) => ({
      label: role,
      value: role,
    })),
    seniorities: SENIORITIES.map((seniority) => ({
      label: seniority,
      value: seniority,
    })),
    accept_terms: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
});

const toTermsAndConditionsTextBlock = (): TextBlockData => ({
  type: "HTMLTextBlock",
  html: `<p>${termsParagraph1}</p><p>${termsParagraph2}</p><p>${termsParagraph3}</p>`,
});

const termsParagraph1 = `Ascential may email you from time to time with updates and other information about our events, products and services that may be of interest. We will also pass your details to our selected partners and sponsors who may contact you.`;
const termsParagraph2 = `You can unsubscribe at any time. See our <a href=${PATHS.privacyPolicy}>privacy policy</a> and <a href=${PATHS.cookiesPolicy}>cookies policy</a>. `;
const termsParagraph3 = `By clicking <i>\"Save\"</i> you acknowledge and agree that you have read, understood and agree to be bound by our <a href=${PATHS.termsAndConditions}>Terms & Conditions</a>.`;

export const CreateProfileMapper = {
  toFormData,
  toTermsAndConditionsTextBlock,
};
