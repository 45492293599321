import { gql } from "graphql-request";
import {
  HERO_BLOCK_FRAGMENT,
  PAGE_TITLE_BLOCK_FRAGMENT,
  PAYWALL_COMPONENT_FRAGMENT,
  CARD_FRAGMENT,
} from "./section_queries";

export const PAGE_BY_SLUG_QUERY = gql`
  query ($preview: Boolean, $slug: String) {
    pageCollection(preview: $preview, where: { slug: $slug }, limit: 1) {
      items {
        title
        slug
        metaTitle
        metaDescription
        metaImage {
          url
        }
        navigationType
        requiredUserAction
        loggedinPaywall {
          ...PaywallComponentFragment
        }
        loggedoutPaywall {
          ...PaywallComponentFragment
        }
        content: contentCollection(preview: $preview) {
          items {
            sys {
              id
            }
          }
        }
        announcement {
          type: __typename
          announcementMessageRichText {
            json
          }
          date
          expiredMessageRichText {
            json
          }
          variant
        }
        headerComponent {
          type: __typename
          ... on HeroBlock {
            ...HeroBlockFragment
          }
          ... on PageTitleBlock {
            ...PageTitleBlockFragment
          }
        }
      }
    }
  }
  ${HERO_BLOCK_FRAGMENT}
  ${PAGE_TITLE_BLOCK_FRAGMENT}
  ${PAYWALL_COMPONENT_FRAGMENT}
  ${CARD_FRAGMENT}
`;
