import { ParsedQuery } from "@/libs/QueryParams";

export const sortValues = [
  "relevance",
  "date_descending",
  "date_ascending",
  "most_awarded",
  "title_ascending",
  "title_descending",
] as const;

export type SortValue = (typeof sortValues)[number];

export interface SortOption {
  value: SortValue;
  label: string;
}

interface SortOptionsFilter {
  options: SortOption[];
  initialValue: SortValue;
}

export const DEFAULT_SORT_OPTIONS: SortOption[] = [
  { value: "relevance", label: "Best match" },
  { value: "date_descending", label: "Newest to oldest" },
  { value: "date_ascending", label: "Oldest to newest" },
  { value: "most_awarded", label: "Most awarded" },
  { value: "title_ascending", label: "Title - A-Z" },
  { value: "title_descending", label: "Title - Z-A" },
];

export const CLASSIC_SORT_OPTIONS: SortOption[] = [
  { value: "date_descending", label: "Newest to oldest" },
  { value: "date_ascending", label: "Oldest to newest" },
  { value: "title_ascending", label: "Title - A-Z" },
  { value: "title_descending", label: "Title - Z-A" },
];

export const isValidSortValue = (value: string): value is SortValue => {
  return (sortValues as readonly string[]).includes(value);
};

export const parseSortValueFromQuery = (
  query: ParsedQuery,
  defaultSort?: SortValue
): SortValue | undefined => {
  return query?.sort &&
    !Array.isArray(query?.sort) &&
    isValidSortValue(query?.sort)
    ? (query.sort as SortValue)
    : defaultSort || undefined;
};

export const buildSortOptions = (
  sortOptions: SortOption[],
  defaultValue?: SortValue
): SortOptionsFilter => ({
  options: sortOptions,
  initialValue: defaultValue || sortOptions[0].value,
});
