import { toCollectionData } from "@/services/providers/gemini/mappers/collections";
import {
  toComponentGroupingData,
  mapComponentsToCards,
  toPaginationData,
} from "@/services/providers/gemini/mappers/component_grouping";
import {
  toFilterBlockData,
  parseFilterOptions,
} from "@/services/providers/gemini/mappers/filter_mapper";
import {
  rankingsDataToTableData,
  parseRankingFilterOptions,
} from "@/services/providers/gemini/mappers/rankings";
import { toDirectoryTableData } from "@/services/providers/gemini/mappers/directory";

export const GeminiMapper = {
  Filters: {
    parseFilterOptions,
  },
  toCollectionData,
  toComponentGroupingData,
  toDirectoryTableData,
  toFilterBlockData,
  parseRankingFilterOptions,
  rankingsDataToTableData,
  mapComponentsToCards,
  toPaginationData,
};
