import { ComponentGroupingData, SearchBarData } from "@/renderers";
import {
  TEXT_CONSTS,
  defaultComponentsPerRow,
  formatNumber,
  pluralize,
} from "@/services/libs";
import {
  ClassicAsset,
  FilteredContent,
} from "@/services/providers/gemini/types";
import { classicToCard } from "@/services/providers/gemini/mappers/component_grouping";

const toGrid = ({
  content,
  pagination,
}: FilteredContent): ComponentGroupingData => ({
  type: "ComponentGrouping",
  variant: "grid",
  title: `${formatNumber(pagination?.totalRecords)} ${pluralize(
    pagination?.totalRecords || 0,
    TEXT_CONSTS.RESULT
  )} found in Classic`,
  components: content
    ? content.map((content) =>
        classicToCard(content as unknown as ClassicAsset)
      )
    : [],
  componentsPerRow: defaultComponentsPerRow,
  page: pagination?.currentPage,
  pageSize: pagination?.pageSize,
  totalRecords: pagination?.totalRecords,
  noResultsText: "UNFORTUNATELY, NO RESULTS WERE FOUND FOR YOUR SEARCH.",
});

const toSearchBarData = (searchText: string): SearchBarData => ({
  type: "SearchBar",
  searchText: searchText,
  searchBarLabel: "Search Classic",
  suggestions: [
    { label: "Grand Prix" },
    { label: "Automotive" },
    { label: "Apple" },
    { label: "Y & R" },
  ],
});

export const ClassicListingMapper = {
  toSearchBarData,
  toGrid,
};
