import { useState } from "react";
import { FilterGroup } from "@horizon/components";
import { FilterBlockData, FilterData } from "@/components/components.d";
import { QueryParams } from "@/libs/QueryParams";
import {
  currentSearchQuery,
  parseSearchQuery,
} from "../../libs/QueryParams/query_params";
import { Analytics } from "@/libs/analytics";
import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";

type SelectedFilter = {
  name: string;
  label: string;
  value: string;
};

type DefaultQueryParams = {
  sort?: string;
  search_text?: string;
  content_type?: string;
};

export const FiltersComponent = (filterBlockData: FilterBlockData) => {
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  const initialSort =
    filterBlockData?.sortFilter?.options?.find(
      (option) => option.value === filterBlockData?.sortFilter?.initialValue
    )?.label || "Most awarded";

  let initialSelectedFilters: SelectedFilter[] = [];
  filterBlockData?.filters?.map((filter) => {
    if (filter.initialValue && Array.isArray(filter.initialValue)) {
      filter.initialValue.map((value) => {
        const selectedOption = filter.options.find(
          (option) => option.value === value.toString()
        );
        if (selectedOption) {
          initialSelectedFilters.push({
            name: filter.key,
            label: selectedOption.label,
            value: selectedOption.value,
          });
        }
      });
    }
  });

  // State to control the sort value
  const [sort, setSort] = useState(initialSort);
  // State to control the selectedFilters (current UI state)
  const [selectedFilters, setSelectedFilters] = useState(
    initialSelectedFilters
  );
  // State to control the modal visibility. Used to decide whether to update
  // the query params or filter change or not (if modal is open, don't update
  // until the Apply button is clicked)
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Filters are the filter options that are displayed in the UI
  const filters = filterBlockData?.filters?.map((filter) => {
    return {
      ...filter,
      name: filter.key,
      type: filter.multiselect ? "checkbox" : "radio",
    };
  });

  // Sort Options are the sort options that are displayed in the UI
  const sortOptions = filterBlockData.sortFilter
    ? {
        ...filterBlockData?.sortFilter,
        name: filterBlockData?.sortFilter?.key,
        type: "radio",
      }
    : null;

  // Update the modal visibility state when the modal is opened or closed
  const handleAllFiltersToggle = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  // Sort filter change handler - update the sort state and query params
  const handleSortChange = (name: string, label: string) => {
    const sortValue = filterBlockData?.sortFilter?.options.filter(
      (option) => option.label === label
    )[0].value;
    Analytics.trackSortInteraction(clientParams, label, user);
    QueryParams.replaceQueryParam(name, [sortValue as string]);
    setSort(label);
  };

  // Filter change handler - update the selectedFilters state and query params
  // NOTE: This function is called when a filter is selected or unselected,
  // but whether to update the query params or not is decided by the isModalOpen
  const handleFilterChange = (name: string, label: string, value: string) => {
    const filterIndex = selectedFilters?.findIndex(
      (selectedFilter) =>
        selectedFilter.label === label && selectedFilter.name === name
    ) as number;

    // Remove existing filter, handle if multiple values of the filter are in use
    if (filterIndex !== null && filterIndex > -1) {
      const newSelectedFilters = [...selectedFilters];
      newSelectedFilters.splice(filterIndex, 1);
      const sameFilters = getSameFilters(newSelectedFilters, name);
      const newQueryValue =
        sameFilters.length > 0 ? sameFilters.map((filter) => filter.value) : "";
      setSelectedFilters(newSelectedFilters);
      if (!isModalOpen) {
        //TODO Add analytics
        QueryParams.replaceQueryParam(name, newQueryValue);
      }
    } else {
      // Add new filter, handle if multiple values of the filter are in use
      const filter = {
        label,
        name,
        value,
      };

      const filterType = filters.find(
        (filter: FilterData) => filter.name === name
      )?.type;
      const newSelectedFilters = [...selectedFilters];

      if (filterType === "radio") {
        const existingFilterIndex = selectedFilters.findIndex(
          (filter) => filter.name === name
        );
        if (existingFilterIndex !== -1) {
          newSelectedFilters[existingFilterIndex] = filter;
        } else {
          newSelectedFilters.push(filter);
        }
      } else {
        newSelectedFilters.push(filter);
      }
      setSelectedFilters(newSelectedFilters);
      const sameFilters = getSameFilters(selectedFilters, name);
      const newQueryValue =
        sameFilters.length > 0 && filterType === "checkbox"
          ? [sameFilters.map((filter) => filter.value), value]
          : [value];

      if (!isModalOpen) {
        //TODO Add analytics
        QueryParams.replaceQueryParam(name, newQueryValue as string[]);
      }
    }
  };

  // Clear all filters - reset the selectedFilters state and query params
  const handleClear = () => {
    setSelectedFilters([]);
    const newQueryParams = getDefaultQueryParams();
    QueryParams.replaceAllQueryParams(newQueryParams);
  };

  // Get the default query params from the current search query, they will
  // not get removed on filter clear
  const getDefaultQueryParams = () => {
    const search = currentSearchQuery();
    const queryParams = parseSearchQuery(search);
    const newQueryParams = {} as DefaultQueryParams;
    if (queryParams?.sort) {
      newQueryParams.sort = queryParams.sort as string;
    }

    if (queryParams?.search_text) {
      newQueryParams.search_text = queryParams.search_text as string;
    }

    if (queryParams?.content_type) {
      newQueryParams.content_type = queryParams.content_type as string;
    }

    return newQueryParams;
  };

  // Get the filters with the same name
  const getSameFilters = (filters: SelectedFilter[], name: string) =>
    filters?.filter((filter) => filter.name === name);

  // Modal apply button handler - update the query params with all the selected
  // filters, replace the existing query params with the new ones
  const handleSubmit = (selectedFilters: SelectedFilter[]) => {
    const newQueryParams = getDefaultQueryParams() as any;

    selectedFilters.map((selectedFilter: SelectedFilter) => {
      if (!newQueryParams[selectedFilter.name]) {
        newQueryParams[selectedFilter.name] = [selectedFilter.value];
      } else {
        newQueryParams[selectedFilter.name].push(selectedFilter.value);
      }
    });

    Analytics.trackFilterInteraction(clientParams, newQueryParams, user);
    QueryParams.replaceAllQueryParams(newQueryParams);
  };

  return (
    <FilterGroup
      filters={filters}
      onClear={handleClear}
      onAllFiltersAction={handleSubmit}
      onAllFiltersToggle={handleAllFiltersToggle}
      onFilterChange={handleFilterChange}
      onSortChange={handleSortChange}
      selectedFilters={selectedFilters}
      filtersToShow={filterBlockData.filtersToShow}
      sort={sort}
      sortOptions={sortOptions}
      my={6}
    />
  );
};
