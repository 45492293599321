import { ComponentData, MediaPlayerData } from "@/renderers";
import { classicToAwardTagData } from "@/services/libs/award_utils";
import { ClassicAsset } from "@/services/providers/gemini/types";

const classicToVideo = (
  classic: ClassicAsset,
  accessToken?: string
): MediaPlayerData => ({
  type: "MediaPlayerData",
  media: {
    mediaType: "video/mp4",
    alt: classic.title,
    href: classic.videoUrl,
    thumbnail: classic.imageUrl,
  },
  accessToken,
});

const classicToPageTitle = ({
  title,
  year,
  award,
  brand,
  production,
  country,
  sector,
  agency,
}: ClassicAsset): ComponentData =>
  ({
    type: "PageTitleBlock",
    title: title,
    superText: `${year}/${agency}/${brand}`,
    awardTags: [classicToAwardTagData(award)],
    subText: `LION:${award}, COUNTRY:${country}, SECTOR:${sector}, PRODUCTION:${production}`,
  } as ComponentData);

export const ClassicMapper = {
  classicToVideo,
  classicToPageTitle,
};
